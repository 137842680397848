export function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: "short" });
    const year = date.getFullYear().toString().slice(-2); // Get last 2 digits of the year

    const formattedDate = `${day}${getOrdinalSuffix(day)} ${month} ${year}`;

    return formattedDate;
}

// Function to get ordinal suffix for a number
function getOrdinalSuffix(number) {
    if (number >= 11 && number <= 13) {
        return 'th';
    }
    switch (number % 10) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th';
    }
}