import React, { useEffect } from "react";
import useGetPartnerProfile from "../../../hooks/useGetPartnerProfile";
import MenuIcon from '@mui/icons-material/Menu';
import { useLayoutStore } from "./store";
import Avatar from '@mui/material/Avatar';
import { Link } from "react-router-dom";
import { HOME_NAVIGATION_EVENTS } from "../../../pages/Home/constants";
import { useAnalytics } from "../../../hooks/useAnalytics";

interface HeaderProps { };

const Header: React.FC<HeaderProps> = () => {
    const { track, identify } = useAnalytics();
    const { setIsLeftOpen } = useLayoutStore(({ setIsLeftOpen }) => ({ setIsLeftOpen }));
    const { data } = useGetPartnerProfile();
    const { profile } = data?.data || {};
    const { firstName, lastName, email, phone, publicId } = profile || {}

    useEffect(() => {
        if(publicId) {
            identify(profile);
        }
    }, [publicId]);

    const avatarName = `${firstName?.charAt(0)}${lastName?.charAt(0)}`;

    return (
        <div className="shadow sticky bg-[#FBFBF6] top-0 z-50 p-4">
            <div className="flex justify-between xl:justify-end items-center w-full">
                <MenuIcon onClick={() => setIsLeftOpen()} className="text-gray-600 cursor-pointer block xl:!hidden" />
                <div className="flex items-center gap-6">
                    <div className="flex items-end flex-col">
                        <div className="capitalize hidden xl:block font-bold text-sm text-gray-800">
                            {`${firstName?.toLowerCase()} ${lastName?.toLowerCase()}` ||
                                'Hey !'}
                        </div>
                        <span className="text-sm text-gray-700 hidden xl:block">
                            {' '}{email || '-'}
                        </span>
                    </div>
                    <Link to="/profile" onClick={() => track({eventName: HOME_NAVIGATION_EVENTS.Profile})}>
                        <Avatar className="uppercase">{avatarName}</Avatar>
                    </Link>
                </div>
            </div>
        </div>
    );
};

Header.displayName = "Header";

export default Header;