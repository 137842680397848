import React from "react";
import Footer from "../../components/Footer";

interface PrivacyPolicyProps { };

const PrivacyPolicy: React.FC<PrivacyPolicyProps> = () => {
    return (
        <>
            <div className="py-24 max-w-screen-2xl mx-auto px-4 md:px-12">
                <h1 className="font-medium text-4xl lg:text-6xl mb-32 text-center">Privacy Policy</h1>

                <div className="text-xl md:text-2xl">
                    <p className="mb-4">
                        This document is an electronic record in terms of Information Technology Act, 2000 and rules made thereunder as applicable. No physical or digital signatures are required being an electronic record.
                    </p>

                    <p className="mb-4">
                        Purple Petal Invest Private Limited (“PPIPL”) is a limited liability company registered under the laws of India having corporate identification number U72900MH2021PTC364347 and which owns and operates the technology platform “TAPINVEST™” (the “PPIPL”, “We”, “Us”). TAPINVEST™ facilitates selling and buying financial products on its Website (https://tapinvest.in ) (the “Website”) and/or android application). TAPINVEST™ is committed to upholding the privacy and security of any data that is voluntarily provided by you (the “User” or “You”) and which identifies You as such an individual (the “Personal Data”) while accessing and/or registering and using TAPINVEST™ Website (the “Website”) and/or TAPINVEST ™ application (the “App”) on a compatible device. This privacy policy (the “Privacy Policy”) provides the manner in which PPIPL collects, uses, stores, processes, discloses, transfers, protects and uses Personal Data.
                    </p>

                    <p className="mb-4">
                        PPIPL owns and operates the Platform (as hereinafter defined) and is a marketplace engaged in the business of providing technology solutions for fixed income financial products and investment opportunities (the "Business").
                    </p>

                    <p className="mb-4">
                        By accessing or using the Platform, User signifies that User has read, understood and agreed to be bound by this Privacy Policy. User hereby consents to PPIPL’s collection, use, storing, processing, disclosing, transferring, and protecting of User’s Personal Data as described in this Privacy Policy. If Users are using PPIPL’s services on behalf of another entity, then, User hereby represents that User is authorised by such entity to accept the terms of this Privacy Policy and further consent on behalf of such entity to PPIPL’s collection, use, storing, processing, disclosing, transferring, and protecting of such entity’s information as described in this Privacy Policy.
                    </p>

                    <p className="mb-4">
                        We reserve the right to update or modify this Privacy Policy at any time without prior notice and such changes shall be effective immediately upon posting the updated or modified Privacy Policy on the Platform. An intimation regarding the updates or modification of the Privacy Policy shall be uploaded on Announcements&gt;Legal, which will be available on our Website. User’s access and use of the Platform following any such change constitutes User’s agreement to follow and be bound by this Privacy Policy, as updated or modified. For this reason, we encourage User has to review this Privacy Policy each time User accesses and uses the Platform. User’s use of the Platform and any disputes arising therefrom, is subject to this Privacy Policy.
                    </p>

                    <p className="mb-4">
                        Unless otherwise defined herein, the capitalised terms in this Privacy Policy shall have the meaning ascribed to the term in our Terms and Conditions.
                    </p>

                    <p className="mb-6"><b>Consent</b></p>
                    <ol className="list-decimal ml-6 flex flex-col gap-6 md:gap-12">
                        <li>
                            User acknowledges that this Privacy Policy is part of the Terms & Conditions of the Platform by accessing and/or using the Platform or by otherwise providing Us with their Personal Data or by making use of the services provided by the Platform, User unconditionally signifies their (a) consent to the Privacy Policy, and (b) consent to collect, use, store, process, disclose, transfer, and protect User’s Personal Data in accordance with the provisions of this Privacy Policy and applicable laws.
                        </li>

                        <li>
                            User acknowledges that User is over 18 years of age and is providing their Personal Data out of their free will. In case User is under the 18 years of age, it is clarified that PPIPL does not engage with minors and disclaims any Personal Data collected, or stored, used, or processed, disclosed, or transferred of the minor on account of misrepresentation made in regard to the age of such person.
                        </li>

                        <li>
                            If User does not provide us with their Personal Data, this will result in PPIPL withdrawing the services and / or terminate the existing relationship with the User.
                        </li>

                        <li>
                            User has an option to withdraw his/her consent at any point, provided such withdrawal of the consent is intimated to us in writing at support@tapinvest.in. If User chooses to withdraw, this may result in PPIPL withdrawing its services and / or terminate its existing relationship with the User.
                        </li>
                    </ol>

                    <p className="my-6"><b>Personal Data Collected</b></p>
                    <p>In order to provide services to User, we require Users to voluntarily provide us Personal Data which Users do and hereby consent to PPIPL for collecting, using, storing, processing, disclosing, transferring, and protecting of such data. </p>
                    <p className="my-5">The Personal Data that we may collect from User, or about User, may include but are not limited to, the following:</p>

                    <ul className="list-disc ml-6 flex flex-col gap-4">
                        <li>Name of the User;</li>
                        <li>Date of birth of the User;</li>
                        <li>Gender;</li>
                        <li>Address (including country and pin code);</li>
                        <li>Aadhar Number </li>
                        <li>PAN Number</li>
                        <li>Financial and KYC information (such as passport, driving licence, Voter’s Identity Card);</li>
                        <li>Phone number;</li>
                        <li>Email address;</li>
                        <li>Internet protocol address, location, browser type, browser language, referring URL, files accessed, errors generated, time zone, operating system and other visitor details collected in Our log files;</li>
                        <li>the pages of our Website that User visits, the time and date of Users visit, the time spent on those pages and other statistics (the “Log Data”); and</li>
                        <li>Any other information that is willingly shared by User.</li>
                    </ul>

                    <p className="my-6"><b>Purpose of Personal Data Collected</b></p>
                    <p>The Personal Data collected from User shall be used for.</p>

                    <ul className="list-disc ml-6 flex flex-col gap-4">
                        <li>To provide services;</li>
                        <li>To generate invoices and contracts.</li>
                        <li>For verification of ID, and to comply with KYC and Anti Money Laundering Regulations.</li>
                        <li>Aadhar Number is taken to verify OTP. Through Aadhar we receive the Aadhaar Name, DOB as per Aadhaar, Gender, Address, Masked mobile number, and registered Email ID in Aadhaar</li>
                        <li>To verify information and check validity status for documents such as PAN, Aadhar Link, GSTIN, Bank Details which are shared during KYC and when it was last updated, the documents are shared with a third-party programme, all related to the specific purpose for which User has consented to with Us. KYC details are also shared with third parties for payments in order to complete business transactions.</li>
                        <li>To resolve any customer support related issues;</li>
                        <li>Develop new products, services, features, and functionalities;</li>
                        <li>To perform research and analysis for our understanding, information, analysis, services and technologies in order to provide all users improved quality of care; and ensuring that the content and advertising displayed are customized to the User’s interests and preferences;</li>
                        <li>Generating and maintaining User friendly App;</li>
                        <li>Provide personalized features;</li>
                        <li>Facilitating payment collection and remittance activities as needed;</li>
                        <li>Maintaining regular communications with the User concerning transactions the User    initiates, such as requesting information or assistance, submitting a loan request, making payments, transferring funds, and giving updates on transactions etc.</li>
                        <li>Modifying the App from time to time to cater to the User’s interests; and</li>
                        <li>Providing the App and the functions and features therein, efficiently.</li>
                    </ul>

                    <p className="my-6"><b>Collection of Personal Data</b></p>
                    <p className="my-3">The methods by which We collect User’s Personal Data include but are not limited to the following:</p>
                    <ol className="list-decimal ml-6 flex flex-col gap-6 md:gap-12">
                        <li>Access the Platform or use any of the features of the Platform.</li>
                        <li>When User provides his/her Personal Data to Us or provides their personal information during any registration to: </li>
                        <li>verify the User’s identity; </li>
                        <li>determine the User’s eligibility for dealing in financial products using, inter alia, our Platform and such additional rules that may be determined by Us, and </li>
                        <li>to safeguard against illegal activities like fraud, cheating, and misappropriation.</li>
                        <li>Information collected from the User’s browser sends whenever on visiting the Platform, accessing the Website or logging in to the User’s Account.  We may use third party services to collect, monitor, process and analyse such Personal Data. This information is kept completely confidential and secure.</li>
                        <li>Information taken during the course of a service provided by Us to the User;</li>
                        <li>Through User’s computer/device, once User has have granted permissions to Our Platform;</li>
                        <li>Through PPIPL’s Representatives who are bound by confidentiality norms; and </li>
                        <li>Though the use of Cookies.</li>
                    </ol>

                    <p className="my-6"><b>Sharing of Information</b></p>

                    <ol className="list-decimal ml-6 flex flex-col gap-6 md:gap-12">
                        <li>
                            Information shared by User will not be shared for any purpose other than in connection with the Platform.  We may disclose information to third party to comply with relevant rules and regulations. We will not rent, sell, or share any Personal Data pertaining to the User to third parties unless:
                            <ul className="list-disc ml-6 flex flex-col gap-2">
                                <li>it is pursuant to obtaining the User’s permission;</li>
                                <li>it is in connection with the services being rendered through the Platform;</li>
                                <li>it is to help investigate, prevent, or take action regarding unlawful and illegal activities; suspected fraud, potential threat to the safety or security of any person, violations of PPIPL’s terms and conditions, or as a defence against legal claims or to detect duplicate or multiple accounts to prevent abuse of our services;</li>
                                <li>it is a case of special circumstances including compliance with court orders, requests/order, notices from legal authorities or law enforcement agencies compel us to make such disclosure; and</li>
                                <li>it forms part of the information We share with advertisers on an aggregate basis. Additionally, we reserve the right to disclose Personal Data to third parties in a non-identifiable, anonymous or aggregated format to understand customer trends and patterns and manage and improve our business relations. The transactions above will be governed by the relevant agreements and will be protected by statutory Data Protection Laws of India. We take User’s consent to publish any feedback received from User on our Platform.</li>
                            </ul>
                        </li>
                        <li>The Users' financial transactions take place on a secure Platform and are encrypted. This is the most advanced type of security available today. However, the User is urged to exercise reasonable precaution and restraint when maintaining any sensitive information like payment details on the platform.</li>
                        <li>The Company urges User to maintain confidentiality and security while distributing Personal Data, PPIPL disclaims liability from any infringement caused by breach of security of any third party which are beyond the reasonable control of PPIPL including but not limited to, acts of government, unauthorized access to the computer data or storage device, errors due to technology, computer hacking, poor internet quality and telephone services of the User,</li>
                    </ol>

                    <p className="my-6"><b>Information Security</b></p>
                    <ol className="list-decimal ml-6 flex flex-col gap-6 md:gap-12">
                        <li>We have implemented reasonable security practices and standards in compliance with Industry Standards to store the information provided by the User. We follow a comprehensive documented information security programme and information security policies that contain managerial, technical, operational, and physical security control measures including security measures like role-based access, password protection and encryption that are commensurate with the information assets being protected with the nature of business. Access to affiliates, third parties and employees is restricted to the purpose and need to know basis and within guidelines of Data Protection Rules and Regulations and Privacy Policy.</li>
                        <li>We shall use generally accepted industry standards to protect the User information submitted to Us, both during transmission and upon receipt. However, please be advised that, no method of transmission over the Internet, or method of electronic storage, is completely secure. Therefore, even though We strive to use commercially acceptable means to protect User information, We cannot guarantee its absolute security and User’s use of the App is at User’s sole risk and discretion. The company disclaims all liability for any errors caused during such transmission. We also cannot warrant that such User information may not be misused in the event our safeguards and protocols are breached by a malicious third-party. Further, we disclaim all liability for actions by Users who they have shared their information with. </li>
                        <li>If a password is used to help protect User accounts and account information, it is the responsibility of the User to keep the password confidential. Users have to ensure that User always logs out, before sharing the device with a third party and it is advised that the User utilize a service to protect access to the User’s device.</li>
                        <li>It is hereby clarified that the collection, usage, and sharing of User information by Us shall be in accordance with the provisions of the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011 and other applicable laws.</li>
                        <li>However, User is advised to maintain reasonable security measures in protecting sensitive data. PPIPL will take all reasonable steps to maintain security. Company will not be liable for any breach of security beyond reasonable measures, or any unintended loss or disclosure of information caused in relation to User’s Personal Information.</li>
                    </ol>

                    <p className="my-6"><b>Cookie Policy</b></p>

                    <p>
                        We use ‘cookies’ to store information, including visitors’ preferences, and the pages on the Platform that the visitor accessed or visited. As part of Cookie, we will get access to User’s email id. The information obtained from cookies is used to optimize users’ experience, analyse traffic on the Website and track user trends.  by customizing Our web page content based on visitors’ browser type and/ or other information. This data is for internal use of the company and will not be transferred to a third party. Cookies can be permanent or temporary. Users may choose to adjust their cookie settings on their device. If removed, User may be prevented from using certain features of our Website. We do not control the use of cookies by third parties.
                    </p>

                    <p className="my-6"><b>Third Party Privacy Policies</b></p>

                    <p>
                        PPIPL does not disclose Personal Data to third parties in the normal course of operations. Our Privacy Policy does not apply to other advertisers, Websites, or applications. User must use his/her own discretion while accessing third-party Websites, PPIPL bears no liability in this regard. The User is hereby advised to consult the privacy policy of the respective third-party servicers for information on their policies and practices.
                    </p>

                    <p className="my-6"><b>Terms of Usage and Procedure for Removing Username from the Records</b></p>
                    <ol className="list-decimal ml-6 flex flex-col gap-6 md:gap-12">
                        <li>PPIPL will store all records of communications including phone calls placed and received for orders, inquiries, feedback, or any other purposes. This data is regarded as confidential and will not be disclosed to other parties unless legally required. Such data and information will be the sole property of PPIPL. </li>
                        <li>When a User is desirous of having his/her name and other details removed from our records, immediately upon receiving the User’s written request to that effect We shall, subject to the terms hereof, remove and/delete all such information. PPIPL will store personal information for a reasonable period from the last date of use of the service of the Platform or as long as required by law.  However, such withdrawal shall not affect the legality of processing of the personal data based on consent before its withdrawal.</li>
                        <li>If the User no longer wishes to receive notifications about our services, The User may change his/her notification preferences by contacting us at support@tapinvest.in. However, the Platform reserves the right to send important notifications even after the User withdraws consent to receive updates for some platform-required procedures.</li>
                    </ol>

                    <p className="my-6"><b>Procedure for Correcting Inaccuracies In User Information</b></p>
                    <p>
                    The User may correct or update any information online. The information will be updated within a reasonable period of time. The User is responsible for maintaining the accuracy of information available on the Platform. If the User provides incorrect, outdated, inaccurate or incomplete information or if PPIPL has reasonable grounds to suspect that information provided by User is provides incorrect, outdated, inaccurate or incomplete, PPIPL has sole discretion to discontinue access or services. PPIPL may choose to not update, delete or correct Users personal information under certain circumstances including but not limited to evaluative purposes or in case of documents necessary for litigation.  In the event of loss of access details, the User may retrieve the same or receive new access details by sending an e-mail to: support@tapinvest.in.
                    </p>

                    <p className="my-6"><b>Grievance Officer</b></p>

                    <p>
                    In accordance with Information Technology Act, 2000 and rules made there under, the name and contact details of our Grievance Officer are provided below:
                    </p>

                    <div className="flex flex-col gap-3 mt-3">
                        <p><b>Name:</b> Gururaja B</p>
                        <p><b>Address:</b>  #2732, Ground & First floor, 16th Cross, 27th Main Rd, Sector 2, HSR Layout, Bengaluru, Karnataka 560102</p>
                        <p><b>Tel:</b> +91-9036959302</p>
                        <p><b>Email:</b> gururaja.b@tapinvest.in</p>
                    </div>

                </div>
            </div>
            <Footer />
        </>

    );
};

PrivacyPolicy.displayName = "PrivacyPolicy";

export default PrivacyPolicy;