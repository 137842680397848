import React, { useCallback, useState } from "react";
import {
    usePartnersSummary,
    usePartners,
    useSubPartnerInvestments,
} from "./usePartners";
import PartnersTable from "./PartnersTable";
import { useNavigate } from "react-router-dom";
import { AddCircle, SearchOutlined } from "@mui/icons-material";
import PartnersDrawer from "./PartnersDrawer/PartnersDrawer";
import { useLayoutStore } from "../../components/organisms/Layout/store";
import { useDelay } from "../../hooks/useDelay";
import TableSkeleton from "../../components/TableSkeleton";
import Input from "../../components/Fields/Input";
import { debounce } from "../../utils/debounce";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import { NetworkManager } from "../../services";
import { useDownloadCsv } from "../../hooks/useDownloadCsv";
import { CLIENTS_EVENTS } from "./constants";
import { useAnalytics } from "../../hooks/useAnalytics";
import { getRupee } from "../../utils/numbers";
import { Tab, Tabs } from "@mui/material";
import { useQuery } from "react-query";
import { getProfile } from "../../hooks/useGetPartnerProfile";
import PartnersInvestmentTable from "./PartnersInvestmentTable";

interface PartnerProps {}

const Partners: React.FC<PartnerProps> = () => {
    const [pageSize, setPageSize] = useState(10);
    const [pageNo, setPageNo] = useState(0);
    const [orderBy, setOrderBy] = useState("firstName");
    const [search, setSearch] = useState("");
    const [order, setOrder] = useState<"asc" | "desc">("asc");

    const { data: profileData } = useQuery(`${["getProfile"]}`, getProfile);

    const [tab, setTab] = useState(0);

    const handleTabChange = (e, newValue: number) => {
        setTab(newValue);
    };

    // const { csvLoading, downloadCsv } = useDownloadCsv({
    //     path: NetworkManager.PATH.csvPartnerClient,
    //     filename: "partners.csv",
    // });

    const { track } = useAnalytics();

    const { setIsPartnerDrawerOpen, setPartnerDetails } = useLayoutStore(
        ({ setPartnerDetails, setIsPartnerDrawerOpen }) => ({
            setPartnerDetails,
            setIsPartnerDrawerOpen,
        })
    );
    const navigate = useNavigate();

    const handleChangePage = (event, newPage) => {
        setPageNo(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setPageNo(0);
    };

    const handleRowClick = (row: any) => {
        if (row.url && row.isKycVerified) {
            navigate(`/partners/${row.url}`);
        } else {
            // setPartnerDetails({
            //     name: row.originalName,
            //     email: row.originalEmail,
            //     pan: row.pan,
            // });
            // setIsPartnerDrawerOpen(row.clientPublicId);
        }
    };

    const handleAddNewClient = useCallback(() => {
        setIsPartnerDrawerOpen(null);
        track({ eventName: CLIENTS_EVENTS.Add_new_Client });
    }, []);

    const handleChangeSort = (e, columnId) => {
        if (columnId === orderBy) {
            setOrder(order === "desc" ? "asc" : "desc");
        }
        setOrderBy(columnId);
    };

    const handleSearch = (e) => {
        setSearch(e.target.value);
    };

    const { data, isLoading } = usePartners({
        payload: {
            id: profileData?.data?.profile.id,
            pageNo,
            pageSize,
            orderBy,
            order,
            search,
        },
    });

    const isClientsReady = useDelay(isLoading, 500);

    const { data: summaryData, isLoading: isSummaryLoading } =
        usePartnersSummary();

    const {
        subPartnerInvestedCount,
        totalEarnedIncome,
        totalCurrentMonthEarnedIncome,
        subPartnerCount,
    } = summaryData?.data || {};

    const debounceHandleSearch = debounce(handleSearch, 500);

    return (
        <div className="my-6 max-w-7xl mx-auto flex flex-col gap-4">
            {/* Clients Summary */}
            <div className="grid grid-cols-2 xl:grid-cols-4 border border-stone-300 rounded-lg bg-black/[.02]">
                <div className="p-4 md:p-6 border-r border-r-stone-300 max-xl:border-b max-xl:border-b-stone-300">
                    <h3 className="text-stone-700 font-semibold text-xs uppercase mb-1">
                        Sub Partner Count
                    </h3>
                    <p className="text-2xl font-medium text-stone-700">
                        {subPartnerCount}
                    </p>
                </div>
                <div className="p-4 md:p-6 xl:border-r xl:border-r-stone-300 max-xl:border-b max-xl:border-b-stone-300">
                    <h3 className="text-stone-700 font-semibold text-xs uppercase mb-1">
                        Sub Partner Invested Count
                    </h3>
                    <p className="text-2xl font-medium text-stone-700">
                        {subPartnerInvestedCount}
                    </p>
                </div>
                <div className="p-4 md:p-6 border-r border-r-stone-300">
                    <h3 className="text-stone-700 font-semibold text-xs uppercase mb-1">
                        Current Month Income
                    </h3>
                    <p className="text-2xl font-medium text-stone-700">
                        {getRupee.format(totalCurrentMonthEarnedIncome)}
                    </p>
                </div>
                <div className="p-4 md:p-6">
                    <h3 className="text-stone-700 font-semibold text-xs uppercase mb-1">
                        Total Earned Income
                    </h3>
                    <p className="text-2xl font-medium text-stone-700">
                        {getRupee.format(totalEarnedIncome)}
                    </p>
                </div>
            </div>
            <Tabs
                className="mt-2 mb-4"
                classes={{
                    indicator: "!bg-green-700",
                    flexContainer: "border-b border-b-black/[0.08]",
                }}
                value={tab}
                onChange={handleTabChange}
                aria-label="deals-tabs"
            >
                <Tab
                    label="All Partners"
                    className="!capitalize !font-medium"
                    classes={{
                        selected: "!text-green-700",
                    }}
                    id="all-partners"
                    aria-controls="deals-tabpanel-0"
                />
                <Tab
                    label="All Partner Investments"
                    className="!capitalize !font-medium"
                    classes={{
                        selected: "!text-green-700",
                    }}
                    id="all-partners-investments"
                    aria-controls="deals-tabpanel-0"
                />
            </Tabs>
            <div className="flex flex-col gap-3 items-center md:flex-row md:items-center mb-2">
                <Input
                    prefixIcon={<SearchOutlined />}
                    placeholder="Search"
                    onChange={debounceHandleSearch}
                    className="ml-2 ps-11"
                />
                {/* <div className="flex items-center gap-3">
                    <button
                        onClick={downloadCsv}
                        disabled={csvLoading}
                        type="button"
                        className="bg-white shadow border border-slate-300 flex items-center gap-2 text-sm  text-gray-800 rounded-lg font-semibold h-10 w-fit px-4"
                    >
                        <DownloadForOfflineOutlinedIcon fontSize="small" />
                        Download Excel
                    </button> 
                   <button
                        type="button"
                        onClick={handleAddNewClient}
                        className="bg-green-700 flex items-center gap-2 text-sm disabled:bg-green-700/80 text-white rounded-xl font-bold h-10 w-fit px-4"
                    >
                        <AddCircle fontSize="small" />
                        Add New Partner
                    </button> 
                </div> */}
            </div>
            {/* Clients Table */}
            {isClientsReady && !isLoading ? (
                tab === 0 ? (
                    <PartnersTable
                        data={data?.data}
                        pageSize={pageSize}
                        pageNo={pageNo}
                        orderBy={orderBy}
                        order={order}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        handleRowClick={handleRowClick}
                        handleChangeSort={handleChangeSort}
                    />
                ) : (
                    <PartnersInvestmentTable
                        pageSize={pageSize}
                        pageNo={pageNo}
                        orderBy={orderBy}
                        order={order}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        handleRowClick={handleRowClick}
                        handleChangeSort={handleChangeSort}
                    />
                )
            ) : (
                <TableSkeleton />
            )}
            <PartnersDrawer />
        </div>
    );
};

Partners.displayName = "Partners";

export default Partners;
