import { useQuery } from "../services/NetworkManager";
import { NetworkManager } from "../services";

export function getProfile() {
  return NetworkManager.Call({
    method: "get",
    path: NetworkManager.PATH.getPartnerWithReferral,
  });
}

export default function useGetPartnerProfile() {
  const { data, isLoading } = useQuery(`${["getPartner"]}`, getProfile);

  return { data, isLoading };
}

export const getCreateP2PPaymentLink = ({
  clientPublicId,
  amount,
  investmentType,
  totalProjectedValue,
  returns,
  payoutDate,
}: any) => {
  return NetworkManager.Call({
    method: "post",
    path: NetworkManager.PATH.generateUserP2PPaymentLink,
    data: {
      clientPublicId: clientPublicId,
      amount: amount,
      investmentType: investmentType,
      totalProjectedValue: totalProjectedValue,
      returns: returns,
      payoutDate: payoutDate,
    },
  });
};

export const getCreateWalletPaymentLink = ({ clientPublicId, amount }: any) => {
  return NetworkManager.Call({
    method: "post",
    path: NetworkManager.PATH.generateUserWalletPaymentLink,
    data: {
      clientPublicId,
      amount,
    },
  });
};
