import { Dialog } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useOnboardingStore } from "./store";
import Checkbox from "../../components/Fields/Checkbox";
import { NetworkManager } from "../../services";
import { useOnboardingNavigate } from "./store";
import { ONBOARDING_EVENTS, ONBOARDING_PAGE_TYPE } from "./constants";
import { useAnalytics } from "../../hooks/useAnalytics";

interface AgreementModalProps { };

const AgreementModal: React.FC<AgreementModalProps> = () => {
    const [isLoading, setLoading] = useState(false);
    const [agreement, setAgreement] = useState("");
    const [contractPublicId, setContractPublicId] = useState();
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const { isContractModalOpen, setIsContractModalOpen } = useOnboardingStore(({ isContractModalOpen, setIsContractModalOpen }) => ({ isContractModalOpen, setIsContractModalOpen }));
    const { handleNextPage } = useOnboardingNavigate();
    const { track } = useAnalytics();

    const getAgreementDetails = async () => {
        const agreementResponse = await NetworkManager.Call({
            method: 'get',
            path: NetworkManager.PATH.getPartnerOnboardingContract,
        });

        setAgreement(agreementResponse?.data.content)
        setContractPublicId(agreementResponse?.data.publicId)
    };

    const acceptTerms = async () => {
        setLoading(true);
        const signAgreementResponse = await NetworkManager.Call({
            method: 'post',
            path: NetworkManager.PATH.partnerSignContract,
            data: {
                profileType: "PARTNER",
                signed: true,
                contractPublicId
            }
        });
        track({ eventName: ONBOARDING_EVENTS.KYC_TnC_Success });
        // Navigate to Deals
        handleNextPage(ONBOARDING_PAGE_TYPE.LOGGED_IN)
        // Close dialog 
        setIsContractModalOpen(false);

        setLoading(false);
    }

    useEffect(() => {
        if (isContractModalOpen && !agreement) {
            getAgreementDetails();
        }
    }, [isContractModalOpen]);

    return (
        <Dialog maxWidth="md" fullWidth open={isContractModalOpen}>
            <div>
                <div className="border-b border-b-stone-200 p-6 bg-primary sticky top-0 z-20">
                    <h2 className="text-xl text-stone-800 font-semibold">Agreement</h2>
                </div>
                <div className="bg-white py-3 px-6 text-sm">
                    <div dangerouslySetInnerHTML={{ __html: agreement }} />
                </div>
                <div className="flex justify-between items-center border-t border-t-stone-200 p-6 bg-primary sticky bottom-0">
                    <Checkbox
                        id="acceptTerms"
                        className="accent-green-600"
                        label="I have read and agreed to the terms and conditions"
                        labelClassName="text-stone-500"
                        onChange={(e) => setAcceptedTerms(e.target.checked)}
                    />
                    <button
                        type="button"
                        disabled={!acceptedTerms || isLoading}
                        onClick={acceptTerms}
                        className="h-10 rounded-xl px-7 text-white font-semibold bg-green-600 disabled:bg-green-700/60 border border-green-700"
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </Dialog>
    );
};

AgreementModal.displayName = "AgreementModal";

export default AgreementModal;