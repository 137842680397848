import React from 'react';
import Tables from '../../components/organisms/Tables/Tables';
import Box from '@mui/material/Box';
import { getRowsAndCols } from './utils';
import { SORTABLE_COLUMNS } from '../../pages/Investments/constants';

const columns = [
	{ id: 'dealName', label: 'Deal Name', minWidth: 170, align: 'left' },
	{
		id: 'clientName',
		label: 'Client Name',
		minWidth: 170,
		align: 'left',
	},
	{
		id: 'investedDate',
		label: 'Invested on',
		minWidth: 100,
		align: 'left',
	},
	{
		id: 'investedAmount',
		label: 'Invested Amount',
		minWidth: 100,
		align: 'left',
	},
	{ id: 'payoutOn', label: 'Payout On', minWidth: 100, align: 'left' },
	{
		id: 'payoutAmount',
		label: 'Payout Amount',
		minWidth: 100,
		align: 'left',
	},
	{
		id: 'irr',
		label: 'IRR',
		minWidth: 100,
		align: 'left',
	},
	{
		id: 'earnedIncome',
		label: "Your Earning",
		minWidth: 100,
		align: 'left'
	}
];

const InvestmentTable = ({
	data,
	pageSize,
	pageNo,
	orderBy,
	order,
	handleChangePage,
	handleChangeRowsPerPage,
	handleChangeSort
}) => {
	const row = getRowsAndCols(data);
	const totalData = data?.data.totalItems;
	return (
		<Box>
			<Tables
				totalData={totalData}
				columns={columns}
				sortableColumns={SORTABLE_COLUMNS}
				rows={row}
				handleChangePage={handleChangePage}
				handleChangeRowsPerPage={
					handleChangeRowsPerPage
				}
				handleChangeSort={handleChangeSort}
				rowsPerPage={pageSize}
				page={pageNo}
				orderBy={orderBy}
                order={order}
			/>
		</Box>
	);
};

export default InvestmentTable;
