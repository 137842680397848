import { useQuery } from "../../services/NetworkManager";
import { NetworkManager } from "../../services";

export function getAllClients({ queryKey }) {
    const [_, pageNo, pageSize, orderBy, order, search] = queryKey[0].split(",");


    return NetworkManager.Call({
        method: 'post',
        path: NetworkManager.PATH.clients,
        data: {
            currentPage: pageNo,
            pageSize,
            sortBy: orderBy,
            sortDir: order.toUpperCase(),
            searchPhrase: search
        }
    })
};

export function getClientSummary() {
    return NetworkManager.Call({
        method: 'get',
        path: NetworkManager.PATH.clientSummaryData,
    })
};

export function getClientKycStatus({ queryKey }) {
    const id = queryKey[0].split(",")[1];

    return NetworkManager.Call({
        method: 'get',
        path: NetworkManager.PATH.clientKycStatus.replace('{{clientId}}', id),
    })
};

export function getClientVirtualAccount({ queryKey }) {
    const id = queryKey[0].split(",")[1];

    return NetworkManager.Call({
        method: 'get',
        path: NetworkManager.PATH.clientVirtualAccount.replace('{{clientId}}', id),
    })
}

export function useClient({ payload }) {
    const { pageNo, pageSize, orderBy, order, search } = payload;
    const { data, isLoading } = useQuery(`${['clients', pageNo, pageSize, orderBy, order, search]}`, getAllClients);
    return { data, isLoading };
}

export function useClientSummary() {
    const { data, isLoading } = useQuery('clientSummary', getClientSummary);

    return { data, isLoading };
}

export function useClientKycStatus(clientId?: string) {
    const { data, isLoading } = useQuery(`${['clientKycStatus', clientId]}`, getClientKycStatus, { enabled: !!clientId });
    return { data, isLoading };
}

export function useClientVirtualAccount(clientId?: string) {
    const { data, isLoading } = useQuery(`${['clientVirtualAccount', clientId]}`, getClientVirtualAccount, { enabled: !!clientId })
    return { data, isLoading };
}
