import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import FirstStep from './FirstStep';
import AddOrEditClient from './AddOrEditClient';
import PrimaryClientStep from './PrimaryClientStep'; // Assuming you have a PrimaryClientStep component
import { ArrowBack } from '@mui/icons-material';

interface FamilyDrawerProps {
  isOpen: boolean; // Prop to control visibility
  onClose: () => void; // Callback to close the drawer
  clientToEdit?: any;
  isEditing?: boolean;
}

const FamilyDrawer: React.FC<FamilyDrawerProps> = ({
  isOpen,
  onClose,
  clientToEdit,
  isEditing,
}) => {
  const [familyLabel, setFamilyLabel] = useState<string>('');
  const [step, setStep] = useState<number>(0); // Step state to control steps
  const [dataObject, setDataObject] = useState<any>({
    familyName: '',
    familyPublicId: null,
    userDetails: [],
  });

  useEffect(() => {
    if (clientToEdit) {
      setFamilyLabel(clientToEdit.familyName);
      setDataObject({
        familyName: clientToEdit.familyName,
        familyPublicId: clientToEdit.publicId,
        userDetails: [], // Initialize with an empty array or existing client details if any
      });
      setStep(1); // Go to the AddOrEditClient step if clientToEdit is passed
    } else {
      setStep(0); // Start from the first step if no clientToEdit is passed
    }
  }, [clientToEdit]);

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
      resetSteps();
      setDataObject({
        familyName: '',
        familyPublicId: null,
        userDetails: [],
      });
      setFamilyLabel('');
    }
  };

  const resetSteps = () => {
    setStep(0);
    setFamilyLabel('');
    setDataObject({
      familyName: '',
      familyPublicId: '',
      userDetails: [],
    });
  };

  // Move to AddOrEditClient step
  const moveToAddClient = (newFamilyLabel: string) => {
    setFamilyLabel(newFamilyLabel);
    setDataObject((prev) => ({
      ...prev,
      familyName: newFamilyLabel,
    }));
    setStep(1); // Move to the next step
  };

  // Move to PrimaryClientStep step
  const moveToPrimaryClient = (userDetails) => {
    setDataObject((prev) => ({
      ...prev,
      userDetails,
    }));
    setStep(2); // Move to the PrimaryClientStep
  };

  return (
    <>
      <div
        id="client-drawer"
        className={clsx(
          'transition-all flex flex-col duration-300 transform fixed top-0 end-0 bottom-0 z-[80] w-full md:w-[600px] bg-primary overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300',
          {
            'translate-x-0': isOpen,
            'translate-x-full': !isOpen,
          },
        )}
      >
        {/* Header */}
        <div className="border-b border-b-stone-300 px-4 py-4 md:px-6 md:py-8">
          <div className="flex gap-8 items-center">
            {(step === 2 || (step === 1 && !isEditing)) && (
              <button
                onClick={() => setStep(step - 1)}
                className="bg-green-700/10 flex items-center justify-center p-1.5 h-9 w-9 rounded-full text-green-700"
              >
                <ArrowBack fontSize="small" />
              </button>
            )}
            <h3 className="text-lg md:text-2xl font-semibold mb-1.5">
              {step === 0
                ? 'Add family name'
                : step === 1
                ? isEditing
                  ? 'Edit Client Details'
                  : 'Add Client Details'
                : 'Set Primary Clients'}
            </h3>
          </div>
        </div>

        {/* Content */}
        <div className="px-4 py-4 md:px-6 md:py-6">
          {step === 0 && (
            <FirstStep
              setFamilyLabel={setFamilyLabel}
              handleNext={() => moveToAddClient(familyLabel)}
              familyLabel={familyLabel}
            />
          )}
          {step === 1 && (
            <AddOrEditClient
              initialData={clientToEdit}
              familyLabel={familyLabel}
              handleNext={(userDetails) => moveToPrimaryClient(userDetails)}
              dataObject={dataObject}
            />
          )}
          {step === 2 && (
            <PrimaryClientStep
              dataObject={dataObject}
              setDataObject={setDataObject}
              setFamilyLabel={setFamilyLabel}
              onClose={onClose}
            />
          )}
        </div>
      </div>

      {isOpen && (
        <div
          onClick={handleOverlayClick}
          className="flex fixed top-0 right-0 bottom-0 left-0 bg-black/50 z-[70]"
        />
      )}
    </>
  );
};

FamilyDrawer.displayName = 'FamilyDrawer';

export default FamilyDrawer;
