import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { NetworkManager } from './services';
import ProtectedRoutes from './pages/Login/ProtectedRoutes';
import Deals from './pages/Deals/Deals';
import Layout from './components/organisms/Layout/Layout';
import DealDetail from './pages/DealDetail/DealDetail';
import Onboarding from './pages/Onboarding/Onboarding';
import { Toaster } from 'react-hot-toast';
import Clients from './pages/Clients/Clients';
import ClientDetail from './pages/ClientDetail/ClientDetail';
import Investments from './pages/Investments/Investments';
import Home from './pages/Home/Home';
import Terms from './pages/Terms/Terms';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import RiskDisclosure from './pages/RiskDisclosure/RiskDisclosure';
import Profile from './pages/Profile/Profile';
import ProductDeck from './pages/ProductDeck/ProductDeck';
import TapAcademy from './pages/TapAcademy/TapAcademy';
import UserPayment from './pages/UserPayment/UserPayment';
import BondsDealDetail from './pages/BondsDealDetail/BondsDealDetail';
import { BONDS_DEALS_ENABLED } from './flags';
import Partners from './pages/Partners/Partners';
import PartnersDetail from './pages/partnersDetail/PartnersDetail';
import FamilyAccount from './pages/familyAccount/FamilyAccount';
import FamilyDetails from './pages/FamilyDetail/FamilyDetails';

function App() {
  return (
    <div>
      <NetworkManager.CustomQueryClientProvider>
        <Toaster />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/risk-disclosure" element={<RiskDisclosure />} />
          <Route element={<ProtectedRoutes />}>
            <Route path="*" element={<Navigate to="/deals" replace={true} />} />
            <Route element={<Layout />}>
              <Route path="/clients" element={<Clients />} />
              <Route path="/partners" element={<Partners />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/clients/:clientId" element={<ClientDetail />} />
              <Route path="/family-account" element={<FamilyAccount />} />
              <Route path="/family-account/:familyAccountId" element={<FamilyDetails />} />
              <Route path="/partners/:partnerId" element={<PartnersDetail />} />
              <Route path="/deals" element={<Deals />} />
              <Route path="/deals/:dealId" element={<DealDetail />} />
              {BONDS_DEALS_ENABLED && (
                <Route path="/bond-deals/:dealId" element={<BondsDealDetail />} />
              )}
              <Route path="/deals/:dealId/:financeType" element={<DealDetail />} />
              <Route path="/investments" element={<Investments />} />
              <Route path="/tap-academy" element={<TapAcademy />} />
              <Route path="/product-resources" element={<ProductDeck />} />
            </Route>
          </Route>
          <Route path="/login" element={<Onboarding />} />
          <Route path="/user-payment/:productType" element={<UserPayment />} />
        </Routes>
      </NetworkManager.CustomQueryClientProvider>
    </div>
  );
}

export default App;
