import React from 'react';
import { KYC_STATUS } from '../../../../pages/ClientDetail/constants';
import { ChevronRight } from '@mui/icons-material';
import { useLayoutStore } from '../../Layout/store';

const DealNameAndStatus = ({ name = '', status = '', isReinvestInvestment = false, dealData }) => {
	const { setIsReinvestModalOpen, setReinvestDeal } = useLayoutStore(({ setIsReinvestModalOpen, setReinvestDeal}) => ({ setIsReinvestModalOpen, setReinvestDeal }))

	const getKycStatusTag = (kycStatus) => {
		if (kycStatus == KYC_STATUS.REPAID_ON_TIME) {
			return <div className='bg-green-200 w-fit rounded-lg text-green-700 py-1 px-2 text-xs font-semibold'>Completed</div>

		}
		if (kycStatus == KYC_STATUS.SCHEDULED) {
			return <div className='bg-gray-200 w-fit rounded-lg text-gray-700 py-1 px-2 text-xs font-semibold'>Ongoing</div>
		}
	}

	const handleReinvest = (e) => {
		e.stopPropagation();
		setReinvestDeal(dealData)
		setIsReinvestModalOpen(true);
	};

	return (
		<>
			<div className='flex flex-col gap-1'>
				{name}
				<div className="flex items-center gap-2">
					{getKycStatusTag(status)}
					{isReinvestInvestment &&
						<button
							onClick={handleReinvest}
							className="bg-green-600 px-2 py-0.5 rounded-full text-white text-xs font-medium flex items-center gap-0.5"
						>
							Reinvest <ChevronRight fontSize="small" />
						</button>
					}
				</div>
			</div>
		</>

	);
};

export default DealNameAndStatus;
