import { usePostHog } from 'posthog-js/react'
import mixpanel from 'mixpanel-browser';

type CaptureEventParams<T> = {
    eventName: string;
    eventProperties?: T;
};

export const useAnalytics = () => {
    const posthog = usePostHog()

    const track = <T>({ eventName, eventProperties }: CaptureEventParams<T>) => {
        posthog.capture(eventName, eventProperties);
        mixpanel.track(eventName, eventProperties);
    }

    const reset = () => {
        posthog.reset()
        mixpanel.reset();
    }

    const identify = (userProperties) => {
        const { firstName, lastName, email, phone, publicId } = userProperties;
        if (publicId) {
            mixpanel.identify(publicId)

            if (email) {
                mixpanel.people.set({
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    phone: phone,
                    name: `${firstName} ${lastName}`
                })

                posthog?.identify(publicId, {
                    email: email,
                })
            }
        }
    }

    return {
        track,
        reset,
        identify
    }
}