import NameAndKYC from '../../components/organisms/Tables/CellRenderer/NameAndKYC';
import NameAndPAN from '../../components/organisms/Tables/CellRenderer/NameAndPAN';
import CurrencyAmount from '../../components/organisms/Tables/CellRenderer/CurrencyAmount';
import DealNameAndStatus from '../../components/organisms/Tables/CellRenderer/DealNameAndStatus';

import * as R from 'ramda';
import { numDifferentiation, twoDecimalNumber } from '../../utils/numbers';
import { getInvestmentStatusTag } from '../../pages/utils/investment';

function createData(dealName, clientName, investedDate, investedAmount, payoutOn, payoutAmount, irr, earnedIncome) {
    return { dealName, clientName, investedDate, investedAmount, payoutOn, payoutAmount, irr, earnedIncome };
  }

export const getRowsAndCols = (data) => {

    const rows = [];
    const d = R.path(["data", "items"], data);


    d?.forEach(element => {
        const status = element.isKycVerified ? <NameAndPAN name={element.name} pan={element.pan} /> : {value: <NameAndKYC name={element.name} />, kyc: false}
        const investedDateFormatted = new Date(element.investedOn).toLocaleDateString(undefined, { year: "numeric", month: "long", day: "numeric" });
        const payoutDateFormatted = new Date(element.maturityDate).toLocaleDateString(undefined, { year: "numeric", month: "long", day: "numeric" });

        rows.push(createData(
        <DealNameAndStatus 
        name={element.companyName} 
        status={element.status} 
        isReinvestInvestment={element.isReinvestInvestment}
        dealData={element}
        />, 
        <NameAndPAN name={element.clientName} pan={element.pan} />, investedDateFormatted, 
        <div className='flex items-center gap-1'>
<CurrencyAmount amount={element.amount} />
            {getInvestmentStatusTag(element.state)}
        </div>, 
        payoutDateFormatted, 
        <CurrencyAmount amount={element.payoutAmount} />, 
        `${twoDecimalNumber(element.irr)}%`,
        numDifferentiation(element.earnedIncome),
        ))
    });


    return rows ;
}