import React from "react";

interface TapLogoProps {
    isWhite?: boolean
};

const TapLogo: React.FC<TapLogoProps> = ({ isWhite }) => {
    const color = isWhite ? "white" : "black";

    return (
        <svg width="77" height="34" viewBox="0 0 77 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1.20435" y="6.4292" width="21.6455" height="6.01266" fill={color} />
            <rect x="5.41382" y="13.6443" width="13.2278" height="6.01266" fill={color} />
            <rect x="9.01831" y="20.8594" width="6.01265" height="6.01266" fill={color} />
            <path d="M47.1862 6.99341V10.8648H41.5986V26.071H37.5676V10.8648H32.0066V6.99341H47.1862Z" fill={color} />
            <path d="M55.5277 12.6874H59.3725V26.071H55.5277V24.4879C55.1995 25.0378 54.6674 25.4857 53.9312 25.8316C53.204 26.1686 52.419 26.3371 51.5765 26.3371C50.4323 26.3371 49.3813 26.0267 48.4235 25.4058C47.4656 24.785 46.7117 23.9424 46.1618 22.8781C45.6119 21.805 45.337 20.6342 45.337 19.3659C45.337 17.4324 45.9401 15.7916 47.1463 14.4435C48.3614 13.0954 49.8381 12.4214 51.5765 12.4214C52.4102 12.4214 53.1951 12.5943 53.9312 12.9402C54.6674 13.2861 55.1995 13.7296 55.5277 14.2706V12.6874ZM52.4279 22.6919C53.0754 22.6919 53.6785 22.5367 54.2372 22.2262C54.8048 21.9158 55.235 21.5167 55.5277 21.0289V17.7562C55.235 17.2506 54.8048 16.8382 54.2372 16.5189C53.6785 16.1996 53.0754 16.04 52.4279 16.04C51.4878 16.04 50.6984 16.3593 50.0598 16.9979C49.4212 17.6276 49.102 18.4169 49.102 19.3659C49.102 20.3149 49.4212 21.1087 50.0598 21.7473C50.6984 22.377 51.4878 22.6919 52.4279 22.6919Z" fill={color} />
            <path d="M69.8559 12.4214C71 12.4214 72.0466 12.7318 72.9956 13.3526C73.9534 13.9646 74.7029 14.8027 75.2439 15.867C75.7938 16.9313 76.0687 18.0976 76.0687 19.3659C76.0687 20.6342 75.7938 21.805 75.2439 22.8781C74.7029 23.9424 73.9534 24.785 72.9956 25.4058C72.0466 26.0267 71 26.3371 69.8559 26.3371C69.0133 26.3371 68.2239 26.1686 67.4878 25.8316C66.7605 25.4857 66.2328 25.0378 65.9046 24.4879V30.4879H62.0598V12.6874H65.9046V14.2706C66.2328 13.7207 66.7605 13.2772 67.4878 12.9402C68.2239 12.5943 69.0133 12.4214 69.8559 12.4214ZM69.0177 22.6919C69.949 22.6919 70.7295 22.377 71.3592 21.7473C71.9889 21.1087 72.3038 20.3149 72.3038 19.3659C72.3038 18.4258 71.9845 17.6364 71.3459 16.9979C70.7162 16.3593 69.9401 16.04 69.0177 16.04C68.3703 16.04 67.7627 16.1996 67.1951 16.5189C66.6275 16.8293 66.1973 17.2329 65.9046 17.7296V21.0023C66.1973 21.5078 66.623 21.9158 67.1818 22.2262C67.7406 22.5367 68.3525 22.6919 69.0177 22.6919Z" fill={color} />
        </svg>
    );
};

TapLogo.displayName = "TapLogo";

export default TapLogo;