import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

const Name = ({ name = 'Anil Sharma' }) => {
	return (
		<Box display={'flex'} flexDirection={'column'}>
			<Box
				display={'flex'}
				alignItems={'left'}
				justifyContent={'flex-start'}
			>
				<Typography
					sx={{
						fontSize: '14px',
						fontWeight: '500',
					}}
				>
					{' '}
					{name ? name : '-'}{' '}
				</Typography>
			</Box>
		</Box>
	);
};

export default Name;
