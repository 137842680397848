import { CircularProgress } from "@mui/material";
import React from "react";
import clsx from "clsx";

interface KYCButtonProps {
    isLoading: boolean;
    text: string;
    className?: string;
    disabled?: boolean;
};

const KYCButton: React.FC<KYCButtonProps> = ({
    isLoading,
    text,
    className,
    disabled = false
}) => {
    return (
        <button
            type="submit"
            disabled={isLoading || disabled}
            className={clsx("relative bg-green-700 mt-9 text-sm disabled:bg-green-700/60 mx-auto text-white rounded-xl font-bold py-4 w-full", {
                "bg-opacity-80 text-opacity-50": isLoading
            }, className)}
        >
            <CircularProgress style={{color: "white"}} className={clsx("absolute text-white left-8 md:left-12", {
                "visible": isLoading,
                "invisible": !isLoading
            })} size={20} />

            {text}
        </button>
    );
};

KYCButton.displayName = "KYCButton";

export default KYCButton;