import NameAndKYC from "../../components/organisms/Tables/CellRenderer/NameAndKYC";
import NameAndPAN from "../../components/organisms/Tables/CellRenderer/NameAndPAN";
import CurrencyAmount from "../../components/organisms/Tables/CellRenderer/CurrencyAmount";

import * as R from "ramda";
import Name from "../../components/organisms/Tables/CellRenderer/Name";
import { formatDate } from "../../utils/date";

function createData(
    name,
    amount,
    balance,
    email,
    phone,
    clientPublicId,
    url,
    isKycVerified,
    pan,
    originalName,
    originalEmail
) {
    return {
        name,
        amount,
        balance,
        email,
        phone,
        id: clientPublicId,
        url: `/partners/${clientPublicId}`,
        isKycVerified,
        pan,
        clientPublicId,
        originalName,
        originalEmail,
    };
}

function createPartnerData(
    name,
    email,
    phone,
    investments,
    returns,
    url,
    isKycVerified
) {
    return {
        name,
        email,
        phone,
        investments,
        returns,
        url,
        isKycVerified,
    };
}

function createPartnerInvestmentData(name, email, phone, investments, returns) {
    return {
        name,
        email,
        phone,
        investments,
        returns,
    };
}

function createSubPartnerInvestmentData(
    companyName,
    email,
    clientName,
    amount,
    investedOn,
    earnedIncome
) {
    return {
        companyName,
        email,
        clientName,
        amount,
        investedOn,
        earnedIncome,
    };
}

export const getRowsAndCols = (data, type) => {
    const rows = [];
    const d =
        type === "client" || type === "subPartnerInvestments"
            ? R.path(["items"], data)
            : R.path(["elements"], data);

    type === "client"
        ? d?.forEach((element) => {
              const name = element.isKycVerified ? (
                  <NameAndPAN name={element.name} pan={element.pan} />
              ) : (
                  {
                      value: <NameAndKYC name={element.name} />,
                      kyc: false,
                  }
              );

              rows.push(
                  createData(
                      name,
                      <CurrencyAmount amount={element.totalInvested} />,
                      <CurrencyAmount amount={element.balance} />,
                      <Name name={element.email} />,
                      <Name name={element.phone} />,
                      element.clientPublicId,
                      element.url,
                      element.isKycVerified,
                      element.pan,
                      element.name,
                      element.email
                  )
              );
          })
        : type === "partnerInvestments"
        ? d?.forEach((element) => {
              const name = element.kycStatus.onboardingCompleted ? (
                  <NameAndPAN
                      name={
                          element.profile.firstName + element.profile.lastName
                      }
                      pan={element.pan}
                  />
              ) : (
                  {
                      value: <NameAndKYC name={"-"} />,
                      kyc: false,
                  }
              );

              rows.push(
                  createPartnerInvestmentData(
                      name,
                      element.profile.email,
                      element.profile.phone,
                      <CurrencyAmount
                          amount={element.partnerAggregate.totalInvested}
                      />,
                      <CurrencyAmount
                          amount={element.partnerAggregate.totalReturn}
                      />
                  )
              );
          })
        : type === "subPartnerInvestments"
        ? d?.forEach((element) => {
              rows.push(
                  createSubPartnerInvestmentData(
                      element.companyName,
                      element.email,
                      element.clientName,
                      <CurrencyAmount amount={element.amount} />,
                      formatDate(element.investedOn),
                      <CurrencyAmount amount={element.earnedIncome} />
                  )
              );
          })
        : d?.forEach((element) => {
              const name = element.kycStatus.onboardingCompleted ? (
                  <NameAndPAN
                      name={
                          element.profile.firstName + element.profile.lastName
                      }
                      pan={element.pan}
                  />
              ) : (
                  {
                      value: <NameAndKYC name={"-"} />,
                      kyc: false,
                  }
              );

              rows.push(
                  createPartnerData(
                      name,
                      element.profile.email,
                      element.profile.phone,
                      element.partnerAggregate.totalInvested,
                      element.partnerAggregate.totalReturn,
                      element.profile.id,
                      element.kycStatus.onboardingCompleted
                  )
              );
          });

    return rows;
};

const summaryLabelMap = {
    totalUsers: "TOTAL",
    investedUsers: "INVESTED",
    notInvestedUsers: "NOT INVESTED",
    kycPendingUsers: "KYC PENDING",
};

export const getSummaryData = (data) => {
    const returnData = [];

    const d = data?.data?.data;

    R?.keys(d)?.forEach((element) => {
        returnData.push({
            label: summaryLabelMap[element],
            value: d[element],
        });
    });

    return returnData;
};
