import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const CurrencyAmount = ({ amount, showZero = false }) => {
  let rupees = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  });
  return (
    <Box>
      <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
        {amount ? rupees.format(amount) : showZero ? 0 : '-'}
      </Typography>
    </Box>
  );
};

export default CurrencyAmount;
