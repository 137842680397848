import React from "react";
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Image from "../../assets/Image";
import { numDifferentiation, twoDecimalNumber } from "../../utils/numbers";
import { KeyboardArrowDown } from "@mui/icons-material";
import clsx from "clsx";
import { useState } from "react";
import { useDealStats } from "./useDealDetails";
import Skeleton from "@mui/material/Skeleton";

interface DealCompaniesProps {
    companies: {
        companyName: string;
        dealId: number;
        logoUrl: number;
        percentage: number;
    }[]
};

const DealCompanies: React.FC<DealCompaniesProps> = ({ companies }) => {
    const [expanded, setExpanded] = useState<number | false>(false);
    const [expandedDealId, setExpandedDealId] = useState(null);
    const [isPanelLoading, setIsPanelLoading] = useState(false);

    const { data, isLoading: isRequesting } = useDealStats(expandedDealId, !expandedDealId)

    const isLoading = isPanelLoading || isRequesting;

    const {
        onTimeRepaymentsPercentage,
        totalRaisedByCompany,
        maturedDeals,
        totalDeals,
        repaymentsDone,
        backedByImageUrl,
        clientImageUrl,
        totalRepayments
    } = data?.data || {};

    const handleChange = (panel: number, newExpanded: boolean, dealId: number) => {
        setExpanded(newExpanded ? panel : false);
        setIsPanelLoading(true);
        setTimeout(() => {
            setExpandedDealId(newExpanded ? dealId : null);
            setIsPanelLoading(false);
        }, 200);
    };


    return (
        <div className="mt-3">
            {companies.map((company, index) => (
                <MuiAccordion
                    className="!bg-primary border border-stone-200"
                    expanded={expanded === index}
                    onChange={(e, newExpanded) => handleChange(index, newExpanded, company.dealId)}
                    disableGutters
                    sx={{ '&:before': { height: '0px' } }}
                    elevation={0}
                >
                    <MuiAccordionSummary
                        aria-controls={`panel${index}d-content`}
                        id={`panel${index}d-header`}
                        expandIcon={<KeyboardArrowDown className={clsx({
                            "text-green-700": expanded !== index,
                            "text-stone-400": expanded === index
                        })} />}
                    >
                        <div className="flex justify-between items-center w-full">
                            <div className="flex items-center gap-3">
                                <div className="w-8 h-8 overflow-hidden rounded bg-zinc-200">
                                    <Image
                                        // @ts-ignore
                                        src={company.logoUrl as any}
                                        alt={company.companyName}
                                        className="w-full h-full"
                                    />
                                </div>
                                <h3 className="text-stone-700 font-medium">{company.companyName}</h3>
                            </div>
                            <span className="text-stone-700 font-medium mr-3">{twoDecimalNumber(company.percentage * 100)}%</span>
                        </div>
                    </MuiAccordionSummary>
                    <MuiAccordionDetails>
                        <div className="flex mt-auto w-full min-h-0 flex-col">
                            <div className="grid grid-cols-2 xl:grid-cols-4 gap-1">
                                <div>
                                    <h3 className="text-stone-700 font-semibold text-xs uppercase mb-1">Deals done</h3>
                                    {isLoading ? <Skeleton width={80} height={40} /> : <p className="text-lg font-medium text-stone-700">{maturedDeals} <span className="text-black/30">of {totalDeals}</span></p>}
                                </div>
                                <div>
                                    <h3 className="text-stone-700 font-semibold text-xs uppercase mb-1">Raised</h3>
                                    {isLoading ? <Skeleton width={80} height={40} /> : <p className="text-lg font-medium text-stone-700">{numDifferentiation(totalRaisedByCompany)}</p>}
                                </div>
                                <div>
                                    <h3 className="text-stone-700 font-semibold text-xs uppercase mb-1">Repayments</h3>
                                    {isLoading ? <Skeleton width={80} height={40} /> : <p className="text-lg font-medium text-stone-700">{repaymentsDone} of <span className="text-black/30">{totalRepayments}</span></p>}
                                </div>
                                <div>
                                    <h3 className="text-stone-700 font-semibold text-xs uppercase mb-1">On time payments</h3>
                                    {isLoading ? <Skeleton width={80} height={40} /> : <p className="text-lg font-medium text-stone-700">{onTimeRepaymentsPercentage} <span className="text-black/30">%</span></p>}
                                </div>
                            </div>
                            <div className="grid grid-cols-2 md:col-span-1 gap-1 mt-6">
                                {clientImageUrl && !isLoading && <div>
                                    <h3 className="text-stone-700 font-semibold text-xs uppercase mb-1">Clients</h3>
                                    <div className="h-16 mix-blend-darken max-w-64">
                                        {clientImageUrl && <Image
                                            // @ts-ignore
                                            src={clientImageUrl}
                                            alt={company.companyName}
                                        />}
                                    </div>
                                </div>}
                                {backedByImageUrl && !isLoading && <div>
                                    <h3 className="text-stone-700 font-semibold text-xs uppercase mb-1">Backed by</h3>
                                    <div className="h-16 mix-blend-darken max-w-64">
                                        {backedByImageUrl && <Image
                                            // @ts-ignore
                                            src={backedByImageUrl}
                                            alt={company.companyName}
                                        />}
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </MuiAccordionDetails>
                </MuiAccordion>
            ))
            }
        </div>
    );
};

DealCompanies.displayName = "DealCompanies";

export default DealCompanies;