import * as React from "react";
import Tables from "../../components/organisms/Tables/Tables";
import { getRowsAndCols } from "./utils";
import { PARTNER_SORTABLE_COLUMNS } from "./constants";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
} from "@mui/material";
import { baseColors } from "../../assets/theme";

const columns = [
    { id: "name", label: "Partner Name", minWidth: 170, align: "left" },
    {
        id: "email",
        label: "Email Address",
        minWidth: 170,
        align: "left",
    },
    {
        id: "phone",
        label: "Phone Number",
        minWidth: 170,
        align: "left",
    },
    {
        id: "investments",
        label: "Total Investment",
        minWidth: 100,
        align: "left",
    },
    {
        id: "returns",
        label: "Total Returns",
        minWidth: 170,
        align: "left",
    },
];

interface PartnersTableProps {
    data: {
        paginationDTO: any;
        currentPage: number;
        items: any[];
        pageSize: number;
        totalItems: 0;
    };
    pageSize: number;
    pageNo: number;
    orderBy: string;
    order: "asc" | "desc";
    type?: string;
    handleChangePage: (event: any, newPage: number) => void;
    handleChangeRowsPerPage: (event: any) => void;
    handleRowClick: (row: any) => void;
    handleChangeSort: (event: any, columnId: string) => void;
}

const PartnersTable: React.FC<PartnersTableProps> = ({
    data,
    pageSize,
    pageNo,
    orderBy,
    order,
    handleChangePage,
    handleChangeRowsPerPage,
    handleRowClick,
    handleChangeSort,
    type = "partner",
}) => {
    const row = getRowsAndCols(data, type);
    const totalData = data?.paginationDTO.totalPages;

    return (
        <Paper
            sx={{
                overflow: "hidden",
                borderRadius: "10px",
                boxShadow: "none",
                marginBottom: "20px",
                border: `1px solid ${baseColors.slate[300]}`,
            }}
        >
            <TableContainer
                sx={{
                    borderRadius: "10px",
                }}
            >
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => {
                                const columnId =
                                    PARTNER_SORTABLE_COLUMNS?.[column.id];

                                return (
                                    <TableCell
                                        key={column.id}
                                        sx={{
                                            minWidth: column.minWidth,
                                            backgroundColor: "#f6f6f2",
                                        }}
                                        sortDirection={
                                            orderBy === columnId ? order : false
                                        }
                                    >
                                        {columnId ? (
                                            <TableSortLabel
                                                active={orderBy === columnId}
                                                direction={
                                                    orderBy === columnId
                                                        ? order
                                                        : "asc"
                                                }
                                                classes={{
                                                    icon: "!text-black",
                                                }}
                                                onClick={(e) =>
                                                    handleChangeSort &&
                                                    handleChangeSort(
                                                        e,
                                                        columnId
                                                    )
                                                }
                                            >
                                                {column.label}
                                            </TableSortLabel>
                                        ) : (
                                            column.label
                                        )}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {row.map((row) => {
                            return (
                                <TableRow
                                    hover
                                    // className="cursor-pointer"
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row.code}
                                    sx={{
                                        backgroundColor:
                                            row?.name?.kyc === false
                                                ? "#FEF2F2"
                                                : "#FBFBF6",
                                    }}
                                >
                                    {columns.map((column) => {
                                        let value = row[column.id];

                                        // Determine the display value based on the conditions
                                        let displayValue;
                                        if (
                                            column.id === "dealName" &&
                                            typeof value === "object" &&
                                            value?.props?.name?.toLowerCase() ===
                                                "p2p"
                                        ) {
                                            // Create a new object with modified properties instead of altering the original
                                            displayValue = {
                                                ...value,
                                                props: {
                                                    ...value.props,
                                                    name: "Tap Reserve",
                                                },
                                            };
                                        } else {
                                            displayValue = value;
                                        }

                                        return (
                                            <TableCell
                                                key={column.id}
                                                onClick={() =>
                                                    handleRowClick &&
                                                    handleRowClick(row)
                                                }
                                            >
                                                {typeof displayValue ===
                                                "object"
                                                    ? displayValue?.value ||
                                                      displayValue
                                                    : displayValue}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 20]}
                component="div"
                count={totalData}
                rowsPerPage={pageSize}
                page={pageNo}
                sx={{
                    backgroundColor: "#f6f6f2",
                    ".MuiPaginationItem-root": {
                        backgroundColor: "#f6f6f2",
                    },
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
};

PartnersTable.displayName = "ClientTable";

export default PartnersTable;
