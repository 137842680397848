import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { useLayoutStore } from './store';
import TapLogo from '../../../pages/Onboarding/TapLogo';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import HandshakeIcon from '@mui/icons-material/Handshake';
import LogoutIcon from '@mui/icons-material/Logout';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import SchoolIcon from '@mui/icons-material/School';
import GroupsIcon from '@mui/icons-material/Groups';
import GroupIcon from '@mui/icons-material/Group';
import mixpanel from 'mixpanel-browser';
import { useNavigate } from 'react-router-dom';
import { HOME_NAVIGATION_EVENTS } from '../../../pages/Home/constants';

interface LeftNavProps {}

export const navList = [
  {
    _id: 1,
    target: '/deals',
    name: `Deals`,
    icon: <HandshakeIcon />,
    active: true,
  },
  {
    _id: 2,
    target: '/clients',
    name: `Clients`,
    icon: <GroupsIcon />,
    active: false,
  },
  {
    _id: 3,
    target: '/family-account',
    name: `Family Account`,
    icon: <GroupsIcon />,
    active: false,
  },
  {
    _id: 4,
    target: '/investments',
    name: `Investments`,
    icon: <AccountBalanceIcon />,
    active: false,
  },
  {
    _id: 5,
    target: '/partners',
    name: `Partners`,
    icon: <GroupIcon />,
    active: false,
  },
  {
    _id: 6,
    target: '/tap-academy',
    name: `Tap Academy`,
    icon: <SchoolIcon />,
    event: HOME_NAVIGATION_EVENTS.Tap_Academy,
    active: false,
  },
  {
    _id: 7,
    target: '/product-resources',
    name: `Product's Resources`,
    icon: <LibraryBooksIcon />,
    event: HOME_NAVIGATION_EVENTS.Products_Deck,
    active: false,
  },
];

const LeftNav: React.FC<LeftNavProps> = () => {
  const { isLeftNavOpen, setIsLeftOpen } = useLayoutStore(({ isLeftNavOpen, setIsLeftOpen }) => ({
    isLeftNavOpen,
    setIsLeftOpen,
  }));
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const logout = () => {
    localStorage.removeItem('token');
    mixpanel.reset();
    navigate('/login');
  };
  return (
    <>
      <div
        id="mobile-sidebar"
        className={clsx(
          'transition-all flex flex-col duration-300 transform fixed top-0 start-0 bottom-0 z-[70] w-60 bg-black py-8 overflow-y-auto end-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300',
          {
            'translate-x-0': isLeftNavOpen,
            'max-xl:-translate-x-full': !isLeftNavOpen,
          },
        )}
      >
        <div className="px-6">
          <div>
            <TapLogo isWhite />
          </div>

          <div className="mt-10 flex flex-col gap-8">
            {navList.map((navItem) => (
              <Link
                key={navItem._id}
                to={navItem.target}
                onClick={() => {
                  setIsLeftOpen();
                  if (navItem.event) {
                    mixpanel.track(navItem.event);
                  }
                }}
                className={clsx('text-gray-400 flex items-center gap-2 text-sm font-semibold', {
                  '!text-white': pathname.includes(navItem.target),
                })}
              >
                {navItem.icon}
                {navItem.name}
              </Link>
            ))}
          </div>
        </div>
        <div
          className="flex mt-auto cursor-pointer items-center text-white px-6 gap-2 text-xs font-semibold"
          onClick={logout}
        >
          <LogoutIcon fontSize="small" /> Logout
        </div>
      </div>
      {isLeftNavOpen && (
        <div
          className="flex xl:hidden fixed top-0 right-0 bottom-0 left-0 bg-black/50 z-[60]"
          onClick={() => setIsLeftOpen()}
        />
      )}
    </>
  );
};

LeftNav.displayName = 'LeftNav';

export default LeftNav;
