import React from 'react';

export const BONDS_FAQS = [
    {
        id: 0,
        question: "What is a Bond?",
        answer: "Bonds are a type of loan instrument, these are debt securities issued by the Government, private, and public corporations. Companies issue corporate bonds to raise money for a variety of purposes, such as building a new plant, purchasing equipment, or growing the business."
    },
    {
        id: 1,
        question: "What is the difference between Coupon & Yield?",
        answer: "Coupon rate of a bond is fixed for the complete tenure and does not change, yield changes depending on when and what price an investor is purchasing the bond."
    },
    {
        id: 2,
        question: "What do you mean by Senior secured Bonds?",
        answer: "Senior secured bond holders have the highest priority for repayment in case of default or bankruptcy proceedings. They are typically paid back before other creditors and equity holders."
    },
    {
        id: 3,
        question: "Do I need a demat account to Invest in bonds?",
        answer: "Yes, bonds are available in a dematerialized format, hence you need to have a demat account to hold them."
    },
    {
        id: 4,
        question: "How are bonds taxed?",
        answer: "Bonds are taxed under Income from other source (IFOS) method according to your tax slab."
    },
    {
        id: 5,
        question: "Is TDS deducted in Bonds?",
        answer: "Yes, there is a 10% TDS deduction on the interest component."
    },
    {
        id: 6,
        question: "How will repayment occur?",
        answer: "Tap Invest does not play a role in handling the repayments, the repayments happen directly from the issuer company's end, directly in your bank account according to the payout schedule."
    },
    {
        id: 7,
        question: "What are security components in terms of Bonds?",
        answer: <div>
            <ul className="list-disc pl-6 my-3">
                <li>Collateral or Security Interest: Some bonds are secured by specific assets of the issuer, such as real estate, equipment, or inventory. This collateral provides a source of repayment for bondholders if the issuer defaults.</li>
                <li>Guarantee: Guarantees from a parent company/another entity or a personal guaranetee can enhance the creditworthiness of the bond issue. These guarantees promise that the guarantor will fulfill the obligations of the issuer if they are unable to do so.</li>
                <li>Term sheet Covenants: Bond agreements may include financial and operational covenants that restrict the issuer's activities to ensure they maintain sufficient financial health to meet their bond obligations. These could include limitations on debt levels, dividend payments, or capital expenditures.</li>
                <li>Senior Repayments: All the bonds available on the platform are senior secured Bonds, which means that the repayment seniority in case of default would be the highest for these bond holders.</li>
            </ul>
        </div>
    },
]

export const BONDS_EVENTS = {
    "Bonds_Request_Callback": "Bonds_Request_Callback",
    "Bonds_Generate_Payment_Link": "Bonds_Generate_Payment_Link",
    "Bonds_Upload_CMR": "Bonds_Upload_CMR",
    "Bonds_select_client": "Bonds_select_client",
    "Bonds_Enter_Units": "Bonds_Enter_Units",
    "Clicked_Bonds_Detail_page": "Clicked_Bonds_Detail_page",
    "Clicked_Bonds_tab": "Clicked_Bonds_tab"
}