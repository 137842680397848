export const numDifferentiation = (num: number) => {
    const formatter = new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        notation: 'compact',
        compactDisplay: 'long',
    })
    // Format the number using the formatter
    let formatted = formatter.format(num);

    // Replace the compact notation symbol for thousand from "T" to "K"
    formatted = formatted.replace(/T$/, 'K');

    return formatted;
}

export const twoDecimalNumber = (num: number) => {
    const roundedNumber = Math.round(num * 100) / 100; // Round to two decimal places
    return roundedNumber.toFixed(2); // Ensure two decimal places
}

export const getRupee = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    maximumFractionDigits: 2,
    currency: 'INR',
});

export const getRupeeWithDecimal =  new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
});