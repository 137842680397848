import { useQuery, PATH, Call } from "../../services/NetworkManager";
import { DealsResponseType } from "./types";

export function getAllDeals () {
    return Call({
        method: 'get',
        path: PATH.deals,
    })
}

export function getKycStatusP2P ({queryKey}) {
    const clientId = queryKey[0].split(",")[1];

    return Call({
        method: 'get',
        path: PATH.p2pKycStatus.replace('{{clientId}}', clientId),
    })
}

export function getNudgesP2P () {
    return Call({
        method: 'get',
        path: PATH.p2pNudges,
    })
}

export function useDeal() {
    const { data, isLoading} = useQuery<DealsResponseType>(`${['deals']}`, getAllDeals);
    return {data, isLoading};
}

export function useKycStatusP2P(clientId?: string) {
    const { data, isLoading } = useQuery(`${['p2pKycStatus', clientId]}`, getKycStatusP2P, {
        enabled: !!clientId
    });
    return { data, isLoading };
}

export function useNudgesP2P() {
    const { data, isLoading } = useQuery(`${['p2pNudges']}`, getNudgesP2P);
    return { data, isLoading };
}