import React, { useEffect, useState } from 'react';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Link, useNavigate } from 'react-router-dom';
import { getClientDetails, useClient, useFamilyAccount } from './useFamilyAccount';
import { getRupee } from '../../utils/numbers';
import Avatar from '@mui/material/Avatar';
import Skeleton from '@mui/material/Skeleton';
import TableSkeleton from '../../components/TableSkeleton';
import { useDelay } from '../../hooks/useDelay';
import { debounce } from '../../utils/debounce';
import { SearchOutlined } from '@mui/icons-material';
import Input from '../../components/Fields/Input';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import ClientTable from '../Clients/ClientsTable';
import ClientDrawer from '../Clients/ClientDrawer/ClientDrawer';
import { useLayoutStore } from '../../components/organisms/Layout/store';

interface FamilyDetailsProps {}

const UserDetailsCarousel = ({ userDetails, isReady }) => {
  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        className="absolute top-1/2 right-5 transform bg-green-200/20 text-green-800 p-1 rounded-full cursor-pointer z-10 "
        onClick={onClick}
      >
        <ArrowForwardIcon
          style={{
            width: '20px',
            height: '20px',
            marginBottom: '2px',
          }}
        />
      </div>
    );
  };

  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        className="absolute top-1/2 left-2 transform bg-green-200/20 text-green-800 p-1 rounded-full cursor-pointer z-10"
        onClick={onClick}
      >
        <ArrowForwardIcon
          style={{
            width: '20px',
            height: '20px',
            transform: 'rotate(180deg)',
          }}
        />
      </div>
    );
  };

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: userDetails.length > 1,
    nextArrow: userDetails.length > 1 ? <CustomNextArrow /> : null,
    prevArrow: userDetails.length > 1 ? <CustomPrevArrow /> : null,
  };

  return (
    <Slider {...settings}>
      {userDetails?.map((user, index) => (
        <div
          key={index}
          className={`bg-black/[0.04] min-h-20 !flex items-center justify-between gap-6 rounded-lg pl-6  py-4 mt-6 ${
            userDetails.length > 1 && '!mx-auto !w-[90%]'
          }`}
        >
          {/* Name/PAN */}
          <div className="flex items-center gap-3 flex-1">
            {user.name && (
              <Avatar className="uppercase md:!w-14 md:!h-14 !bg-white/80 !text-slate-600 border border-slate-300">
                {user.name?.charAt(0)}
              </Avatar>
            )}
            <div>
              <h3 className="text-slate-800 mb-0.5 font-medium">
                {isReady ? user.name : <Skeleton width={50} />}
              </h3>
              <h4 className="text-stone-500 text-sm font-medium tracking-widest">{user.pan}</h4>
            </div>
          </div>
          <div className="flex items-center gap-6 mr-10">
            {/* Email ID */}
            <div className="text-right">
              <h3 className="text-stone-500 font-semibold text-xs uppercase mb-1">
                Primary Email ID
              </h3>
              <h4 className="text-sm font-medium text-slate-800 break-all">
                {isReady ? user.email || '-' : <Skeleton width={70} className="ml-auto" />}
              </h4>
            </div>

            {/* Phone */}
            <div className="text-right">
              <h3 className="text-stone-500 font-semibold text-xs uppercase mb-1">
                Primary Phone No.
              </h3>
              <h4 className="text-sm font-medium text-slate-800">
                {isReady ? user.phone || '-' : <Skeleton width={70} className="ml-auto" />}
              </h4>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

const FamilyDetails: React.FC<FamilyDetailsProps> = () => {
  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(0);
  const [orderBy, setOrderBy] = useState('createdAt');
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [search, setSearch] = useState('');

  const navigate = useNavigate();

  const pathSegments = window.location.pathname.split('/');
  const partnerId = pathSegments.pop();

  const { data, isLoading: dataLoading } = useFamilyAccount({
    familyAccountPublicId: partnerId,
  });

  const { data: clientsData, isLoading: clientsDataLoading } = useClient({
    id: partnerId,
    pageNo,
    pageSize,
    orderBy,
    order,
    search,
  });

  const isReady = useDelay(dataLoading, 200);

  const { investedAmount, numberOfClients, familyName, publicId, primaryMemberPublicIds } =
    data?.data[0] || {};

  const [userDetailsList, setUserDetailsList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (primaryMemberPublicIds) {
      const fetchUserDetails = async () => {
        const promises = primaryMemberPublicIds.map((memberId) =>
          getClientDetails(memberId).then((response) => response?.data),
        );
        const results = await Promise.all(promises);
        setUserDetailsList(results);
        setLoading(false);
      };

      fetchUserDetails();
    }
  }, [primaryMemberPublicIds]);

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNo(0);
  };

  const handleChangeSort = (e, columnId) => {
    if (columnId == orderBy) {
      setOrder(order == 'desc' ? 'asc' : 'desc');
    }
    setOrderBy(columnId);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const debounceHandleSearch = debounce(handleSearch, 500);

  const { setIsClientDrawerOpen, setClientDetails } = useLayoutStore(
    ({ setClientDetails, setIsClientDrawerOpen }) => ({
      setClientDetails,
      setIsClientDrawerOpen,
    }),
  );

  const handleRowClick = (row: any) => {
    if (row.url && row.isKycVerified) {
      navigate(row.url);
    } else {
      setClientDetails({
        name: row.originalName,
        email: row.originalEmail,
        pan: row.pan,
      });
      setIsClientDrawerOpen(row.clientPublicId);
    }
  };

  return (
    <div className="my-6 max-w-7xl mx-auto">
      {/* Heading */}
      <div className="flex items-start justify-between">
        <div className="flex items-center gap-4">
          <Link
            to="/family-account"
            className="bg-green-700/10 flex items-center justify-center p-1.5 h-9 w-9 rounded-full text-green-700"
          >
            <ArrowBack fontSize="small" />
          </Link>
          <h1 className="text-lg md:text-xl font-semibold">Family Account Overview</h1>
        </div>
      </div>

      {/* User Details */}

      <UserDetailsCarousel userDetails={userDetailsList} isReady={isReady} />

      {/* Investment Summary */}
      <div className="mt-6 mb-8 md:mb-12 grid grid-cols-2 xl:grid-cols-4 border border-stone-300 rounded-lg">
        <div className="p-4 md:p-6 border-r border-r-stone-300 max-xl:border-b max-xl:border-b-stone-300">
          <h3 className="text-stone-700/40 font-semibold text-xs md:uppercase mb-1">Family Name</h3>
          <p className="text-lg md:text-2xl font-medium text-stone-700 tracking-wider">
            {isReady ? familyName : <Skeleton width={60} />}
          </p>
        </div>
        <div className="p-4 md:p-6 border-r border-r-stone-300 max-xl:border-b max-xl:border-b-stone-300">
          <h3 className="text-stone-700/40 font-semibold text-xs md:uppercase mb-1">Public ID</h3>
          <p className="text-lg md:text-2xl font-medium text-stone-700">
            {isReady ? publicId : <Skeleton width={60} />}
          </p>
        </div>
        <div className="p-4 md:p-6 border-r border-r-stone-300 max-xl:border-b max-xl:border-b-stone-300">
          <h3 className="text-stone-700/40 font-semibold text-xs md:uppercase mb-1">
            Invested Amount
          </h3>
          <p className="text-lg md:text-2xl font-medium text-stone-700 tracking-wider">
            {isReady ? getRupee.format(investedAmount || 0) : <Skeleton width={60} />}
          </p>
        </div>
        <div className="p-4 md:p-6 xl:border-r xl:border-r-stone-300 max-xl:border-b max-xl:border-b-stone-300">
          <h3 className="text-stone-700/40 font-semibold text-xs md:uppercase mb-1">
            Number of Clients
          </h3>
          <p className="text-lg md:text-2xl font-medium text-stone-700">
            {isReady ? numberOfClients : <Skeleton width={60} />}
          </p>
        </div>
      </div>

      <div className="flex flex-col justify-start gap-3 items-start md:flex-row md:justify-between md:items-center mt-6 mb-4">
        <Input
          prefixIcon={<SearchOutlined />}
          placeholder="Search"
          onChange={debounceHandleSearch}
          className="ps-11 ml-1"
        />
        {/* <div className="flex gap-2 items-center">
             <button
                onClick={() => {
                      downloadCsv();
                      track({
                          eventName:
                              CLIENT_DETAIL_EVENTS.Client_Overview_Download_Excel,
                      });
                  }}
                  disabled={csvLoading}
                  type="button"
                  className="bg-white shadow border border-slate-300 flex items-center gap-2 text-sm  text-gray-800 rounded-lg font-semibold h-10 w-fit px-4"
              >
                  <DownloadForOfflineOutlinedIcon fontSize="small" />
                  Download Excel
              </button>
          </div> */}
      </div>
      {/* Client Investments Table */}
      {!clientsDataLoading ? (
        <ClientTable
          data={clientsData?.data}
          pageSize={pageSize}
          pageNo={pageNo}
          orderBy={orderBy}
          order={order}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleRowClick={handleRowClick}
          handleChangeSort={handleChangeSort}
          type="family"
        />
      ) : (
        <TableSkeleton />
      )}
      <ClientDrawer />
    </div>
  );
};

FamilyDetails.displayName = 'PartnersDetail';

export default FamilyDetails;
