import React from "react";
import Footer from "../../components/Footer";

interface RiskDisclosureProps { };

const RiskDisclosure: React.FC<RiskDisclosureProps> = () => {
    return (
        <>
            <div className="py-24 max-w-screen-2xl mx-auto px-4 md:px-12">
                <h1 className="font-medium text-4xl lg:text-6xl mb-32 text-center">Risk Disclosure</h1>

                <div className="text-xl md:text-2xl">
                    <p className="mb-3">
                        PLEASE READ THIS RISK DISCLOSURE STATEMENT CAREFULLY BEFORE TRANSACTING ON THE TAPINVEST™ WEBSITE OR TAPINVEST™ APPLICATION. THE WEBSITE IS OWNED AND OPERATED BY PURPLE PETAL INVEST PRIVATE LIMITED or “PPIPL”. BY ACCESSING, DOWNLOADING AND USING OUR WEBSITE OR APPLICATION, THE USER AGREES TO HAVE FULLY READ AND UNDERSTOOD THE RISKS ASSOCIATED WITH THE TRANSACTIONS MADE ON OR THROUGH THE WEBSITE AND/OR PLATFORM. THE USER FURTHER AGREES TO BEAR THE RISKS ARISING OUT OF THE TRANSACTIONS ENTERED INTO ON THE PLATFORM.
                    </p>

                    <p className="my-8">
                        Investments are subject to market risks and inherently carry risks. The User is hereby advised to take note of the following general risks:
                    </p>

                    <ol className="list-decimal ml-6 flex flex-col gap-6 md:gap-12">
                        <li>
                            Sale, purchase, and leasing/licensing of assets on the Platform may involve, inter alia, the following risks:
                            <ul className="list-disc flex flex-col gap-2">
                                <li>
                                    PPIPL is an intermediary technology platform facilitating the transactions in financial products on its Platform (as defined in the Terms) and therefore conducts basis level of due diligence and disclaims all or any liabilities from any of the warranties undertaken by the parties to the transaction. The parties to the transaction are advised and encouraged to conduct due diligence on the other and upon due satisfaction, they may transact on the Platform. Investments are subject to market risks. The information comprised herein is merely for information purposes only and are subject to verification by the investors. The investors are advised to refer to, and read carefully the, offer documents and obtain expert professional advice regarding specific legal, tax and financial implications of the investment/participation.
                                </li>
                                <li>
                                    The Parties are subject to heavy credit risk since their operations are dependant on capital, any disruption from their source of capital i.e., from clients’, suppliers, and investors like not paying their bills on time could adversely affect their liquidity and financial conditions. As a result, the parties can find themselves unable to pay their purchase price, their lease payments, or even to return leased goods.
                                </li>
                                <li>
                                    The parties may declare for bankruptcy or insolvency proceedings maybe initiated against them due to which they will be unable to meet their financial or any other obligations. This could adversely affect their business operations’.
                                </li>
                                <li>
                                    Force Majeure events could affect the business operations, Force Majeure Events include Act of God.
                                </li>
                            </ul>
                        </li>

                        <li>
                            The Platform may contain certain inaccuracies, errors, or unauthorized information. PPIPL makes every effort to achieve the highest level of integrity, correctness, and authenticity of the website, but it gives no assurances that it is complete, accurate, or correct.
                        </li>

                        <li>
                            The information available on the Platform including any information linked to the site is available on an “as is” basis and without warranties of any kind including, but not limited to, warranties of title or non-infringement or warranties of merchantability and fitness for a particular purpose, other than those warranties which are implied by and incapable of exclusion. Products and services offered on the Platform may change in price and availability at any time and without previous notice. Changes in the rate of exchange may have an adverse effect on the value, price, and income of investments. Past performance is not necessarily an indicator for future performance. PPIPL will not be held liable for unavailability of a product or service available on the Platform.
                        </li>

                        <li>
                            The parties are subject to laws and regulations governing their business operations.  Any changes in laws and regulations governing us could adversely affect our/ their business, financial condition, operations, or cash flows. PPIPL is not a stock exchange, or a registered intermediary recognized by SEBI. Additionally, PPIPL is not a fundraising platform. PPIPL does not provide guaranteed returns on investment, even if invested in high-grade/rated or low credit risk opportunities.
                        </li>

                        <li>
                            PPIPL does not offer any kind of business or investment or legal or tax-related advice to any of its Users. PPIPL doesn't personally offer investment advice. The platform is merely an intermediary platform enabling the investors make their own investments The platform is only facilitating the investors' independent investing decisions. The services available on our platform are for sophisticated customers only. The User is advised to be aware of risks associated with such investments before investing and to thoroughly read any offer related document carefully, Investor might be unable recover even part or whole of Principle amount. Furthermore, the dangers listed in this publication are not all-inclusive. Additional risks related to any product or opportunity on the platform could exist. The User is advised to exercise his/her own discretion while making any purchases on the platform and seek independent advice if required.
                        </li>

                        <li>
                            PPIPL is not responsible for any direct, indirect, incidental, consequential, special, exemplary, punitive or any other damages (including without limitation loss of profits, loss or corruption of data, loss of goodwill, work stoppage, computer failure or malfunction, or interruption of business) under any contract, negligence, strict liability or any other theory arising out of or relating in any way with the use of the site or in reliance of the information available on the site, site-related services, or any products or services offered or sold or displayed on the site or any of the products or services sold or displayed on the site.
                        </li>

                        <li>
                            PPIPL offers no representations, warranties, or guaranties of any kind whatsoever and disclaims all liability in this regard, along with any entity(ies) or person(s) connected to it. Nothing in these terms shall be construed as creating a partnership between PPIPL and any User using the services in accordance with these terms, nor shall it constitute the User acting as the agent of the User with the intent to bind PPIPL in any way, unless expressly provided otherwise. The User understands and agrees that no joint venture, partnership, employment or agency relationship exists between the parties and PPIPL on account of use of our platform.
                        </li>

                        <li>
                            For further information please see our Privacy Policy. PPIPL or any of its representatives will not collect personal information over phone calls. Please be wary of such queries and kindly report such queries in support@tapinvest.in
                        </li>

                        <li>
                            It is hereby clarified that neither PPIPL nor TAPINVEST™ is classified as a stock exchange nor a non-banking financial institution under applicable law.
                        </li>
                    </ol>

                    <p className="mt-6">
                        Any dispute arising out of the transactions on the Platform shall be governed by the laws of India, and the courts in Bangalore, Karnataka shall have the sole jurisdiction to adjudicate the disputes arising out of such transactions.
                    </p>
                </div>
            </div>
            <Footer />
        </>
    );
};

RiskDisclosure.displayName = "RiskDisclosure";

export default RiskDisclosure;