import { useQuery, PATH, Call } from "../../services/NetworkManager";

export function getUserPaymentDetails({ key }) {
  return Call({
    method: "get",
    path: PATH.createUserPaymentLink.replace("{{key}}", key),
  });
}

export function getUserPaymentWalletDetails({ key }) {
  return Call({
    method: "get",
    path: PATH.createUserPaymentLinkWallet.replace("{{key}}", key),
  });
}

export function useUserPaymentDetails(key?: string) {
  const { data, isLoading, error } = useQuery(["userPaymentDetails", key], () =>
    getUserPaymentDetails({ key })
  );
  return { data, isLoading, error };
}

export function useUserPaymentWalletDetails(key?: string) {
  const { data, isLoading, error } = useQuery(
    ["userPaymentWalletDetails", key],
    () => getUserPaymentWalletDetails({ key })
  );
  return { data, isLoading, error };
}

export function getCreateP2PPayment({
  key,
  phone,
}: {
  key: string;
  phone: string;
}) {
  return Call({
    method: "post",
    path: PATH.CreateP2PUserPayments,
    data: {
      key,
      phone,
    },
  });
}

export function useCreateP2PPayment(key?: string, phone?: string) {
  const { data, isLoading, error } = useQuery(["createPayment", key], () =>
    getCreateP2PPayment({ key, phone })
  );
  return { data, isLoading, error };
}

export function getCreateWalletPayment({
  key,
  phone,
}: {
  key: string;
  phone: string;
}) {
  return Call({
    method: "post",
    path: PATH.CreateWalletUserPayments,
    data: {
      key,
      phone,
    },
  });
}

export function useCreateWalletPayment(key?: string, phone?: string) {
  const { data, isLoading, error } = useQuery(["createPayment", key], () =>
    getCreateWalletPayment({ key, phone })
  );
  return { data, isLoading, error };
}
