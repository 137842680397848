import Bonds from "../../assets/bonds.png";
import AssetLeasing from "../../assets/asset-leasing.png";
import InvoiceDiscounting from "../../assets/invoice-discounting.png";
import OnboardingExcellence from "../../assets/seamless-onboarding.png";
import CompetitiveCommission from "../../assets/competitive-commission.png";
import RMSupport from "../../assets/rm-support.png";
import ExclusiveOpportunities from "../../assets/exclusive-opportunities.png";



export const FEATURES = [
    {
        id: 1,
        title: "Invoice Discounting",
        description: "Buy invoices payable to startups and companies and earn high returns in the short term.",
        image: InvoiceDiscounting
    },
    {
        id: 2,
        title: "Bonds",
        description: "Lend money to companies for regular interest payments by purchasing discounted bond issues.",
        image: Bonds
    },
    {
        id: 3,
        title: "Asset Leasing",
        description: "Effortlessly buy and rent assets to businesses and earn monthly rental income.",
        image: AssetLeasing
    },
];

export const BENEFITS = [
    {
        id: 1,
        title: "Onboarding Excellence",
        description: "Our streamlined process ensures that you can get started quickly and efficiently, allowing you to focus on what matters most—growing your investments.",
        icon: OnboardingExcellence
    },
    {
        id: 2,
        title: "Competitive Commission Returns",
        description: "Benefit from our transparent and competitive commission structure, thoughtfully designed to ensure that your hard work is recognised and rewarded fairly.",
        icon: CompetitiveCommission
    },
    {
        id: 3,
        title: "Dedicated Relationship Manager",
        description: "Take advantage of our comprehensive financial guidance and support from a dedicated Relationship Manager.",
        icon: RMSupport
    },
    {
        id: 4,
        title: "Exclusive Opportunities",
        description: "Empower your clients to invest in non-market linked assets, allowing them to diversify their portfolios and secure fixed returns.",
        icon: ExclusiveOpportunities
    },
];

export const HOME_NAVIGATION_EVENTS = {
    "Tap_Academy": "Tap_Academy",
    "Products_Deck": "Products_Deck",
    "Profile": "Profile",
}