import { fetchAndActivateRemoteConfig } from '../firebaseConfig';
import React, { createContext, useEffect, useState } from 'react';

export const RemoteConfigContext = createContext({
  remoteConfig: { partnerZoomWebinarDetails: {}, hideP2P: true },
  configSetter: () => {},
});

const configKeysToFetch = ['partnerZoomWebinarDetails', 'hideP2P'];

function RemoteConfigContextProvider({ children }) {
  const [config, setConfig] = useState({ partnerZoomWebinarDetails: {}, hideP2P: true });

  const remoteConfigSetter = (detailsToSet) => {
    setConfig((prev) => {
      return { ...prev, ...detailsToSet };
    });
  };

  useEffect(() => {
    configKeysToFetch.forEach((key) => {
      fetchAndActivateRemoteConfig(key).then((config) => {
        if (config) {
          remoteConfigSetter({ [key]: JSON.parse(config) });
        } else {
          console.log('Failed to fetch remote config');
        }
      });
    });
  }, []);

  return (
    <RemoteConfigContext.Provider
      value={{ remoteConfig: config, configSetter: remoteConfigSetter }}
    >
      {children}
    </RemoteConfigContext.Provider>
  );
}

export default RemoteConfigContextProvider;
