export const API_URL =
    process.env.REACT_APP_API_BASE_URL ??
    "https://partner-sandbox.tapinvest.in";

export const API_URL_KRAKEN =
    process.env.REACT_APP_KRAKEN_URL ?? "https://kraken-stage.tapinvest.in";

// Put your endpoint here
export const PATH = {
  // get clients
  clients: '/client/v2/get-all-client',

  // partners
  partners: '/profile/v1/get-all-partner',
  partnersSummaryData: 'partner2partner/v1/get-master-partner-aggregate',
  // family accounts
  familyAccounts: 'family-account/v1/get-family-accounts',
  singleFamilyAccount:
    'family-account/v1/get-family-accounts?familyAccountPublicId={{familyAccountPublicId}}',
  addFamilyAccount: 'family-account/v1/upsert-family-accounts',
  // @deprecate
  clientSummaryData: '/client/v2/get-aggregated-client-data',
  clientsStatisticsData: '/client/v2/get-aggregated-client-data',
  client: '/client/v2/client/{{clientId}}',
  clientStatisticsData:
    '/client/v2/get-aggregated-total-client-metric?client-public-id={{clientId}}',
  clientBank: '/client/v1/get-bank-details/{{clientId}}',
  clientKycStatus: '/client/v1/kyc-status?clientId={{clientId}}',
  clientInvestments: '/dashboard/investment/v2/get-all-investments',

  // login endpoints
  phoneOTP: '/onboarding/phone/request-otp',
  verifyPhoneOTP: '/onboarding/phone/verify-otp',
  emailOTP: '/onboarding/email/request-otp',
  verifyEmailOTP: '/onboarding/email/verify-otp',

  // kyc endpoints
  partnerPanInfo: '/onboarding/kyc/request-pan-info/{{pan}}/PARTNER',
  verifyPartnerPanInfo: '/onboarding/kyc/confirm-pan-info/{{pan}}',
  aadhaarInfo: '/onboarding/kyc/request-aadhaar-otp/{{aadhaar}}',
  verifyAadhaarInfo: '/onboarding/kyc/verify-aadhaar-otp',
  verifyBankInfo: '/onboarding/kyc/verify-bank',

  // create client
  createClient: '/client/v1/create',
  clientPanInfo: '/onboarding/kyc/request-pan-info/{{pan}}/CLIENT',
  verifyClientPanInfo: '/client/v1/pan/add',
  clientAadhaarInfo: '/client/v1/aadhaar/verify',
  clientVerifyAadhaarInfo: '/client/v1/aadhaar/add',
  clientVerifyBankInfo: '/client/v1/add-bank-account',
  clientVirtualAccount: '/client/v1/virtual-account?clientId={{clientId}}',

  // get investments
  investments: '/dashboard/investment/v2/partner',
  partnerInvestments: '/dashboard/investment/v2/get-all-investments',
  subPartnerInvestments: 'partner2partner/v1/get-all-sub-partner-investments',
  investmentsSummary: '/dashboard/investment/v2/latest-partner-aggregates',

  // download
  csvClientInvestment: '/csv/client-investment/{{clientId}}',
  csvPartnerInvestment: '/csv/partner-investment',
  csvPartnerClient: '/csv/partner-client',

  deals: '/deals/v1/get-all',
  deal: '/deals/v1/id/{{dealId}}',
  dealInfo: '/deals/v1/deal-info/id/{{dealId}}',
  createInvestment: '/investment/v1/invoice-discounting/create-investment/{{dealId}}/without-otp',
  investmentTerms: '/investment/v1/invoice-discounting/{{dealId}}/investment-terms',
  // fetch partner profile
  getPartnerWithReferral: 'profile/v1/get-partner-with-referral',
  // partner contracts
  getPartnerOnboardingContract: 'contract/get-contract/PARTNER_ONBOARDING',
  partnerSignContract: 'contract/sign-contract',
  getPartnerDealContract: 'contract/document-contract/{{dealId}}/{{financeType}}',
  // tap reserve / p2p
  p2pInvestments: 'p2p/v1/investment-terms',
  p2pKycStatus: 'p2p/v1/kyc-status?clientId={{clientId}}',
  p2pNudges: 'p2p/v1/nudges',
  p2pAadhaar: 'p2p/v1/aadhaar/request-otp',
  p2pOtp: 'p2p/v1/aadhaar/verify-otp',

  // user payments
  generateUserP2PPaymentLink: 'p2p/v1/generate-link',
  generateUserWalletPaymentLink: 'wallet/v1/generate-link',
  createUserPaymentLink: 'v1/payment-link/p2p?key={{key}}',
  createUserPaymentLinkWallet: 'v1/payment-link/wallet?key={{key}}',
  CreateP2PUserPayments: 'v1/payment-link/p2p/invest',
  CreateWalletUserPayments: 'v1/payment-link/wallet/add-money',
  getPartnerDealContract: 'contract/document-contract/{{dealId}}/{{financeType}}',
  // reinvestment
  reinvest:
    'dashboard/investment/v2/reinvestment/optout/{{investmentId}}?client-public-id={{clientId}}',
  // tax report
  taxReportStatus: 'client/v2/taxreport-status?client-public-id={{clientId}}&fy={{year}}',
  taxReportDownload: 'client/v2/taxreport-download?client-public-id={{clientId}}&fy={{year}}',

  // withdraw
  withdrawMoney: '/wallet/v1/wallet-withdrawal-request',

  // bonds
  bondsDetail: 'bonds/v1/deals/{{dealId}}',
  bondsInvestmentTerms: 'bonds/v1/deals/{{dealId}}/investment-term',
  bondsCashflow: 'bonds/v1/deals/{{dealId}}/cash-flow',
  cmrUpload: 'bonds/v1/cmr-upload',
  cmrStatus: 'bonds/v1/cmr/status?client-public-id={{clientId}}',
  bondsPaymentLink: 'bonds/v1/generate-link',
  createUserPaymentLinkBonds: 'v1/payment-link/bond?key={{key}}',
  CreateBondsUserPayments: 'v1/payment-link/bond/invest',
  partnerConsiderationAmount: 'bonds/v1/partner-consideration-amount',
};
