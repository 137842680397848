import clsx from "clsx";
import React, { Children, ReactElement } from "react";

interface FieldProps {
  label?: string;
  htmlFor?: string;
  error?: string;
  children: ReactElement;
  className?: string;
}

export const Field = ({ label, htmlFor, error, children, className }: FieldProps) => {
  const id = htmlFor || getChildId(children);

  return (
    <div className={clsx("flex flex-col", className)}>
      {label && <label htmlFor={id} className={clsx("font-medium mb-1.5 text-xs text-gray-700")}>{label}</label>}
      {children}
      {error && (
        <span role="alert" className="text-red-500 text-xs mt-1">
          {error}
        </span>
      )}
    </div>
  );
};

function getChildId(children: ReactElement) {
  const child = Children.only(children);

  if ("id" in child?.props) {
    return child.props.id;
  }
}
