import { FileCopyRounded } from "@mui/icons-material";
import React from "react";

interface CardComponentProps {
  title: string;
}

const ErrorCardComponent: React.FC<CardComponentProps> = ({ title }) => {
  return (
    <div className="max-w-md mx-auto mt-[15%] bg-white shadow-lg rounded-xl overflow-hidden">
      <div className="p-6">
        <div className="flex items-center justify-center mb-6">
          <div className="w-10 h-10 bg-red-100 rounded-full flex items-center justify-center">
            <svg
              className="w-6 h-6 text-red-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </div>
        </div>
        <div className="text-center">
          <h2 className="text-3xl font-semibold mb-1">{title}</h2>
          <p className="text-gray-500">An error occurred. Please try again!</p>
        </div>
        <div className="text-center mt-10">
          <p className="text-gray-600">
            Need Help?{" "}
            <a href="mailto:support@tapinvest.in" className="text-gray-600 underline underline-offset-4">
              Contact Us
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ErrorCardComponent;
