import React, { useEffect } from 'react';
import Divider from '@mui/material/Divider';
import { Outlet, useNavigate } from 'react-router-dom'
import LeftNav from './LeftNav';
import Header from './Header';
import useGetPartnerProfile from '../../../hooks/useGetPartnerProfile';
import { useOnboardingStore } from '../../../pages/Onboarding/store';
import { ONBOARDING_PAGE_TYPE } from '../../../pages/Onboarding/constants';

const Layout = ({ children }) => {
	const navigate = useNavigate();
  const { data, isLoading } = useGetPartnerProfile();
  const { setNextPage } = useOnboardingStore(({ setNextPage }) => ({ setNextPage }))
  const { kycStatus } = data?.data || {};

  useEffect(() => {
    if (!isLoading && kycStatus) {
      // Navigate to login if partner onboarding incomplete 
      if (!kycStatus?.onboardingCompleted) {
        setNextPage(ONBOARDING_PAGE_TYPE.PHONE_VERIFICATION);
        localStorage.removeItem("token");
        navigate('/login');
      }
    }
  }, [isLoading, kycStatus])

  return (
    <div className='flex flex-col'>
      <Divider sx={{ width: "100%" }} />
      <LeftNav />
      <Header />
      <div className="@container pl-4 pr-8 xl:ml-60">
        {children ? children : <Outlet />}
      </div>
    </div>
  )
}

export default Layout