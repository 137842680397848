import { useQuery } from '../../services/NetworkManager';
import { NetworkManager } from '../../services';

interface GetAllFamilyAccountParams {
  familyAccountPublicId?: string;
  searchPhrase?: string;
}

export function getAllFamilyAccount({
  familyAccountPublicId,
  searchPhrase,
}: GetAllFamilyAccountParams) {
  // Construct the params object dynamically
  const params: Record<string, string> = {};

  if (familyAccountPublicId) params.familyAccountPublicId = familyAccountPublicId;
  if (searchPhrase ) params.searchPhrase = searchPhrase;
  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.familyAccounts,
    params: {
      familyAccountPublicId,
      searchPhrase,
    },
  });
}

export function AddFamilyAccount(data) {
  return NetworkManager.Call({
    method: 'post',
    path: NetworkManager.PATH.addFamilyAccount,
    data: data,
  });
}

export function useFamilyAccount({
  familyAccountPublicId,
  searchPhrase,
}: { familyAccountPublicId?: string; searchPhrase?: string } = {}) {
  const { data, isLoading, refetch } = useQuery(['allFamilyAccounts', searchPhrase], () =>
    getAllFamilyAccount({ familyAccountPublicId, searchPhrase }),
  );
  return { data, isLoading, refetch };
}
