import * as React from 'react';
import Tables from '../../components/organisms/Tables/Tables';
import { getRowsAndCols } from './utils';
import { SORTABLE_COLUMNS } from './constants';

const columns = [
    { id: 'name', label: 'Client Name', minWidth: 170, align: 'left' },
    {
        id: 'email',
        label: 'Email Address',
        minWidth: 170,
        align: 'left',
    },
    {
        id: 'phone',
        label: 'Phone Number',
        minWidth: 170,
        align: 'left',
    },
    {
        id: 'amount',
        label: 'Invested Amount',
        minWidth: 100,
        align: 'left',
    },
    {
        id: 'balance',
        label: 'Wallet Balance',
        minWidth: 170,
        align: 'left',
    },
];

interface ClientTableProps {
    data: {
        currentPage: number;
        items: any[];
        pageSize: number;
        totalItems: 0;
    }
    pageSize: number;
    pageNo: number;
    orderBy: string,
	order: "asc" | "desc",
    handleChangePage: (event: any, newPage: number) => void;
    handleChangeRowsPerPage: (event: any) => void;
    handleRowClick: (row: any) => void;
    handleChangeSort: (event: any, columnId: string) => void;
    type: string;
};


const ClientTable: React.FC<ClientTableProps> = ({
    data,
    pageSize,
    pageNo,
    orderBy,
	order,
    handleChangePage,
    handleChangeRowsPerPage,
    handleRowClick,
    handleChangeSort,
    type,
}) => {
    const row = getRowsAndCols(data, type);
    const totalData = data?.totalItems;
    return (
        <Tables
            totalData={totalData}
            columns={columns}
            sortableColumns={SORTABLE_COLUMNS}
            rows={row}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={
                handleChangeRowsPerPage
            }
            handleRowClick={handleRowClick}
            handleChangeSort={handleChangeSort}
            rowsPerPage={pageSize}
            page={pageNo}
            orderBy={orderBy}
            order={order}
        />
    );
}

ClientTable.displayName = "ClientTable";

export default ClientTable;
