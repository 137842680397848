import { Call, PATH, useQuery } from '../../services/NetworkManager';
import { NetworkManager } from '../../services';

export function getAllFamilyAccount({ familyAccountPublicId }) {

  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.singleFamilyAccount.replace(
      '{{familyAccountPublicId}}',
      familyAccountPublicId,
    ),
  });
}

export function getAllClients({ queryKey }) {
  const [_, id, pageNo, pageSize, orderBy, order, search] = queryKey[0].split(',');

  return NetworkManager.Call({
    method: 'post',
    path: NetworkManager.PATH.clients,
    data: {
      currentPage: pageNo,
      pageSize,
      sortBy: orderBy,
      sortDir: order.toUpperCase(),
      searchPhrase: search,
      searchOn: ['name', 'email', 'pan', 'phone'],
      filters: [
        {
          field: 'familyAccountPublicId',
          value: id,
          operation: 'EQUALS',
        },
      ],
    },
  });
}

export function getClientDetails(id) {
  return Call({
    method: 'get',
    path: PATH.client.replace('{{clientId}}', id),
  });
}

export function useFamilyAccount(familyAccountPublicId) {
  const { data, isLoading } = useQuery({
    queryKey: ['allFamilyAccounts', familyAccountPublicId],
    queryFn: () => getAllFamilyAccount(familyAccountPublicId),
  });
  return { data, isLoading };
}

export function useClient({ id, pageNo, pageSize, orderBy, order, search }) {
  const { data, isLoading } = useQuery(
    `${['clients', id, pageNo, pageSize, orderBy, order, search]}`,
    getAllClients,
  );
  return { data, isLoading };
}
