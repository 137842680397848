import { NetworkManager } from "../../services";
import { useQuery } from "../../services/NetworkManager";

export function getSubPartner({ queryKey }) {
    const [id, pageNo, pageSize, orderBy, order, search] =
        queryKey[0].split(",");

    return NetworkManager.Call({
        method: "post",
        path: NetworkManager.PATH.partners,
        data: {
            currentPage: pageNo,
            pageSize,
            sortBy: orderBy,
            sortDir: order.toUpperCase(),
            searchPhrase: search,
            searchOn: [
                "firstName",
                "lastName",
                "email",
                "phone",
                "pan",
                "panName",
            ],
            filters: [
                {
                    field: "id",
                    operation: "EQUALS",
                    value: id,
                },
            ],
        },
    });
}

export function useSubPartners({ payload }) {
    const { id, pageNo, pageSize, orderBy, order, search } = payload;
    const { data, isLoading } = useQuery(
        `${[id, pageNo, pageSize, orderBy, order, search]}`,
        getSubPartner,
        { enabled: !!id }
    );
    return { data, isLoading };
}

export function getSubPartnerInvestments({ queryKey }) {
    const [_, id, pageNo, pageSize, orderBy, order, search] =
        queryKey[0].split(",");
    return NetworkManager.Call({
        method: "post",
        path: NetworkManager.PATH.subPartnerInvestments,
        data: {
            currentPage: pageNo,
            pageSize,
            sortBy: "id",
            sortDir: "DESC",
            searchPhrase: search,
            searchOn: ["dealName"],
            filters: [
                {
                    field: "partnerId",
                    operation: "EQUALS",
                    value: id,
                },
            ],
        },
    });
}

export function useSubPartnerInvestments({
    id,
    pageNo,
    pageSize,
    orderBy,
    order,
    search,
}) {
    const { data, isLoading } = useQuery(
        `${[
            "subPartnerInvestments",
            id,
            pageNo,
            pageSize,
            orderBy,
            order,
            search,
        ]}`,
        getSubPartnerInvestments
    );
    return { data, isLoading };
}
