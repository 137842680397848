import React from "react";

/**
 * Takes Epoch time in milliseconds, subtract the user's current time and displays it in HH:MM:SS format.
 * @param time - The total time in milliseconds.
 * @param sx - The CSS class name for styling the component.
 */
export const ClickingTimer: React.FC<{ time: number; sx: string }> = ({
  time,
  sx,
}) => {
  const [seconds, setSeconds] = React.useState(Math.floor(time));

  React.useEffect(() => {
    setSeconds(Math.floor(time / 1000));
  }, [time]);

  React.useEffect(() => {
    /**
     * Updates the timer every second and clears the interval when the timer reaches 0.
     */
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds <= 0) {
          clearInterval(interval);
          return 0;
        }
        return prevSeconds - 1;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  /**
   * Formats the given number of seconds into hours, minutes, and seconds format.
   * @param seconds - The number of seconds to format.
   * @returns The formatted time string in the format "H : M : S".
   */
  const formatTime = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${hours}H : ${minutes}M : ${remainingSeconds}S`;
  };

  return <div className={sx}>{formatTime(seconds)}</div>;
};
