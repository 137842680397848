import React from "react";
import useGetPartnerProfile from "../../hooks/useGetPartnerProfile";
import { twoDecimalNumber } from "../../utils/numbers";

interface ProfileProps { };

const Profile: React.FC<ProfileProps> = () => {
    const { data, isLoading } = useGetPartnerProfile();
    const { assetLeasingTerms, invoiceDiscountingTerms } = data?.data || {};

    return (
        <div className="my-6 max-w-6xl mx-auto">
            <h1 className="text-xl lg:text-2xl font-semibold text-slate-800">Commission Table</h1>

            <div className="mt-8 rounded-xl border border-stone-300">
                <div className="bg-black/[0.02] p-6 text-center text-base lg:text-xl font-bold text-slate-700 border-b border-b-stone-300">
                    Total inflow for the month IRR (in Cr)
                </div>
                <div className="grid grid-cols-3 bg-black/[0.02] divide-x divide-stone-300">
                    <div className="p-6">
                        <p className="font-semibold text-slate-700 text-base lg:text-xl text-center">Range</p>
                    </div>
                    <div className="p-6">
                        <p className="font-semibold text-slate-700 text-base lg:text-xl text-center">Invoice Discounting</p>
                    </div>
                    <div className="p-6">
                        <p className="font-semibold text-slate-700 text-base lg:text-xl text-center">Asset Leasing</p>
                    </div>
                </div>

                <div className="grid grid-cols-3 w-full border-t border-t-stone-300 divide-x divide-y divide-stone-200">
                    {!isLoading && assetLeasingTerms.map((term, index) => {
                        return (
                            <React.Fragment key={index}>
                                <div className="grid grid-cols-1 md:grid-cols-3 p-6 w-full justify-between items-center font-medium text-sm md:text-base lg:text-lg">
                                    <span>{index > 0 && ">"}{twoDecimalNumber(term.rangeLower / 10000000)}</span>
                                    <span className="max-md:text-start text-center">-</span>
                                    <span>{twoDecimalNumber(term.rangeUpper / 10000000)}</span>
                                </div>

                                <div className="p-6 font-medium text-sm md:text-base lg:text-lg text-center">
                                    {twoDecimalNumber(invoiceDiscountingTerms[index].commissionPercentage)}%
                                </div>

                                <div className="p-6 font-medium text-sm md:text-base lg:text-lg text-center">
                                    {twoDecimalNumber(term.commissionPercentage)}%
                                </div>
                            </React.Fragment>
                        )
                    })

                    }
                </div>
            </div>


            <div className="grid grid-cols-1 md:grid-cols-3 border border-stone-300 my-6 rounded-xl">
                <div className="bg-black/[0.02] p-6 text-center">
                    <p className="text-base lg:text-xl font-bold text-slate-700">Tap Reserve (P2P)</p>
                </div>
                <div className="col-span-2 p-6 text-center text-sm md:text-base lg:text-lg font-medium">
                    <p>1.00% across all investments</p>
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 border border-stone-300 rounded-xl">
                <div className="bg-black/[0.02] p-6 flex justify-center items-center">
                    <p className="text-base lg:text-xl font-bold text-slate-700">Bonds</p>
                </div>
                <div className="col-span-2 p-6 flex justify-between items-center text-sm md:text-base lg:text-lg text-center">
                    <p className="max-w-[462px] leading-7 mx-auto">
                        Wish to know more about the deals which would be a fit for your portfolio? Reach out directly to <b>Avnish Singh</b> (Bonds Manager) at <span className="text-green-700 font-semibold">avnish.singh@tapinvest.in</span> or <span className="text-green-700 font-semibold">+917278005041</span>
                    </p>
                </div>
            </div>
        </div>
    );
};

Profile.displayName = "Profile";

export default Profile;