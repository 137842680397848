import { useQuery } from "../../services/NetworkManager";
import { NetworkManager } from "../../services";

export function getAllInvestments({ queryKey }) {
    const [_, pageNo, pageSize, orderBy, order, search] =
        queryKey[0].split(",");

    return NetworkManager.Call({
        method: "post",
        path: NetworkManager.PATH.partnerInvestments,
        data: {
            currentPage: pageNo,
            pageSize,
            sortBy: orderBy,
            sortDir: order.toUpperCase(),
            searchPhrase: search,
            searchOn: ["dealName"],
        },
    });
}

export function getInvestmentsSummary() {
    return NetworkManager.Call({
        method: "get",
        path: NetworkManager.PATH.investmentsSummary,
    });
}

export function useInvestments({ payload }) {
    const { pageNo, pageSize, orderBy, order, search } = payload;
    const { data, isLoading } = useQuery(
        `${["investments", pageNo, pageSize, orderBy, order, search]}`,
        getAllInvestments
    );
    return { data, isLoading };
}

export function useInvestmentsSummary() {
    const { data, isLoading } = useQuery(
        "investmentsSummary",
        getInvestmentsSummary
    );

    return { data, isLoading };
}
