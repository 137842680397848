import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useDealClients } from './useDealDetails';
import { getRupee } from '../../utils/numbers';
import clsx from 'clsx';
import { useAnalytics } from '../../hooks/useAnalytics';
import { Button, Checkbox, ListItemText } from '@mui/material';
import { CLIENTS_EVENTS } from '../Clients/constants';
import { useLayoutStore } from '../../components/organisms/Layout/store';

interface SelectClientProps {
  client: any | any[]; // Can be a single client or an array
  setClient: (clients: any | any[]) => void;
  isTapReserve?: boolean;
  eventName?: string;
  showAddNewClient?: boolean;
  setLoading?: any;
  multiple?: boolean;
  value?: string;
  onChange?: any;
}

const ObservedMenuItem = ({ onIntersect }) => {
  const itemRef = useRef(null);

  useEffect(() => {
    const observerCallback = (entries) => {
      entries?.forEach((entry) => {
        if (entry.isIntersecting) {
          onIntersect();
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, { threshold: 1.0 });

    if (itemRef.current) {
      observer.observe(itemRef.current);
    }

    return () => {
      if (itemRef.current) {
        observer.unobserve(itemRef.current);
      }
    };
  }, [onIntersect]);

  return <MenuItem ref={itemRef} disabled className="invisible" />;
};

const SelectClient: React.FC<SelectClientProps> = ({
  client,
  setClient,
  eventName,
  showAddNewClient = true,
  setLoading,
  multiple = false,
  value,
  onChange,
}) => {
  const [pageNo, setPageNo] = useState(0);
  const [selectClients, setSelectClients] = useState([]);

  const { data: dealClients, isLoading: dealClientsLoading } = useDealClients(pageNo, value);

  const { items: clients, totalItems } = dealClients?.data || {};

  const { track } = useAnalytics();

  useEffect(() => {
    if (setLoading) {
      setLoading(!!dealClientsLoading);
    }
  }, [dealClientsLoading, setLoading]);

  const filteredClients = useMemo(() => {
    if (multiple) {
      return clients?.filter((client) => !client.familyName);
    } else {
      return clients?.filter((client) => client.isKycVerified);
    }
  }, [clients, multiple]);

  const hasMoreClients = useMemo(() => {
    // Basic conditions
    const basicCondition =
      totalItems > pageNo * 10 && totalItems > clients.length && filteredClients?.length > 9;

    // Additional condition based on 'multiple'
    const additionalCondition = multiple
      ? selectClients?.filter((client) => client.isKycVerified).length > 9
      : true; // If 'multiple' is false, this condition will always be true

    return basicCondition && additionalCondition;
  }, [totalItems, pageNo, clients, filteredClients, selectClients, multiple]);

  const handleChange = (e: SelectChangeEvent<string | string[]>) => {
    if (multiple) {
      const selectedClientIds = e.target.value as string[];
      const selectedClients = selectClients.filter((client) =>
        selectedClientIds.includes(client.clientPublicId),
      );
      setClient(selectedClients);
    } else {
      const selectedClientId = e.target.value as string;
      const selectedClient = selectClients.find(
        (client) => client.clientPublicId === selectedClientId,
      );
      setClient(selectedClient || '');
    }
    track({ eventName });
  };

  const clientElement = useCallback(({ name, pan, balance, family }) => {
    return (
      <div className="flex justify-between items-center w-full">
        <div className="flex">
          <div className="flex flex-col">
            <h4 className="text-sm font-medium text-slate-600">
              {name.length > 15 ? `${name.slice(0, 15)}...` : name}
            </h4>
            <h3 className="text-xs font-medium text-slate-600/60 mt-1">{pan}</h3>
          </div>
          {family && (
            <h3 className="text-xs font-medium text-green-800 h-fit mx-1 bg-green-800/15 rounded-xl px-1 py-[6px]">
              {family.length > 12 ? `${family.slice(0, 12)}...` : family}
            </h3>
          )}
        </div>

        <p className="text-sm font-medium text-slate-600">{getRupee.format(balance)}</p>
      </div>
    );
  }, []);

  useEffect(() => {
    if (pageNo === 0 && !dealClientsLoading) {
      setSelectClients(filteredClients);
    } else if (pageNo > 0 && !dealClientsLoading) {
      const newClients = [...selectClients, ...filteredClients];
      setSelectClients(newClients);
    }
  }, [pageNo, filteredClients, dealClientsLoading]);

  const handleClientsRequest = () => {
    setPageNo(pageNo + 1);
  };

  const { setIsClientDrawerOpen } = useLayoutStore(({ setIsClientDrawerOpen }) => ({
    setIsClientDrawerOpen,
  }));

  const handleAddNewClient = useCallback(() => {
    setIsClientDrawerOpen(null);
    track({ eventName: CLIENTS_EVENTS.Add_new_Client_From_ID });
  }, []);

  const isClientSelected = (clientId) => {
    if (multiple) {
      return (client as any[]).some((selectedClient) => selectedClient.clientPublicId === clientId);
    } else {
      return (client as any)?.clientPublicId === clientId;
    }
  };

  return (
    <>
      {dealClients && filteredClients.length > 0 ? (
        <Select
          multiple={multiple}
          displayEmpty
          value={
            multiple
              ? (client as any[]).map((c) => c.clientPublicId)
              : (client as any)?.clientPublicId || ''
          }
          IconComponent={KeyboardArrowDown}
          className={clsx('w-full bg-white !rounded-lg', {
            'h-10': !client,
          })}
          onChange={(e) => {
            handleChange(e);
            if (onChange) {
              onChange();
            }
          }}
          renderValue={(selected) => {
            if (!selected || (Array.isArray(selected) && selected.length === 0)) {
              return <p className="text-gray-400">Select Client</p>;
            }

            if (multiple) {
              return (
                <div className="flex flex-wrap gap-1">
                  {(selected as string[]).map((clientId) => {
                    const selectedClient = selectClients.find(
                      (client) => client.clientPublicId === clientId,
                    );
                    if (selectedClient) {
                      return (
                        <div
                          key={selectedClient.clientPublicId}
                          className="bg-gray-100 p-1 rounded text-sm"
                        >
                          {selectedClient.name}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              );
            } else {
              const selectedClient = selectClients.find(
                (client) => client.clientPublicId === selected,
              );
              return selectedClient ? (
                clientElement({
                  name: selectedClient.name,
                  pan: selectedClient.pan,
                  balance: selectedClient.balance,
                  family: selectedClient.familyName,
                })
              ) : (
                <p className="text-gray-400">Select Client</p>
              );
            }
          }}
          MenuProps={{
            MenuListProps: {
              className: 'max-h-80 overflow-auto',
            },
          }}
          input={<OutlinedInput />}
        >
          <MenuItem disabled value="" className="!px-0">
            <div className="flex px-4 justify-between w-full border-b border-b-gray-400 pb-2">
              <h4 className="text-stone-700 text-xs">Name of Client</h4>
              <p className="text-stone-700 text-xs">Wallet Balance</p>
            </div>
          </MenuItem>
          {!dealClientsLoading &&
            filteredClients.map((client) => (
              <MenuItem key={client.clientPublicId} value={client.clientPublicId}>
                {multiple && (
                  <Checkbox
                    checked={isClientSelected(client.clientPublicId)}
                    className="p-0 mr-2"
                  />
                )}
                <ListItemText
                  primary={clientElement({
                    name: client.name,
                    pan: client.pan,
                    balance: client.balance,
                    family: client.familyName,
                  })}
                />
              </MenuItem>
            ))}
          {hasMoreClients && !dealClientsLoading && filteredClients.length > 0 && (
            <ObservedMenuItem onIntersect={handleClientsRequest} />
          )}
        </Select>
      ) : showAddNewClient ? (
        <Button
          fullWidth
          onClick={handleAddNewClient}
          className="!bg-green-700 !text-sm !disabled:bg-green-700/60 !mx-auto !text-white !rounded-xl !font-bold !h-10 !w-full !capitalize"
        >
          Add new client
        </Button>
      ) : dealClientsLoading ? (
        <MenuItem disabled value="" className="!px-0">
          <div className="px-4  w-full pb-2">
            <p className="text-stone-700 text-xs">Loading...</p>
          </div>
        </MenuItem>
      ) : (
        <MenuItem disabled value="" className="!px-0">
          <div className="px-4  w-full border-b border-b-gray-400 pb-2">
            <p className="text-stone-700 text-xs">No Clients available for selection</p>
          </div>
        </MenuItem>
      )}
    </>
  );
};

SelectClient.displayName = 'SelectClient';

export default SelectClient;
