import React, { useState } from 'react';

interface FirstStepProps {
  familyLabel: string;
  setFamilyLabel: (value: string) => void;
  handleNext: () => void; // This now moves to the next step in the flow
}

const FirstStep: React.FC<FirstStepProps> = ({ familyLabel, setFamilyLabel, handleNext }) => {
  const [error, setError] = useState<string | null>(null);

  const onConfirm = () => {
    if (familyLabel.trim() === '') {
      setError('Family label is required.');
      return;
    }
    setError(null);
    handleNext(); // Proceed to the next step
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onConfirm();
    }
  };

  return (
    <div className="flex flex-col justify-between min-h-24 md:min-h-28 pb-4">
      <div>
        <label className="block text-gray-700 text-xs font-medium mb-2" htmlFor="familyLabel">
          Enter Family Label Name
        </label>
        <input
          id="familyLabel"
          type="text"
          placeholder="Example: Enter last name"
          className="appearance-none border border-gray-300 rounded-md py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent w-3/5"
          onChange={(e) => setFamilyLabel(e.target.value)}
          onKeyPress={handleKeyPress}
          value={familyLabel}
        />
        {error && <p className="text-red-500 text-xs mt-2">{error}</p>}
      </div>
      <button
        type="button"
        className="border-green-700 md:mt-3 shrink-0 flex items-center gap-2 text-sm text-white rounded-xl font-bold h-10 w-fit px-4"
        style={{
          background:
            'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.12) 119.05%), #16A34A',
          boxShadow: ' 0px 1.1px 1.8px -0.3px rgba(0, 0, 0, 0.10)',
        }}
        onClick={onConfirm}
      >
        Confirm
      </button>
    </div>
  );
};

FirstStep.displayName = 'FirstStep';

export default FirstStep;
