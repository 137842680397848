import React from "react";

interface ProductDeckProps { };


const ProductDeck: React.FC<ProductDeckProps> = () => {
    return (
        <div className="flex flex-col max-w-7xl mx-auto">
            <h1 className="font-medium text-4xl mb-9 mt-6">Partner’s Starter Pack</h1>
            <iframe
                src="https://drive.google.com/embeddedfolderview?id=12b1LjdY4fv83JUzUt8kKf8YQo9t75jpL#list"
                className="w-full h-[calc(100vh-75px)]"
            />
        </div>
    );
};

ProductDeck.displayName = "ProductDeck";

export default ProductDeck;