import React, { useState } from "react";
import Input from "../../components/Fields/Input";
import { Field } from "../../components/Fields/Field";
import OTPWrapper from "./OTPWrapper";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { NetworkManager } from "../../services";
import { useOnboardingNavigate } from "./store";
import { toast } from "react-hot-toast";
import KYCButton from "./KYCButton";
import { ONBOARDING_EVENTS } from "./constants";
import { useAnalytics } from "../../hooks/useAnalytics";

interface EmailAuthProps { }

const incorrectOtp = () =>
  toast.error("Invalid OTP", {
    duration: 2500,
    position: "top-center",
  });

const EmailAuth: React.FC<EmailAuthProps> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isVerifyStep, setVerifyStep] = useState(false);
  const { handleNextPage } = useOnboardingNavigate();
  const [isOtpResend, setIsOtpResend] = useState(false);
  const [timeForOtpResend, setTimeForOtpResend] = useState(30);
  const [email, setEmail] = useState<string | null>(null);

  const { track } = useAnalytics();

  const schema = yup.object().shape({
    otp: yup.number().when("emailSaved", ([emailSaved]) => {
      if (emailSaved) {
        return yup
          .number()
          .transform((value) => (Number.isNaN(value) ? null : value))
          .max(9999, "Must be a 4-digit OTP")
          .min(1000, "Must be a 4-digit OTP")
          .required("Please enter OTP");
      } else {
        return yup
          .number()
          .transform((value) => (Number.isNaN(value) ? null : value))
          .nullable();
      }
    }),
    emailSaved: yup.bool().default(false),
    email: yup
      .string()
      .required("Email is required")
      .email("Email is not valid"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { email: null },
  });

  const submitEmail = (email: string) => {
    return NetworkManager.Call({
      method: "post",
      path: NetworkManager.PATH.emailOTP,
      data: {
        email: email,
      },
    });
  };

  const submitOTP = (email: string, otp: number) => {
    return NetworkManager.Call({
      method: "post",
      path: NetworkManager.PATH.verifyEmailOTP,
      data: {
        email: email,
        otp: otp,
      },
    });
  };

  const onSubmit = async (data: Record<string, any>) => {
    const { email, otp } = data;
    setIsLoading(true);
    setEmail(email);

    if (!otp) {
      setTimeForOtpResend(30);
    }

    if (isVerifyStep) {
      try {
        const otpRes = await submitOTP(email, otp);
        if (!otpRes.error) {
          handleNextPage(otpRes.nextPage, otpRes.message);
        } else {
          if (otpRes.error && otpRes.error.includes("Invalid OTP")) {
            incorrectOtp();
          }
        }
      } catch (error) {
        if (error?.response?.status?.toString().startsWith("5")) {
          toast.error("Something went wrong! Please try again", {
            duration: 2500,
            position: "top-center",
          });
        } else if (error?.response?.status?.toString().startsWith("4")) {
          toast.error(error?.response?.data?.message, {
            duration: 2500,
            position: "top-center",
          });
        } else {
          incorrectOtp();
        }
      }
    } else {
      try {
        const emailRes = await submitEmail(email);
        if (emailRes.code === 200) {
          setValue("emailSaved", true);
          setValue("otp", null);
          setVerifyStep(true);
          handleEntryEvent(ONBOARDING_EVENTS.Email_verify);
        }
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message, {
            duration: 2500,
            position: "top-center",
          });
        } else {
          toast.error("Something went wrong! Please try again", {
            duration: 2500,
            position: "top-center",
          });
        }
      }
    }

    setIsLoading(false);
  };

  React.useEffect(() => {
    if (timeForOtpResend > 0) {
      const timerId = setTimeout(() => {
        setTimeForOtpResend(timeForOtpResend - 1);
      }, 1000);
      return () => clearTimeout(timerId);
    } else {
      setIsOtpResend(true);
    }
  }, [timeForOtpResend]);

  const handleEntryEvent = (event) => {
    track({ eventName: event });
  };

  const handleResend = async () => {
    setIsOtpResend(true);

    try {
      const otpRes = await submitEmail(email);

      if (otpRes?.code?.toString().startsWith("2")) {
        toast.success("OTP sent successfully", {
          duration: 2500,
          position: "top-center",
        });
      } else if (otpRes.error.includes("Invalid OTP")) {
        incorrectOtp();
      } else {
        toast.error("Something went wrong! Please try again", {
          duration: 2500,
          position: "top-center",
        });
      }
    } catch (err) {
      if (err?.response?.status?.toString().startsWith("4")) {
        toast.error(
          `${err?.response?.data?.message ||
          "Something went wrong, please try again later"
          }`,
          {
            duration: 2500,
            position: "top-center",
          }
        );
      } else {
        toast.error("Something went wrong! Please try again", {
          duration: 2500,
          position: "top-center",
        });
      }
    } finally {
      setIsOtpResend(false);
      setTimeForOtpResend(30);
    }
  };

  return (
    <OTPWrapper
      title="Email verification"
      verifyTitle="Verifying your mail"
      subtitle="Please enter your email address"
      verifySubtitle="Please provide OTP sent to your mail"
      isVerifyStep={isVerifyStep}
      resetVerify={() => {
        setValue("emailSaved", false);
        setValue("email", null);
        setVerifyStep(false);
        setIsLoading(false);
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        {isVerifyStep ? (
          <Field label="OTP" error={errors.otp?.message}>
            <Input
              type="text"
              numericOnly={true}
              placeholder="Enter OTP"
              {...register("otp")}
            />
          </Field>
        ) : (
          <Field label="Email Address" error={errors.email?.message}>
            <Input
              onClick={() => handleEntryEvent(ONBOARDING_EVENTS.Email_Entry)}
              type="email"
              placeholder="Enter your email address"
              {...register("email", { required: true, maxLength: 80 })}
            />
          </Field>
        )}
        <KYCButton
          isLoading={isLoading}
          text={isVerifyStep ? "Confirm & Proceed" : "Send Verification Code"}
        />
        {isVerifyStep && (
          <button
            disabled={!isOtpResend}
            type="button"
            onClick={handleResend}
            className={`${!isOtpResend ? "mx-[27%]" : "mx-[32%]"
              } text-sm px-7 rounded-xl text-black/80  h-10 mt-5 `}
          >
            Resend OTP {!isOtpResend ? `In ${timeForOtpResend}` : ""}
          </button>
        )}
      </form>
    </OTPWrapper>
  );
};

EmailAuth.displayName = "EmailAuth";

export default EmailAuth;
