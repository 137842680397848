import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import { CloseOutlinedIcon } from "../../../assets/mui";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

interface ChequeModalProps {
    handleClose: () => void;
    open: boolean;
}

const ChequeModal: React.FC<ChequeModalProps> = ({ handleClose, open }) => {
    return (
        <Dialog
            open={open}
            maxWidth="md"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: "12px !important",
                },
            }}
        >
            <DialogTitle>
                <div className=" flex justify-between items-center">
                    <div>
                        <h3 className="text-lg font-semibold">
                            Here's how it works
                        </h3>
                        <p className="text-slate-600 text-sm mt-1.5">
                            This contains a guide to add money to your Client's
                            virtual account- via cheque
                        </p>
                    </div>
                    <CloseOutlinedIcon
                        className="cursor-pointer bg-slate-200 rounded-full p-1"
                        onClick={handleClose}
                        style={{
                            width: "28px",
                            height: "28px",
                        }}
                    />
                </div>
            </DialogTitle>

            <DialogContent>
                <div className="py-5 bg-white rounded-lg relative overflow-hidden">
                    <div className="overflow-y-auto max-h-96 pr-4">
                        <h3 className="text-zinc-500 text-sm font-semibold">
                            1. Write a Cheque:
                        </h3>
                        <p className="text-zinc-500 text-sm mt-1.5">
                            Make the cheque payable to: PURPLE PETAL INVEST PVT
                            LTD COLLECTION ESCROW ACCOUNT
                        </p>
                        <p className="text-zinc-500 text-sm mt-1.5">
                            Ensure the following details are correctly filled
                            in:
                        </p>
                        <p className="text-zinc-500 text-sm mt-1.5">
                            Account Number: 004705017351
                        </p>
                        <p className="text-zinc-500 text-sm mt-1.5">
                            IFSC Code: ICIC0000047
                        </p>
                        <p className="text-zinc-500 text-sm mt-1.5">
                            Branch: Bangalore - Koramangala
                        </p>
                        <h3 className="text-zinc-500 text-sm font-semibold mt-5">
                            2. Deposit the Cheque:
                        </h3>
                        <p className="text-zinc-500 text-sm mt-1.5">
                            Visit your bank and deposit the cheque into the
                            above account.
                        </p>
                        <h3 className="text-zinc-500 text-sm font-semibold mt-5">
                            3. Share the UTR and Cheque Number:
                        </h3>
                        <p className="text-zinc-500 text-sm mt-1.5">
                            After depositing the cheque, please ask your client
                            to share the UTR (Unique Transaction Reference)
                            number and the Cheque Number with you & kindly
                            forward that to RM.
                        </p>
                        <h3 className="text-zinc-500 text-sm font-semibold mt-5">
                            4. Credit to Your Virtual Account:
                        </h3>
                        <p className="text-zinc-500 text-sm mt-1.5">
                            Once we receive the UTR and Cheque Number, we will
                            credit the equivalent amount to your virtual account
                            within 24 hours.
                        </p>
                        <h3 className="text-zinc-500 text-sm font-semibold mt-5">
                            Important Notes:
                        </h3>
                        <p className="text-zinc-500 text-sm mt-1.5">
                            It may take up to 24 hours for the amount to reflect
                            in your virtual account. Ensure all cheque details
                            are accurately filled in to avoid any errors.
                        </p>
                        <p className="text-zinc-500 text-sm mt-1.5">
                            Ensure all cheque details are accurately filled in
                            to avoid any errors.
                        </p>
                    </div>
                    <div className="absolute bottom-0 left-0 right-0 h-12 bg-gradient-to-t from-white to-transparent pointer-events-none"></div>
                </div>
                <div className="flex justify-between items-center">
                    <div className="flex gap-1 items-center">
                        <HelpOutlineOutlinedIcon
                            sx={{
                                color: "#475569",
                                height: "20px",
                                width: "20px",
                            }}
                        />
                        <span className="text-slate-600">
                            Have queries? Reach out to your RM
                        </span>
                    </div>
                    <button
                        className="bg-green-600 text-white py-2 px-4 rounded-lg shadow-md"
                        onClick={handleClose}
                    >
                        Understood
                    </button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default ChequeModal;
