// src/components/InvestmentDetails.tsx
import React from "react";
import { ClickingTimer } from "../../../components/Fields/ClickingTime";
import { UserPaymentPersonalDetails } from "../types";
import SubdirectoryArrowRightRoundedIcon from "@mui/icons-material/SubdirectoryArrowRightRounded";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  Skeleton,
} from "@mui/material";
import { getRupeeWithDecimal } from "../../../utils/numbers";
import { useMobileSize } from "../../../hooks/useScreenSize";

interface PersonalDetailsProps extends UserPaymentPersonalDetails {
  loading: boolean;
  setShowLenderOnboardingAgreement: (value: boolean) => void;
  makeInvestment: () => void;
  setPhone: (phone: string) => void;
}

const convertEnumToReadableFormat = (enumValue: string) => {
  const map: { [key: string]: string } = {
    TAP_1M_FIXED: "1 months plan",
    TAP_3M_FIXED: "3 months plan",
    TAP_6M_FIXED: "6 months plan",
    TAP_12M_FIXED: "12 months plan",
    TAP_1M_MON_FIXED: "1 months plan",
    TAP_3M_MON_FIXED: "3 months plan",
    TAP_6M_MON_FIXED: "6 months plan",
    TAP_12M_MON_FIXED: "12 months plan",
  };

  return map[enumValue] || enumValue;
};

// Function to convert time to milliseconds
const convertEpochToLocalMilliseconds = (time: string) => {
  const userTime = new Date().getTime();
  return parseInt(time) - userTime;
};

const InvestmentDetails: React.FC<PersonalDetailsProps> = ({
  data,
  loading,
  setShowLenderOnboardingAgreement,
  makeInvestment,
  setPhone,
}) => {
  const [checked, setChecked] = React.useState(true);
  const isDisabled =
    loading ||
    !data ||
    parseInt(data?.expiryDateTime) < new Date().getTime() ||
    !checked;
  const isMobile = useMobileSize();
  const buttonStyles = {
    marginTop: "20px",
    borderRadius: "12px",
    border: isDisabled ? "" : "1px solid #15803D",
    background: isDisabled
      ? "#D3D3D3"
      : "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.12) 119.05%), #16A34A",
    boxShadow: isDisabled
      ? "none"
      : "0px 1.1px 1.8px -0.3px rgba(0, 0, 0, 0.10)",
    color: "white",
    width: "100%",
    padding: "8px 16px",
    transition: "background 0.3s ease-in-out, transform 0.3s ease-in-out",
    cursor: isDisabled ? "not-allowed" : "pointer",
  };

  return (
    <div
      className={`bg-white shadow-md rounded-lg p-5 ${isMobile && "mb-5"}`}
      style={{ minWidth: "450px", maxWidth: "600px" }}
    >
      <div className="flex justify-between items-center">
        <div className="flex ">
          <SubdirectoryArrowRightRoundedIcon
            className="text-stone-400"
            sx={{ width: "15px", height: "15px" }}
          />
          <p className="text-xs text-stone-400 font-medium">
            Requested by {loading ? <Skeleton width={60} /> : data?.partnerName}
          </p>
        </div>
        {loading ? (
          <Skeleton width={60} />
        ) : (
          <ClickingTimer
            time={convertEpochToLocalMilliseconds(data?.expiryDateTime) || 0}
            sx="text-xs text-stone-600 bg-stone-200 px-2 py-1 rounded-2xl"
          />
        )}
      </div>
      <div className="flex flex-col items-center mt-16 gap-1 mb-10">
        <h3 className="text-lg font-bold">Investment in Tap Reserve</h3>
        <p className="text-sm text-gray-600">
          {loading ? (
            <Skeleton width={60} />
          ) : (
            convertEnumToReadableFormat(data?.investmentType)
          )}
        </p>
      </div>
      <div className="mt-4 flex flex-col gap-4 px-3">
        <div className="flex justify-between">
          <p className="text-base text-stone-500">Investment amount:</p>
          <p className="text-base font-semibold text-stone-800">
            {loading ? (
              <Skeleton width={60} />
            ) : (
              getRupeeWithDecimal.format(data?.amount || 0)
            )}
          </p>
        </div>
        <div className="flex justify-between">
          <p className="text-base text-stone-500">Returns p.a:</p>
          <p className="text-base font-semibold text-stone-800">
            {loading ? (
              <Skeleton width={60} />
            ) : data ? (
              data?.returns + "%"
            ) : (
              "-"
            )}
          </p>
        </div>{" "}
        <div className="flex justify-between">
          <p className="text-base text-stone-500">Projected Returns:</p>
          <p className="text-base font-semibold text-stone-800">
            {loading ? (
              <Skeleton width={60} />
            ) : (
              getRupeeWithDecimal.format(data?.totalProjectedValue || 0)
            )}
          </p>
        </div>{" "}
        <div className="flex justify-between">
          <p className="text-base text-stone-500">Payout Date:</p>
          <p className="text-base font-semibold text-stone-800">
            {loading ? (
              <Skeleton width={60} />
            ) : (
              data?.payoutDate?.split("-").reverse().join("-") || 0
            )}
          </p>
        </div>
      </div>
      <div
        className="border-gray-300 mt-4 pt-4"
        style={{
          backgroundSize: "24px 1px",
          backgroundRepeat: "repeat-x",
          backgroundImage:
            "linear-gradient(to right, rgba(0,0,0,0.12) 35%, rgba(255,255,255,0) 0%)",
        }}
      >
        <div className="flex justify-between px-3">
          <p className="text-base text-stone-500">To be paid:</p>
          <p className="text-base font-semibold text-green-700">
            {loading ? (
              <Skeleton width={60} />
            ) : (
              getRupeeWithDecimal.format(data?.amount || 0)
            )}
          </p>
        </div>
        <Box mt={2}>
          <FormControlLabel
            control={
              <Checkbox
                className="custom-checkbox"
                defaultChecked={checked}
                onChange={(e) => setChecked(e.target.checked)}
                sx={{
                  "& .MuiSvgIcon-root": {
                    color: "#16A34A",
                  },
                }}
              />
            }
            label={
              <span className="text-sm text-stone-600 opacity-70">
                By proceeding, you agree to Lendbox's{" "}
                <Link
                  href="https://cdn.tapinvest.in/documents/P2PLenderAgreement.pdf"
                  target="_blank"
                  underline="hover"
                  style={{
                    cursor: "pointer",
                    color: "#57534e",
                    textDecoration: "underline",
                    textUnderlineOffset: "2px",
                  }}
                >
                  Lender Onboarding Agreement
                </Link>
                {/* <span
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowLenderOnboardingAgreement(true)}
                >
                  Lender Onboarding Agreement
                </span> */}
              </span>
            }
          />
        </Box>

        <Button
          style={buttonStyles}
          onMouseDown={(e) => {
            if (!isDisabled) e.currentTarget.style.background = "#15803D";
          }}
          onMouseUp={(e) => {
            if (!isDisabled)
              e.currentTarget.style.background =
                "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.12) 119.05%), #16A34A";
          }}
          onClick={() => {
            if (!isDisabled) makeInvestment();
          }}
          disabled={isDisabled}
        >
          pay now
        </Button>
      </div>
    </div>
  );
};

export default InvestmentDetails;
