import React, { useCallback, useState } from 'react';
import { useClient, useClientSummary } from './useClient';
import ClientTable from './ClientsTable';
import { useNavigate } from 'react-router-dom';
import { AddCircle, SearchOutlined, Diversity3 } from '@mui/icons-material';
import ClientDrawer from './ClientDrawer/ClientDrawer';
import { useLayoutStore } from '../../components/organisms/Layout/store';
import { useDelay } from '../../hooks/useDelay';
import TableSkeleton from '../../components/TableSkeleton';
import Input from '../../components/Fields/Input';
import { debounce } from '../../utils/debounce';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import { NetworkManager } from '../../services';
import { useDownloadCsv } from '../../hooks/useDownloadCsv';
import { CLIENTS_EVENTS } from './constants';
import { useAnalytics } from '../../hooks/useAnalytics';

interface ClientsProps {}

const Clients: React.FC<ClientsProps> = () => {
  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(0);
  const [orderBy, setOrderBy] = useState('walletBalance');
  const [search, setSearch] = useState('');
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');

  const { csvLoading, downloadCsv } = useDownloadCsv({
    path: NetworkManager.PATH.csvPartnerClient,
    filename: 'client.csv',
  });

  const { track } = useAnalytics();

  const { setIsClientDrawerOpen, setClientDetails } = useLayoutStore(
    ({ setClientDetails, setIsClientDrawerOpen }) => ({
      setClientDetails,
      setIsClientDrawerOpen,
    }),
  );
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNo(0);
  };

  const handleRowClick = (row: any) => {
    if (row.url && row.isKycVerified) {
      navigate(row.url);
    } else {
      setClientDetails({
        name: row.originalName,
        email: row.originalEmail,
        pan: row.pan,
      });
      setIsClientDrawerOpen(row.clientPublicId);
    }
  };

  const handleAddNewClient = useCallback(() => {
    setIsClientDrawerOpen(null);
    track({ eventName: CLIENTS_EVENTS.Add_new_Client });
  }, []);

  const handleChangeSort = (e, columnId) => {
    if (columnId === orderBy) {
      setOrder(order === 'desc' ? 'asc' : 'desc');
    }
    setOrderBy(columnId);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const { data, isLoading } = useClient({
    payload: {
      pageNo,
      pageSize,
      orderBy,
      order,
      search,
    },
  });

  const isClientsReady = useDelay(isLoading, 500);

  const { data: summaryData, isLoading: isSummaryLoading } = useClientSummary();
  const { investedUsers, kycPendingUsers, notInvestedUsers, totalUsers } = summaryData?.data || {};

  const debounceHandleSearch = debounce(handleSearch, 500);

  return (
    <div className="my-6 max-w-7xl mx-auto flex flex-col gap-4">
      {/* Clients Summary */}
      <div className="grid grid-cols-2 xl:grid-cols-4 border border-stone-300 rounded-lg bg-black/[.02]">
        <div className="p-4 md:p-6 border-r border-r-stone-300 max-xl:border-b max-xl:border-b-stone-300">
          <h3 className="text-stone-700 font-semibold text-xs uppercase mb-1">Total</h3>
          <p className="text-2xl font-medium text-stone-700">{totalUsers}</p>
        </div>
        <div className="p-4 md:p-6 xl:border-r xl:border-r-stone-300 max-xl:border-b max-xl:border-b-stone-300">
          <h3 className="text-stone-700 font-semibold text-xs uppercase mb-1">Invested</h3>
          <p className="text-2xl font-medium text-stone-700">{investedUsers}</p>
        </div>
        <div className="p-4 md:p-6 border-r border-r-stone-300">
          <h3 className="text-stone-700 font-semibold text-xs uppercase mb-1">Not Invested</h3>
          <p className="text-2xl font-medium text-stone-700">{notInvestedUsers}</p>
        </div>
        <div className="p-4 md:p-6">
          <h3 className="text-stone-700 font-semibold text-xs uppercase mb-1">KYC Pending</h3>
          <p className="text-2xl font-medium text-stone-700">{kycPendingUsers}</p>
        </div>
      </div>

      <div className="flex flex-col justify-start gap-3 items-start md:flex-row md:justify-between md:items-center mt-6">
        <Input
          prefixIcon={<SearchOutlined />}
          placeholder="Search"
          onChange={debounceHandleSearch}
          className="ml-2 ps-11"
        />
        <div className="flex items-center gap-3">
          <button
            onClick={downloadCsv}
            disabled={csvLoading}
            type="button"
            className="bg-white shadow border border-slate-300 flex items-center gap-2 text-sm  text-gray-800 rounded-lg font-semibold h-10 w-fit px-4"
          >
            <DownloadForOfflineOutlinedIcon fontSize="small" />
            Download Excel
          </button>
          <button
            type="button"
            onClick={handleAddNewClient}
            className="bg-green-700 flex items-center gap-2 text-sm disabled:bg-green-700/80 text-white rounded-xl font-bold h-10 w-fit px-4"
          >
            <AddCircle fontSize="small" />
            Add New Client
          </button>
          <button
            type="button"
            onClick={() => navigate('/family-account')}
            className="bg-green-700 flex items-center gap-2 text-sm disabled:bg-green-700/80 text-white rounded-xl font-bold h-10 w-fit px-4"
          >
            <Diversity3 fontSize="small" />
            Family Account
          </button>
        </div>
      </div>
      {/* Clients Table */}
      {isClientsReady && !isLoading ? (
        <ClientTable
          data={data?.data}
          pageSize={pageSize}
          pageNo={pageNo}
          orderBy={orderBy}
          order={order}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleRowClick={handleRowClick}
          handleChangeSort={handleChangeSort}
          type='client'
        />
      ) : (
        <TableSkeleton />
      )}
      <ClientDrawer />
    </div>
  );
};

Clients.displayName = 'Clients';

export default Clients;
