import React from "react";
import Image from "../../../assets/rbi.png";

interface MetricsProps {
  title?: string;
  subtitle?: string;
  image?: string;
  borderRight?: boolean;
  paddingLeft?: boolean;
}

const Metrics: React.FC<MetricsProps> = ({
  title,
  subtitle,
  image,
  borderRight,
  paddingLeft = true,
}) => {
  return (
    <div
      className={`pr-6 ${paddingLeft ? "pl-6" : ""}  ${
        borderRight ? "border-r border-gray-200" : ""
      }`}
    >
      {image && <img src={image} alt="Metric icon" className="w-8 h-8" />}
      {title && <h1 className="text-2xl text-stone-500 opacity-90">{title}</h1>}
      {subtitle && (
        <h1 className="text-base text-stone-500 opacity-90">{subtitle}</h1>
      )}
    </div>
  );
};

const AboutTapReserve: React.FC = () => {
  const minInvestment = 5000;

  return (
    <div
      className="p-5 pb-3 mt-5"
      style={{ minWidth: "400px", maxWidth: "600px" }}
    >
      <h1 className="text-xl font-semibold text-slate-800">
        About Tap Reserve
      </h1>
      <div className="flex gap-1 flex-col my-6">
        <p className="text-base text-gray-500 font-normal">
          {`Build a P2P portfolio with Tap Reserve and India’s largest RBI regulated P2P NBFC,
        Lendbox`}
        </p>
        <p className="text-base text-gray-500 font-normal mt-1">About Lendbox:</p>
      </div>
      <div className="flex mt-7 ">
        <Metrics
          title="4.7 Lakh+"
          subtitle="Investors till date"
          borderRight={true}
          paddingLeft={false}
        />
        <Metrics
          title="2,563 Cr+"
          subtitle="Assets Under Management"
          borderRight={true}
        />
        <Metrics subtitle="RBI Regulated" image={Image} />
      </div>
    </div>
  );
};

export default AboutTapReserve;
