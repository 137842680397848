import clsx from "clsx";
import React, { ReactNode, forwardRef } from "react";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  containerClassName?: string;
  prefixIcon?: ReactNode;
  prefixNode?: ReactNode;
  numericOnly?: boolean; // New prop to control numeric input
}

// Define the custom input component
const Input: React.ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    value,
    prefixNode,
    prefixIcon,
    className,
    containerClassName,
    numericOnly,
    ...rest
  },
  ref
) => {
  // Handler to allow only numbers
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (numericOnly && !/[0-9]/.test(event.key) && event.key !== "Enter") {
      event.preventDefault();
    }
  };

  // Handler to ensure only numeric input
  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (numericOnly) {
      event.target.value = event.target.value.replace(/[^0-9]/g, "");
    }
  };

  return (
    <div
      className={clsx(
        "relative border flex border-gray-300 rounded-lg overflow-hidden shadow-sm",
        containerClassName
      )}
    >
      {prefixNode && (
        <div className="flex items-center bg-gradient-to-b from-white via-black/0 to-black/[0.04] border-r border-r-gray-300 px-5 text-slate-600">
          {prefixNode}
        </div>
      )}
      {prefixIcon && (
        <div className="absolute inset-y-0 left-0 flex items-center text-gray-500 pl-3 pointer-events-none">
          {prefixIcon}
        </div>
      )}
      <input
        ref={ref}
        value={value}
        className={clsx(
          "px-4 w-full py-3.5 h-[46px] text-gray-800 rounded-lg focus:outline-none placeholder:text-gray-500",
          "[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none",
          { "ps-8": !!prefixIcon },
          { "ps-4": !!prefixNode },
          className
        )}
        onKeyPress={handleKeyPress}
        onInput={handleInput}
        {...rest}
      />
    </div>
  );
};

export default forwardRef(Input);
