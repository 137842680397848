import React from "react";

const AboutWallet: React.FC = () => {
  return (
    <div
      className="p-5 pb-3 mt-5"
      style={{ minWidth: "400px", maxWidth: "600px" }}
    >
      <h1 className="text-xl font-semibold text-slate-800">
        What happens after wallet recharge?
      </h1>
      <div className="flex gap-1 flex-col my-6">
        <p className="text-base text-gray-500 font-normal">
          After you recharge the wallet, the partner will be able to invest in
          upcoming deals. Add money to the wallet to proceed with wallet
          recharge.
        </p>
      </div>
    </div>
  );
};

export default AboutWallet;
