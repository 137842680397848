import { useEffect, useState } from "react";

export const useDelay = (
  loading: boolean,
  delay: number = 1000
) => {
  const [ready, setReady] = useState(false);
  const [initialLoading] = useState(loading);
  useEffect(() => {
    if (!loading && initialLoading) {
      setTimeout(() => setReady(true), delay);
    }
  }, [loading]);

  return !initialLoading || !loading ||  ready;
};
