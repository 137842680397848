import React from "react";
import Skeleton from "@mui/material/Skeleton";

interface TableSkeletonProps { };

const TableSkeleton: React.FC<TableSkeletonProps> = () => {
    return (
        <div className="rounded-lg divide-y divide-stone-300 flex flex-col border border-stone-300">
            <div className="p-2">
                <Skeleton width="100%" height={60} />
            </div>
            <div className="p-2">
                <Skeleton width="100%" height={40} className="pt-2" />
            </div>
            <div className="p-2">
                <Skeleton width="100%" height={40} className="pt-2" />
            </div>
            <div className="p-2">
                <Skeleton width="100%" height={40} className="pt-2" />
            </div>
            <div className="p-2">
                <Skeleton width="100%" height={40} className="pt-2" />
            </div>
        </div>
    );
};

TableSkeleton.displayName = "TableSkeleton";

export default TableSkeleton;