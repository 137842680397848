import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import TablePagination from '@mui/material/TablePagination';
import { baseColors } from '../../../assets/theme';

const Tables = ({
  columns,
  rows,
  handleChangeRowsPerPage,
  handleChangePage,
  handleChangeSort = null,
  sortableColumns = null,
  orderBy = null,
  order = null,
  handleRowClick,
  rowsPerPage,
  page,
  totalData,
  loading = false,
  pagination = true,
}) => {
  return (
    <Paper
      sx={{
        overflow: 'hidden',
        borderRadius: '10px',
        boxShadow: 'none',
        marginBottom: '20px',
        border: `1px solid ${baseColors.slate[300]}`,
      }}
    >
      <TableContainer
        sx={{
          borderRadius: '10px',
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => {
                const columnId = sortableColumns?.[column.id];

                return (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    sx={{
                      minWidth: column.minWidth,
                      backgroundColor: '#f6f6f2',
                    }}
                    sortDirection={orderBy === columnId ? order : false}
                  >
                    {columnId ? (
                      <TableSortLabel
                        active={orderBy === columnId}
                        direction={orderBy === columnId ? order : 'asc'}
                        classes={{
                          icon: '!text-black',
                        }}
                        onClick={(e) => handleChangeSort && handleChangeSort(e, columnId)}
                      >
                        {column.label}
                      </TableSortLabel>
                    ) : (
                      column.label
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading
              ? // Show skeleton rows when loading is true
                Array.from(new Array(rowsPerPage)).map((_, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    sx={{
                      backgroundColor: '#f6f6f2',
                    }}
                  >
                    {columns.map((column, colIndex) => (
                      <TableCell key={colIndex}>
                        <Skeleton variant="rectangular" height={20} />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              : // Show actual data rows when loading is false
                rows.map((row) => (
                  <TableRow
                    hover
                    className="cursor-pointer"
                    role="checkbox"
                    tabIndex={-1}
                    key={row.code}
                    sx={{
                      backgroundColor: row?.name?.kyc === false ? '#FEF2F2' : '#FBFBF6',
                    }}
                  >
                    {columns.map((column) => {
                      let value = row[column.id];

                      // Determine the display value based on the conditions
                      let displayValue;
                      if (
                        column.id === 'dealName' &&
                        typeof value === 'object' &&
                        value?.props?.name?.toLowerCase() === 'p2p'
                      ) {
                        // Create a new object with modified properties instead of altering the original
                        displayValue = {
                          ...value,
                          props: { ...value.props, name: 'Tap Reserve' },
                        };
                      } else {
                        displayValue = value;
                      }

                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          onClick={() => handleRowClick && handleRowClick(row)}
                        >
                          {typeof displayValue === 'object'
                            ? displayValue?.value || displayValue
                            : displayValue}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && (
        <TablePagination
          rowsPerPageOptions={[10, 20]}
          component="div"
          count={totalData}
          rowsPerPage={rowsPerPage}
          page={page}
          sx={{
            backgroundColor: '#f6f6f2',
            '.MuiPaginationItem-root': {
              backgroundColor: '#f6f6f2',
            },
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
};

export default Tables;
