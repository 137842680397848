import { useQuery, PATH, Call } from '../../services/NetworkManager';

export function getDealDetails({ queryKey }) {
  const id = queryKey[0].split(',')[1];
  const financeType = queryKey[0].split(',')[2];

  return Call({
    method: 'get',
    path: `${PATH.deal.replace('{{dealId}}', id)}${
      financeType ? `?financeType=${financeType}` : ''
    }`,
  });
}

export function getDealStats({ queryKey }) {
  const id = queryKey[0].split(',')[1];

  return Call({
    method: 'get',
    path: PATH.dealInfo.replace('{{dealId}}', id),
  });
}

export function getDealClients({ queryKey }) {
  const pageNo = queryKey[0].split(',')[1];
  const value = queryKey[0].split(',')[2];

  return Call({
    method: 'post',
    path: PATH.clients,
    data: {
      currentPage: parseInt(pageNo),
      pageSize: 100,
      sortBy: 'walletBalance',
      sortDir: 'DESC',
    },
  });
}

// TODO: Deal Details types once API keys available
export function useDealDetails(dealId?: string, financeType?: string) {
  const { data, isLoading } = useQuery(`${['deal', dealId, financeType]}`, getDealDetails);
  return { data, isLoading };
}

export function useDealStats(dealId?: string, isPooling?: boolean) {
  const { data, isLoading } = useQuery(`${['dealInfo', dealId]}`, getDealStats, {
    enabled: !isPooling,
  });
  return { data, isLoading };
}

export function useDealClients(pageNo: number, value?: string) {
  const { data, isLoading } = useQuery(`${['dealClients', pageNo, value]}`, getDealClients);
  return { data, isLoading };
}
