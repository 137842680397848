import { QueryClient, QueryClientProvider, useMutation, useQuery } from './dependency/reactQuery';
import axios from './dependency/axios';
import { API_URL, API_URL_KRAKEN, PATH } from './endpoints';
// import { decision } from 'global';

/**
 * This is an abstracted method over axios.
 * Just provide the path and base url will be taken up based on node environment automatically
 * @param {*} data  { path: '/login/'}
 * @returns
 */

let appInfo = null;

async function Call(data) {
  const authToken = localStorage.getItem('token');
  const sessionId = localStorage.getItem('_tapartsid');
  const deviceId = localStorage.getItem('_tapartdid');

  let headers = {};
  if (data?.headers) {
    headers = { ...headers, ...(data?.headers || {}) };
    delete data.headers;
  }
  if (!data.noHeaders) {
    if (authToken) {
      headers = { ...headers, authorization: authToken };
    }
    if (sessionId) {
      headers = { ...headers, 'X-Tap-Session-Id': sessionId };
    }
    if (deviceId) {
      headers = { ...headers, 'X-Tap-Device-Id': deviceId };
    }
    // headers = { ...headers, 'x-platform-request': decision.platform };
    if (appInfo?.build) {
      headers = { ...headers, 'x-client-build-id': appInfo.build };
    }
  }

  const baseURL = data.path.includes('payment-link') ? API_URL_KRAKEN : API_URL;

  let option = {
    url: data.path,
    baseURL,
    headers,
    withCredentials: true,
    ...data,
  };
  try {
    const r = await axios(option);
    const responseSessionId = r.headers['X-Tap-Partner-Session-Id'];
    const responseDeviceId = r.headers['X-Tap-Partner-Device-Id'];
    if (responseSessionId) {
      localStorage.setItem('_tapartsid', responseSessionId);
    }
    if (responseDeviceId) {
      localStorage.setItem('_tapartdid', responseDeviceId);
    }
    return data.getOriginalResponse === true ? r : r.data;
  } catch (err) {
    // if (err?.response?.status === 403) {
    //   if (window) {
    //     // console.error('Forbidden');
    //     // localStorage.removeItem('token');
    //     // window.location.replace("https://leafround.com/deals")
    //   } else {
    //     console.error('Failed to redirect');
    //   }
    //   throw err;
    // }
    if (err?.response?.status === 401 && !data?.disableRedirect) {
      if (window) {
        localStorage.removeItem('token');

        if (process.env.NODE_ENV !== 'development') {
          window.location.replace('/');
        }
      } else {
        console.error('Failed to redirect');
      }
    }
    throw err;
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
    },
  },
});

const CustomQueryClientProvider = ({ children }) => {
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

export {
  Call,
  PATH,
  API_URL,
  API_URL_KRAKEN,
  CustomQueryClientProvider,
  useQuery,
  useMutation,
  queryClient,
};
