import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { useLocation } from "react-router-dom";
import { lenderOnboardingAgreement } from "../types";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import { useMobileSize } from "../../../hooks/useScreenSize";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

const LenderOnboardingAgreement: React.FC<lenderOnboardingAgreement> = ({
  closeModal,
  lendboxAgreementUrl,
  ShowLenderOnboardingAgreement,
}) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [pageScale, setPageScale] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [loadingError, setLoadingError] = useState("");
  const location = useLocation();
  const isMobileAndTablet = useMobileSize();

  useEffect(() => {
    const closeOnUrlChange = () => closeModal();

    return () => {
      closeOnUrlChange();
    };
  }, [location]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };
  
  function handleZoomIn() {
    if (pageScale < 3) {
      setPageScale(pageScale + 0.2);
    }
  }

  function handleZoomOut() {
    if (pageScale > 0.3) {
      setPageScale(pageScale - 0.2);
    }
  }
  const overFlowCondition = () => {
    if (!isMobileAndTablet) {
      if (pageScale < 1.1) {
        return "hidden";
      } else {
        return "scroll";
      }
    } else {
      if (pageScale < 0.8) {
        return "hidden";
      } else {
        return "scroll";
      }
    }
  };

  return (
    <Modal open={ShowLenderOnboardingAgreement} onClose={closeModal}>
      <div
        className={`bg-white shadow-24 rounded-12 ${
          !isMobileAndTablet
            ? "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-650"
            : ""
        }`}
      >
        <div className="flex justify-between items-center p-3 bg-stone-50 rounded-12">
          <div>
            <h6 className={`mb-${isMobileAndTablet ? "0.75" : "0.5"}`}>
              Lender Onboarding Agreement
            </h6>
            {/* <p className="text-sm">Agreement highlighting the sale of receivable invoices</p> */}
          </div>
          <button onClick={closeModal}>
            <CloseIcon />
          </button>
        </div>
        <div
          className={`overflow-y-scroll ${
            overFlowCondition() === "hidden"
              ? "overflow-x-hidden"
              : "overflow-x-scroll"
          } h-400`}
        >
          {lendboxAgreementUrl ? (
            <Document
              file={lendboxAgreementUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={(error) => setLoadingError(error.message)}
              loading={
                <p className="flex justify-center items-center">
                  Loading PDF...
                </p>
              }
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  scale={pageScale}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                />
              ))}
            </Document>
          ) : (
            <p className="flex justify-center items-center">Loading PDF...</p>
          )}
        </div>
        <div className="flex justify-between items-center bg-stone-50 p-3 rounded-12">
          <div className="flex gap-15 items-center">
            <Button
              onClick={handleZoomIn}
              disabled={pageScale >= 3}
              size="small"
              className="rounded-full p-12 min-w-50"
            >
              <ZoomInIcon className="text-slate-600" />
            </Button>
            <Button
              onClick={handleZoomOut}
              disabled={pageScale <= 0.3}
              size="small"
              className="rounded-full p-12 min-w-50"
            >
              <ZoomOutIcon className="text-slate-600" />
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LenderOnboardingAgreement;
