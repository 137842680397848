import React, { useState } from "react";
import Input from "../../../components/Fields/Input";
import { Field } from "../../../components/Fields/Field";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { NetworkManager } from "../../../services";
import { toast } from "react-hot-toast";
import { kycError } from "../../../utils/toast";
import { ClientOnboardingActions } from "../types";
import { CLIENTS_EVENTS, CLIENT_ONBOARDING_PAGE_TYPE } from "../constants";
import KYCButton from "../../Onboarding/KYCButton";
import { useLayoutStore } from "../../../components/organisms/Layout/store";
import { AADHAAR_ERROR_MESSAGE } from "../../Onboarding/constants";
import { useAnalytics } from "../../../hooks/useAnalytics";

interface AadhaarVerificationProps extends ClientOnboardingActions { }

const aadhaarSchema = yup
  .string()
  .required("Please enter Aadhaar Number")
  .test("no-spaces", "Aadhaar number should not contain spaces", (value) => {
    return !/\s/.test(value);
  })
  .matches(/^[0-9]{12}$/, "Invalid Aadhaar number format");

const incorrectOtp = () =>
  toast.error("Invalid OTP", {
    duration: 2500,
    position: "top-center",
  });

const AadhaarVerification: React.FC<AadhaarVerificationProps> = ({
  handleStep,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isVerifyStep, setVerifyStep] = useState(false);
  const [isOtpResend, setIsOtpResend] = useState(false);
  const [timeForOtpResend, setTimeForOtpResend] = useState(30);
  const [aadhaar, setAadhaar] = useState<string | null>(null);
  const { clientId } = useLayoutStore(({ clientId }) => ({ clientId }));

  const { track } = useAnalytics();

  const schema = yup.object().shape({
    otp: yup.number().when("aadhaarSaved", ([aadhaarSaved]) => {
      if (aadhaarSaved) {
        return yup
          .number()
          .transform((value) => (Number.isNaN(value) ? null : value))
          .max(999999, "Must be a 6-digit OTP")
          .min(100000, "Must be a 6-digit OTP")
          .required("Please enter OTP");
      } else {
        return yup
          .number()
          .transform((value) => (Number.isNaN(value) ? null : value))
          .nullable();
      }
    }),
    aadhaar: aadhaarSchema,
    aadhaarSaved: yup.bool().default(false),
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { aadhaar: null },
  });

  const submitAadhaar = (aadhaar: string) => {
    return NetworkManager.Call({
      method: "post",
      path: NetworkManager.PATH.clientAadhaarInfo,
      data: {
        clientId,
        aadhaarNumber: aadhaar,
      },
    });
  };

  const submitOTP = (otp: number) => {
    return NetworkManager.Call({
      method: "post",
      path: NetworkManager.PATH.clientVerifyAadhaarInfo,
      data: {
        clientId,
        otp: otp,
      },
    });
  };

  const onSubmit = async (data: Record<string, any>) => {
    const { aadhaar, otp } = data;
    setIsLoading(true);
    setAadhaar(aadhaar);
    if (!otp) {
      setTimeForOtpResend(30);
    }

    if (isVerifyStep) {
      try {
        const otpRes = await submitOTP(otp);
        if (!otpRes.error) {
          handleStep(CLIENT_ONBOARDING_PAGE_TYPE.BANK_VERIFICATION);
        } else {
          kycError(AADHAAR_ERROR_MESSAGE);
          setVerifyStep(false);
          setValue("aadhaar", aadhaar);
        }
      } catch (error) {
        if (error?.response?.status?.toString().startsWith("5")) {
          toast.error(AADHAAR_ERROR_MESSAGE, {
            duration: 2500,
            position: "top-center",
          });
        } else if (error?.response?.status?.toString().startsWith("4")) {
          toast.error(error?.response?.data?.message, {
            duration: 2500,
            position: "top-center",
          });
        } else {
          toast.error(AADHAAR_ERROR_MESSAGE, {
            duration: 2500,
            position: "top-center",
          });
        }
      }
    } else {
      try {
        const aadhaarRes = await submitAadhaar(aadhaar);
        setValue("aadhaarSaved", true);
        setValue("otp", null);
        setVerifyStep(true);
        handleEntryEvent(CLIENTS_EVENTS.KYC_Aadhaar_Request_OTP);
      } catch (error) {
        if (error?.response?.status?.toString().startsWith("5")) {
          toast.error(AADHAAR_ERROR_MESSAGE, {
            duration: 2500,
            position: "top-center",
          });
        } else if (error?.response?.status?.toString().startsWith("4")) {
          toast.error(error?.response?.data?.message, {
            duration: 2500,
            position: "top-center",
          });
        } else {
          toast.error(AADHAAR_ERROR_MESSAGE, {
            duration: 2500,
            position: "top-center",
          });
        }
      }
    }
    setIsLoading(false);
  };
  const handleResend = async () => {
    setIsOtpResend(true);

    try {
      const otpRes = await submitAadhaar(aadhaar);

      if (otpRes?.code?.toString().startsWith("2")) {
        toast.success("OTP sent successfully", {
          duration: 2500,
          position: "top-center",
        });
      } else if (otpRes.error.includes("Invalid OTP")) {
        incorrectOtp();
      } else {
        toast.error(AADHAAR_ERROR_MESSAGE, {
          duration: 2500,
          position: "top-center",
        });
      }
    } catch (err) {
      if (err?.response?.status?.toString().startsWith("4")) {
        toast.error(
          `${err?.response?.data?.message ||
          AADHAAR_ERROR_MESSAGE
          }`,
          {
            duration: 2500,
            position: "top-center",
          }
        );
      } else {
        toast.error(AADHAAR_ERROR_MESSAGE, {
          duration: 2500,
          position: "top-center",
        });
      }
    } finally {
      setIsOtpResend(false);
      setTimeForOtpResend(30);
    }
  };
  React.useEffect(() => {
    if (timeForOtpResend > 0) {
      const timerId = setTimeout(() => {
        setTimeForOtpResend(timeForOtpResend - 1);
      }, 1000);
      return () => clearTimeout(timerId);
    } else {
      setIsOtpResend(true);
    }
  }, [timeForOtpResend]);
  const handleEntryEvent = (event) => {
    track({ eventName: event });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col max-w-96">
      {isVerifyStep ? (
        <Field label="OTP" error={errors.otp?.message}>
          <Input
            onClick={() =>
              handleEntryEvent(CLIENTS_EVENTS.KYC_Aadhaar_OTP_Entry)
            }
            type="text"
            placeholder="Enter OTP"
            numericOnly={true}
            {...register("otp")}
          />
        </Field>
      ) : (
        <Field
          label="Aadhaar Number"
          className="mt-6"
          error={errors.aadhaar?.message}
        >
          <Input
            onClick={() => handleEntryEvent(CLIENTS_EVENTS.KYC_Aadhaar_Entry)}
            type="text"
            numericOnly={true}
            placeholder="1234 4567 8797"
            {...register("aadhaar", { required: true, maxLength: 12 })}
          />
        </Field>
      )}
      <KYCButton
        isLoading={isLoading}
        text={isVerifyStep ? "Submit OTP" : "Validate Aadhaar"}
      />
      {isVerifyStep && (
        <button
          disabled={!isOtpResend}
          type="button"
          onClick={handleResend}
          className={`${!isOtpResend ? "mx-[27%]" : "mx-[32%]"
            } text-sm px-7 rounded-xl text-black/80  h-10 mt-5 `}
        >
          Resend OTP {!isOtpResend ? `In ${timeForOtpResend}` : ""}
        </button>
      )}
    </form>
  );
};

AadhaarVerification.displayName = "AadhaarVerification";

export default AadhaarVerification;
