import React, { useState } from "react";
import { BENEFITS, FEATURES } from "./constants";
import { Link } from "react-router-dom";
import TapLogo from "../Onboarding/TapLogo";
import HomeBackground from "../../assets/home-background.png";
import LaunchIcon from "@mui/icons-material/Launch";
import Footer from "../../components/Footer";

interface HomeProps {}

const Home: React.FC<HomeProps> = () => {
    const [isUserAuth, setUserAuth] = useState(localStorage.getItem("token"));

    return (
        <>
            {/* Navbar */}
            <div className="flex items-center sticky bg-primary top-0 z-50 py-4 px-4 md:px-8 justify-between">
                <div className="flex items-center gap-x-4">
                    <TapLogo />
                    {/* <div className="ml-4 md:ml-12">
            <a
              href="https://tapinvest.in/"
              target="_blank"
              className="text-slate-600 text-sm font-semibold"
            >
              Tap Invest
            </a>
          </div> */}
                </div>

                <p className="text-sm text-slate-600 font-semibold flex items-center gap-x-4">
                    <a
                        href="https://forms.gle/oH3RvEoeS4KhCfEQ7"
                        target="_blank"
                    >
                        Non Individual Signup
                    </a>
                </p>
            </div>
            <div className="max-w-screen-2xl mx-auto px-4 md:px-8">
                {/* Hero */}
                <div className="max-sm:mb-24 lg:min-h-screen relative">
                    <img
                        src={HomeBackground}
                        alt="Partner App Home"
                        className="absolute hidden lg:block top-12 max-h-screen w-full"
                    />
                    <div className="py-12 sm:py-36 lg:py-40 xl:py-60 z-10 relative">
                        <h3 className="text-center text-xl md:text-2xl xl:text-[40px] font-medium leading-10 md:mb-2 lg:mb-6 tracking-tighter">
                            Home to your
                            <span className="text-green-700 sm:leading-10 block font-semibold text-2xl md:text-3xl xl:text-5xl lg:mt-4">
                                Fixed Income Investments
                            </span>
                        </h3>
                        <p className="text-center text-black/50 text-sm sm:text-base md:text-lg lg:text-2xl">
                            An exclusive platform for Tap Invest Partners
                        </p>
                        <Link to={isUserAuth ? "/deals" : "/login"}>
                            <button
                                type="button"
                                className="bg-green-600 mx-auto mt-8 flex items-center gap-2 border border-green-700 text-white text-sm font-semibold h-10 px-7 rounded-xl"
                            >
                                {isUserAuth
                                    ? "Open your dashboard"
                                    : "Get Started"}{" "}
                                <LaunchIcon fontSize="small" />{" "}
                            </button>
                        </Link>
                    </div>
                </div>
                {/* Stats */}
                <div className="max-w-screen-lg py-6 md:py-12 mx-auto flex flex-col lg:flex-row items-center justify-center gap-12">
                    <h4 className="max-w-[280px] text-center text-black/50">
                        Trusted by the top 1% for investments with fixed returns
                    </h4>
                    <div className="grid grid-cols-3 gap-1 text-black/50 text-center">
                        <div className="flex flex-col gap-8">
                            <div>
                                <h3 className="text-lg sm:text-2xl md:text-[32px] mb-2">
                                    80,000+
                                </h3>
                                <p className="text-xs sm:text-sm md:text-lg">
                                    Assets Managed
                                </p>
                            </div>
                            <div>
                                <h3 className="text-lg sm:text-2xl md:text-[32px] mb-2">
                                    82+
                                </h3>
                                <p className="text-xs sm:text-sm md:text-lg">
                                    Partner Companies
                                </p>
                            </div>
                        </div>
                        <div className="border-x border-x-stone-200 px-4 sm:px-8">
                            <div className="flex flex-col gap-8">
                                <div>
                                    <h3 className="text-lg sm:text-2xl md:text-[32px] mb-2">
                                        2,200+
                                    </h3>
                                    <p className="text-xs sm:text-sm md:text-lg">
                                        Invoices Raised
                                    </p>
                                </div>
                                <div>
                                    <h3 className="text-lg sm:text-2xl md:text-[32px] mb-2">
                                        1,600+
                                    </h3>
                                    <p className="text-xs sm:text-sm md:text-lg">
                                        Deals Closed
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="flex flex-col gap-8">
                                <div>
                                    <h3 className="text-lg sm:text-2xl md:text-[32px] mb-2">
                                        &lt; 0.1%
                                    </h3>
                                    <p className="text-xs sm:text-sm md:text-lg">
                                        Default Rate
                                    </p>
                                </div>
                                <div>
                                    <h3 className="text-lg sm:text-2xl md:text-[32px] mb-2">
                                        350 Cr+
                                    </h3>
                                    <p className="text-xs sm:text-sm md:text-lg">
                                        AUM Raised
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mx-4 py-12 md:py-24 flex justify-center items-center">
                    <div>
                        <h3 className="text-3xl lg:text-5xl font-medium mb-1 lg:mb-3 text-center">
                            Why Tap?
                        </h3>
                        <p className="text-black/50 lg:text-lg text-center">
                            Unlock new avenues for growth and profitability for
                            your clients
                        </p>

                        <div className="grid xl:grid-cols-3 gap-6 md:gap-8 mt-6 md:mt-12">
                            {FEATURES.map((feature) => (
                                <div
                                    key={feature.id}
                                    className="border border-stone-300 rounded-2xl px-4 py-3 md:px-8 md:py-4"
                                >
                                    <div>
                                        <img
                                            src={feature.image}
                                            alt={feature.title}
                                            className="w-full h-full"
                                        />
                                    </div>
                                    <h3 className="text-lg font-semibold mb-3">
                                        {feature.title}
                                    </h3>
                                    <p className="text-sm">
                                        {feature.description}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="mx-4 py-12 md:py-24 flex justify-center items-center">
                    <div>
                        <h3 className="text-3xl lg:text-5xl font-medium mb-1 lg:mb-3 text-center">
                            What's in it for you?
                        </h3>
                        <p className="text-black/50 lg:text-lg text-center">
                            Gain access to benefits designed to enhance your
                            financial growth and professional development.
                        </p>

                        <div className="grid md:grid-cols-2 gap-6 md:gap-8 mt-6 md:mt-12 max-w-screen-xl mx-auto">
                            {BENEFITS.map((benefit) => (
                                <div
                                    key={benefit.id}
                                    className="border border-stone-200 rounded-xl p-4 md:p-8"
                                >
                                    <div>
                                        <img
                                            src={benefit.icon}
                                            alt={benefit.title}
                                            className="w-20 h-20 mb-4"
                                        />
                                    </div>
                                    <h3 className="text-green-950 text-sm mb-2 font-bold">
                                        {benefit.title}
                                    </h3>
                                    <p className="text-stone-500 text-xs">
                                        {benefit.description}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {/* Footer */}
            <Footer />
        </>
    );
};

Home.displayName = "Home";

export default Home;
