import React, { useState } from "react";
import Input from "../../components/Fields/Input";
import { Field } from "../../components/Fields/Field";
import OTPWrapper from "./OTPWrapper";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { NetworkManager } from "../../services";
import { useOnboardingStore } from "./store";
import { OnboardingPageType } from "./types";
import { kycError } from "../../utils/toast";
import KYCButton from "./KYCButton";
import { ONBOARDING_EVENTS } from "./constants";
import toast from "react-hot-toast";
import { useAnalytics } from "../../hooks/useAnalytics";

interface PanVerificationProps { }

const panSchema = yup
  .string()
  .matches(
    /^[a-zA-Z]{3}[abcfghljptfABCFGHLJPTF]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/,
    "Invalid PAN format"
  );

const PanVerification: React.FC<PanVerificationProps> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isVerifyStep, setVerifyStep] = useState(false);
  const { setNextPage } = useOnboardingStore(({ setNextPage }) => ({
    setNextPage,
  }));
  const { track } = useAnalytics();

  const schema = yup.object().shape({
    pan: panSchema,
    panSaved: yup.bool().default(false),
    name: yup.string(),
    panConfirmed: yup.bool().default(false),
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { pan: null },
  });

  const verifyPan = (pan: string) => {
    return NetworkManager.Call({
      method: "post",
      path: NetworkManager.PATH.partnerPanInfo.replace("{{pan}}", pan),
    });
  };

  const confirmPan = (pan: string) => {
    return NetworkManager.Call({
      method: "post",
      path: NetworkManager.PATH.verifyPartnerPanInfo.replace("{{pan}}", pan),
    });
  };

  const onSubmit = async (data: Record<string, any>) => {
    const { pan } = data;

    setIsLoading(true);

    if (isVerifyStep) {
      try {
        const panRes = await confirmPan(pan);
        if (!panRes.error) {
          setNextPage(panRes.nextPage as OnboardingPageType);
          setVerifyStep(false);
          setValue("pan", pan);
        } else {
          kycError("Something went wrong! Please try again");
        }
      } catch (error) {
        if (error?.response?.status?.toString().startsWith("5")) {
          toast.error("Something went wrong! Please try again", {
            duration: 2500,
            position: "top-center",
          });
        } else if (error?.response?.status.toString().startsWith("4")) {
          toast.error(error?.response?.data?.message, {
            duration: 2500,
            position: "top-center",
          });
        } else {
          kycError("Something went wrong! Please try again");
        }
      }
    } else {
      try {
        const panRes = await verifyPan(pan);
        if (!panRes.error) {
          setValue("panSaved", true);
          setValue("name", `${panRes.data.firstName} ${panRes.data.lastName}`);
          setVerifyStep(true);
          handleEntryEvent(ONBOARDING_EVENTS.KYC_PAN_Verify);
        } else {
          kycError("Something went wrong! Please try again");
        }
      } catch (error) {
        if (error?.response?.status?.toString().startsWith("5")) {
          toast.error("Something went wrong! Please try again", {
            duration: 2500,
            position: "top-center",
          });
        } else if (error?.response?.status.toString().startsWith("4")) {
          toast.error(error?.response?.data?.message, {
            duration: 2500,
            position: "top-center",
          });
        } else {
          kycError("Something went wrong! Please try again");
        }
      }
    }

    setIsLoading(false);
  };

  const handleEntryEvent = (event) => {
    track({ eventName: event });
  };

  return (
    <OTPWrapper
      title="Pan Verification"
      verifyTitle="Confirm your identity"
      subtitle="Enter your PAN Card to complete the KYC process with Tap Invest."
      verifySubtitle="Please make sure the name displayed here is the same as on your Aadhaar Card."
      isVerifyStep={isVerifyStep}
      resetVerify={() => {
        setValue("panSaved", false);
        setValue("pan", null);
        setVerifyStep(false);
        setIsLoading(false);
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-8">
        <Field label="PAN Number" error={errors.pan?.message}>
          <Input
            onClick={() => handleEntryEvent(ONBOARDING_EVENTS.KYC_PAN_Entry)}
            type="text"
            placeholder="Enter PAN"
            {...register("pan", {
              onChange: (e) => {
                handleEntryEvent("ONBOARDING_EVENTS.KYC_PAN_Entry");
                setValue("panSaved", false);
                setValue("name", "");
                setValue("pan", e.target.value);
                setIsLoading(false);
                setVerifyStep(false);
              },
            })}
          />
        </Field>
        {isVerifyStep && (
          <Field label="Name as per PAN">
            <div className="capitalize font-medium">
              {getValues("name").toLowerCase()}
            </div>
          </Field>
        )}
        <KYCButton
          isLoading={isLoading}
          text={isVerifyStep ? "Confirm & Proceed" : "Continue"}
        />
      </form>
    </OTPWrapper>
  );
};

PanVerification.displayName = "PanVerification";

export default PanVerification;
