import React from "react";
import { ReactComponent as MultiInvoiceCards } from '../../assets/svg/multi.svg';

interface MultiTagProps { };

const MultiTag: React.FC<MultiTagProps> = () => {
    return (
        <div className="text-green-800 bg-white text-xs font-semibold uppercase flex items-center gap-2 border border-stone-200 rounded py-1 px-2.5">
            <MultiInvoiceCards />
            Multi
        </div>
    );
};

MultiTag.displayName = "MultiTag";

export default MultiTag;