import * as React from 'react';
import Tables from '../../components/organisms/Tables/Tables';
import { getRowsAndCols } from './utils';
import { SORTABLE_COLUMNS } from './constants';

const columns = [
    { id: 'dealName', label: 'Deal Name', minWidth: 170, align: 'left' },
    {
        id: 'investedOn',
        label: 'Invt. Date',
        minWidth: 170,
        align: 'left',
    },
    {
        id: 'amount',
        label: 'Invt. Amount',
        minWidth: 170,
        align: 'left',
    },
    {
        id: 'maturityDate',
        label: 'Payout Date',
        minWidth: 100,
        align: 'left',
    },
    {
        id: 'irr',
        label: 'IRR',
        minWidth: 100,
        align: 'left',
    },
    {
        id: 'payoutAmount',
        label: 'Payout Amount',
        minWidth: 170,
        align: 'left',
    },
];

interface ClientInvestmentsTableProps {
    data: {
        currentPage: number;
        items: any[];
        pageSize: number;
        totalItems: 0;
    }
    pageSize: number;
    pageNo: number;
    orderBy: string,
    order: "asc" | "desc",
    handleChangePage: (event: any, newPage: number) => void;
    handleChangeRowsPerPage: (event: any) => void;
    handleChangeSort: (event: any, columnId: string) => void;
};


const ClientInvestmentsTable: React.FC<ClientInvestmentsTableProps> = ({
    data,
    pageSize,
    pageNo,
    orderBy,
    order,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangeSort
}) => {
    const row = getRowsAndCols(data);
    const totalData = data?.totalItems;
    return (
        <Tables
            totalData={totalData}
            columns={columns}
            rows={row}
            sortableColumns={SORTABLE_COLUMNS}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangeSort={handleChangeSort}
            handleRowClick={() => { }}
            rowsPerPage={pageSize}
            page={pageNo}
            orderBy={orderBy}
            order={order}
        />
    );
}

ClientInvestmentsTable.displayName = "ClientInvestmentsTable";

export default ClientInvestmentsTable;
