import React, { useMemo, useState } from "react";
import { useInvestments, useInvestmentsSummary } from "./useInvestments";
import InvestmentTable from "../../features/investments/InvestmentTable";
import { MONTHS } from "./constants";
import { numDifferentiation } from "../../utils/numbers";
import { useDelay } from "../../hooks/useDelay";
import TableSkeleton from "../../components/TableSkeleton";
import Input from "../../components/Fields/Input";
import { SearchOutlined } from "@mui/icons-material";
import { debounce } from "../../utils/debounce";
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import { NetworkManager } from "../../services";
import { useDownloadCsv } from "../../hooks/useDownloadCsv";
import ReinvestModal from "../../components/organisms/ReinvestModal/ReinvestModal";

interface InvestmentsProps { };

const Investments: React.FC<InvestmentsProps> = () => {
    const [pageSize, setPageSize] = useState(10);
    const [pageNo, setPageNo] = useState(0);
    const [orderBy, setOrderBy] = useState("createdAt");
    const [order, setOrder] = useState<"asc" | "desc">("desc");
    const [search, setSearch] = useState("");

    const handleChangePage = (event, newPage) => {
        setPageNo(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setPageSize(parseInt(event.target.value, 10));
        setPageNo(0);
    };

    const handleChangeSort = (e, columnId) => {
        if (columnId == orderBy) {
            setOrder(order == "desc" ? "asc" : "desc");
        }
        setOrderBy(columnId);
    }



    const { data, isLoading } = useInvestments({
        payload: {
            pageNo,
            pageSize,
            orderBy,
            order,
            search,
            
        },
    });

    const {
        csvLoading,
        downloadCsv
    } = useDownloadCsv({path: NetworkManager.PATH.csvPartnerInvestment, filename: "investment.csv"})


    const isInvestmentsReady = useDelay(isLoading, 500);


    const { data: summaryData, isLoading: isSummaryLoading } =
        useInvestmentsSummary();

    const { totalEarned, totalInvested, currentMonthEarnedIncome, month } = summaryData?.data || {};

    const currentMonth = useMemo(() => MONTHS[month - 1], [month]);

    const handleSearch = (e) => {
        setSearch(e.target.value);
    }

    const debounceHandleSearch = debounce(handleSearch, 500);


    return (
        <div className="my-6 flex flex-col max-w-7xl mx-auto">
            <div className="grid grid-cols-2 xl:grid-cols-3 border border-stone-300 rounded-lg bg-black/[.02]">
                <div className="p-4 md:p-6 border-r border-r-stone-300 max-xl:border-b max-xl:border-b-stone-300">
                    <h3 className="text-stone-700 font-semibold text-xs uppercase mb-1">Total Invested</h3>
                    <p className="text-2xl font-medium text-stone-700">{numDifferentiation(totalInvested || 0)}</p>
                </div>
                <div className="p-4 md:p-6 xl:border-r xl:border-r-stone-300 max-xl:border-b max-xl:border-b-stone-300">
                    <h3 className="text-stone-700 font-semibold text-xs uppercase mb-1">Total Earnings</h3>
                    <p className="text-2xl font-medium text-stone-700">{numDifferentiation(totalEarned || 0)}</p>
                </div>
                <div className="p-4 md:p-6 border-r border-r-stone-300">
                    <h3 className="text-stone-700 font-semibold text-xs uppercase mb-1">{currentMonth} Earnings</h3>
                    <p className="text-2xl font-medium text-stone-700">{numDifferentiation(currentMonthEarnedIncome || 0)}</p>
                </div>
            </div>

            <div className="flex flex-col justify-start gap-3 items-start md:flex-row md:justify-between md:items-center mt-6 mb-4">
                <Input
                    prefixIcon={<SearchOutlined />}
                    placeholder="Search"
                    onChange={debounceHandleSearch}
                    className="ps-11"
                />
                <button
                    onClick={downloadCsv}
                    disabled={csvLoading}
                    type="button"
                    className="bg-white shadow border border-slate-300 flex items-center gap-2 text-sm  text-gray-800 rounded-lg font-semibold h-10 w-fit px-4"
                >
                    <DownloadForOfflineOutlinedIcon fontSize="small" />
                    Download Excel
                </button>
            </div>

            {/* Investments Table */}
            {isInvestmentsReady && !isLoading ?
                <InvestmentTable
                    data={data}
                    pageSize={pageSize}
                    pageNo={pageNo}
                    orderBy={orderBy}
                    order={order}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    handleChangeSort={handleChangeSort}
                />
                :
                <TableSkeleton />
            }            
            <ReinvestModal />
        </div>
    );
};

Investments.displayName = "Investments";

export default Investments;