// src/components/PersonalDetails.tsx
import React from "react";
import { UserPaymentPersonalDetails } from "../types";
import { Skeleton } from "@mui/material";

interface PersonalDetailsProps extends UserPaymentPersonalDetails {
  loading: boolean;
}

const PersonalDetails: React.FC<PersonalDetailsProps> = ({ data, loading }) => {
  return loading ? (
    <div
      className="p-5 pb-3 flex flex-col gap-3"
      style={{ minWidth: "400px", maxWidth: "600px" }}
    >
      <h1 className="text-xl font-semibold text-slate-800">Personal Details</h1>
      <div className="flex flex-col gap-3">
        <div className="border border-x-0 border-t-0 p-[10px] flex flex-col gap-[6px]">
          <Skeleton variant="text" width={100} height={20} />
          <Skeleton variant="text" width={200} height={20} />
        </div>
        <div className="border border-x-0 border-t-0 p-3 flex flex-col gap-[6px]">
          <Skeleton variant="text" width={120} height={20} />
          <Skeleton variant="text" width={200} height={20} />
        </div>
        <div className="border border-x-0 border-t-0 p-[10px] flex flex-col gap-[6px]">
          <Skeleton variant="text" width={100} height={20} />
          <Skeleton variant="text" width={200} height={20} />
        </div>
      </div>
    </div>
  ) : (
    <div className="p-5 pb-3 flex flex-col gap-3" style={{ minWidth: "400px" }}>
      <h1 className="text-xl font-semibold text-slate-800">Personal Details</h1>
      <div className="flex flex-col gap-3">
        <div className=" p-[10px] flex flex-col gap-[6px]">
          <h3 className="block text-sm font-medium text-black">Name</h3>
          <h3>{data?.name}</h3>
        </div>
        <div className="border border-x-0 p-3 flex flex-col gap-[6px]">
          <h3 className="block text-sm font-medium text-black">
            Email address
          </h3>
          <h3 className="text-slate-600">{data?.email}</h3>
        </div>
        <div className="border border-x-0 border-t-0 p-[10px] flex flex-col gap-[6px]">
          <h3 className="block text-sm font-medium text-black">Phone number</h3>
          <h3>{data?.phone}</h3>
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails;
