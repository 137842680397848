import React, { useMemo } from 'react';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { toast } from 'react-hot-toast';
import useGetPartnerProfile from '../../hooks/useGetPartnerProfile';
import Skeleton from '@mui/material/Skeleton';

interface ReferAndEarnProps {}

const copiedNotify = () =>
  toast.success('Copied', {
    duration: 2500,
    position: 'top-center',
  });

const ReferAndEarn: React.FC<ReferAndEarnProps> = () => {
  const { data, isLoading } = useGetPartnerProfile();

  const { referralCodes } = data?.data || {};

  const latestReferral = useMemo(() => {
    return referralCodes?.[referralCodes?.length - 1];
  }, [referralCodes]);

  function copyToClipboard(text: string): void {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        copiedNotify();
      })
      .catch((error) => {
        console.error('Error copying text to clipboard:', error);
      });
  }

  return (
    <div className="sticky min-h-80 top-24 p-5 border bg-white border-zinc-200 rounded-lg">
      {isLoading ? (
        <Skeleton width={80} height={40} />
      ) : (
        <h3 className="text-lg font-semibold mb-2">Refer and Earn More</h3>
      )}
      {isLoading ? (
        <Skeleton width="100%" height={40} />
      ) : (
        <p className="text-slate-500 text-sm">Share the link below with clients and earn</p>
      )}
      {!isLoading ? (
        <div className="mt-8 flex flex-col gap-5">
          {/* Invite Code */}
          <div>
            <label htmlFor="inviteCode" className="text-gray-700 font-medium text-xs">
              Share invite code
            </label>
            <div className="border flex border-gray-300 rounded-lg overflow-hidden shadow-sm">
              <input
                type="text"
                readOnly
                value={latestReferral?.referralCode}
                name="inviteCode"
                className="p-2.5 flex-1 focus:outline-none text-slate-800 text-sm font-semibold"
              />
              <button
                onClick={() => copyToClipboard(latestReferral?.referralCode)}
                type="button"
                className="bg-gradient-to-b from-white via-black/0 to-black/[0.04] border-l border-l-gray-300 px-5 text-slate-600"
              >
                <FileCopyIcon fontSize="small" />
              </button>
            </div>
          </div>

          {/* Client Invite Link */}
          <div>
            <label htmlFor="inviteLink" className="text-gray-700 font-medium text-xs">
              Share invite link to your <span className="font-bold">client</span>
            </label>
            <div className="relative flex border border-gray-300 rounded-lg overflow-hidden shadow-sm">
              <input
                type="text"
                readOnly
                value="refer.tapinvest.in/"
                name="inviteLink"
                className="p-2.5 flex-1 focus:outline-none text-slate-400 text-sm font-semibold"
              />
              <p className="text-slate-800 absolute left-[130px] top-2.5 bottom-0 text-sm font-semibold">
                {latestReferral?.referralCode}
              </p>
              <button
                onClick={() =>
                  copyToClipboard(`https://refer.tapinvest.in/${latestReferral?.referralCode}`)
                }
                type="button"
                className="bg-gradient-to-b from-white via-black/0 to-black/[0.04] border-l border-l-gray-300 px-5 text-slate-600"
              >
                <FileCopyIcon fontSize="small" />
              </button>
            </div>
          </div>

          {/* Partner Invite Link */}
          <div>
            <label htmlFor="inviteLink" className="text-gray-700 font-medium text-xs">
              Share invite link to your <span className="font-bold">partner</span>
            </label>
            <div className="relative flex border border-gray-300 rounded-lg overflow-hidden shadow-sm">
              <input
                type="text"
                readOnly
                value="partner.tapinvest.in/"
                name="inviteLink"
                className="p-2.5 flex-1 focus:outline-none text-slate-400 text-sm font-semibold"
              />
              <p className="text-slate-800 absolute left-[148px] top-2.5 bottom-0 text-sm font-semibold">
                {latestReferral?.referralCode}
              </p>
              <button
                onClick={() =>
                  copyToClipboard(
                    `${window.location.origin}/login?refCode=${latestReferral?.referralCode}`,
                  )
                }
                type="button"
                className="bg-gradient-to-b from-white via-black/0 to-black/[0.04] border-l border-l-gray-300 px-5 text-slate-600"
              >
                <FileCopyIcon fontSize="small" />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-6">
          <Skeleton width="100%" height={60} />
          <Skeleton width="100%" height={60} />
        </div>
      )}
    </div>
  );
};

ReferAndEarn.displayName = 'ReferAndEarn';

export default ReferAndEarn;
