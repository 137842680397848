import { useQuery } from "../../services/NetworkManager";
import { NetworkManager } from "../../services";

export function getAllPartners({ queryKey }) {
    const [_, id, pageNo, pageSize, orderBy, order, search] =
        queryKey[0].split(",");

    return NetworkManager.Call({
        method: "post",
        path: NetworkManager.PATH.partners,
        data: {
            currentPage: pageNo,
            pageSize,
            sortBy: orderBy,
            sortDir: order.toUpperCase(),
            searchPhrase: search,
            searchOn: [
                "firstName",
                "lastName",
                "email",
                "phone",
                "pan",
                "panName",
            ],
            filters: [
                {
                    field: "masterPartnerId",
                    operation: "EQUALS",
                    value: id,
                },
                {
                    field: "masterPartnerUnlinkedOn",
                    operation: "IS_NULL",
                },
            ],
        },
    });
}

export function getPartnersSummary() {
    return NetworkManager.Call({
        method: "get",
        path: NetworkManager.PATH.partnersSummaryData,
    });
}

export function getClientKycStatus({ queryKey }) {
    const id = queryKey[0].split(",")[1];

    return NetworkManager.Call({
        method: "get",
        path: NetworkManager.PATH.clientKycStatus.replace("{{clientId}}", id),
    });
}

export function getSubPartnerInvestments({ queryKey }) {
    const [_, pageNo, pageSize, orderBy, order, search] =
        queryKey[0].split(",");
    return NetworkManager.Call({
        method: "post",
        path: NetworkManager.PATH.subPartnerInvestments,
        data: {
            currentPage: pageNo,
            pageSize,
            // sortBy: orderBy,
            // sortDir: order.toUpperCase(),
            sortBy: "id",
            sortDir: "DESC",
            searchPhrase: search,
            // searchOn: ["firstName", "lastName", "email", "phone", "pan"],
        },
    });
}

export function usePartners({ payload }) {
    const { id, pageNo, pageSize, orderBy, order, search } = payload;
    const { data, isLoading } = useQuery(
        `${["partners", id, pageNo, pageSize, orderBy, order, search]}`,
        getAllPartners,
        { enabled: !!id }
    );
    return { data, isLoading };
}

export function usePartnersSummary() {
    const { data, isLoading } = useQuery("partnersSummary", getPartnersSummary);
    return { data, isLoading };
}

export function useClientKycStatus(clientId?: string) {
    const { data, isLoading } = useQuery(
        `${["clientKycStatus", clientId]}`,
        getClientKycStatus,
        { enabled: !!clientId }
    );
    return { data, isLoading };
}

export function useSubPartnerInvestments({
    pageNo,
    pageSize,
    orderBy,
    order,
    search,
}) {
    const { data, isLoading } = useQuery(
        `${[
            "subPartnerInvestments",
            pageNo,
            pageSize,
            orderBy,
            order,
            search,
        ]}`,
        getSubPartnerInvestments
    );
    return { data, isLoading };
}
