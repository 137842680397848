import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import * as XLSX from 'xlsx';
import { Link, useParams } from 'react-router-dom';
import SelectClient from '../DealDetail/SelectClient';
import KYCButton from '../Onboarding/KYCButton';
import Image from '../../assets/Image';
import CMRUpload from './CMRUpload';
import toast from 'react-hot-toast';
import BondFaqs from './BondFaqs';
import { CloseOutlinedIcon, InfoIcon } from '../../assets/mui';
import { Dialog, DialogContent, DialogTitle, Input, TextField, Typography } from '@mui/material';
import { ArrowBack, CalendarToday, PhoneInTalk } from '@mui/icons-material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {
  generatePaymentLink,
  useBondCashflow,
  useBondDealDetails,
  useBondInvestmentTerms,
  useCmrStatus,
  usePartnerConsiderationAmount,
} from './useBondDealDetails';
import { getRupeeWithDecimal, twoDecimalNumber } from '../../utils/numbers';
import { DealDocumentType } from '../Deals/types';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import Checkbox from '../../components/Fields/Checkbox';
import { formatDate } from '../../utils/date';
import { BONDS_EVENTS } from './constants';
import { useAnalytics } from '../../hooks/useAnalytics';
import useGetPartnerProfile from '../../hooks/useGetPartnerProfile';
import ClientDrawer from '../Clients/ClientDrawer/ClientDrawer';

interface BondsDealDetailProps {}

const BondsDealDetail: React.FC<BondsDealDetailProps> = () => {
  const [units, setUnits] = useState(1);
  const [isCallbackOpen, setIsCallbackOpen] = useState(false);
  const [showCMRModal, setShowCMRModal] = useState(false);
  const [isPaymentOpen, setIsPaymentOpen] = useState(false);
  const [userPaymentLink, setUserPaymentLink] = useState<string | null>(null);
  const [userPaymentLinkLoading, setUserPaymentLinkLoading] = useState(false);
  const [client, setClient] = useState<any>(null);
  const [acceptedTerms, setAcceptedTerms] = useState(true);
  const [isMaxAmountExceeded, setIsMaxAmountExceeded] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [yieldError, setYieldError] = useState(false);
  const [downloadButtonLoading, setDownloadButtonLoading] = useState(false);
  const { dealId } = useParams();
  const clientId = client?.clientPublicId;

  const { data: dealDetails, isLoading: isDealLoading } = useBondDealDetails(dealId);
  const { name, couponRate, maturityDate, tenure, tenurePeriod, company, meta, minQty, ytm } =
    dealDetails?.data || {};

  const {
    yield: yieldRate,
    qty,
    amount: initialAmount,
    returnAmount: initialReturnAmount,
  } = minQty || {};

  const [customYield, setCustomYield] = useState(ytm || yieldRate);

  const { data: investmentTerms, isLoading: isInvestmentTermsLoading } = useBondInvestmentTerms(
    dealId,
    units,
    clientId,
    customYield || ytm,
  );
  const { data: cashFlow, isLoading: isBondCashflowLoading } = useBondCashflow(dealId, units);

  const {
    data: cmrStatus,
    isLoading: isCmrStatusLoading,
    refetch: refetchCmrStatus,
  } = useCmrStatus(clientId);

  const { track } = useAnalytics();

  const { data: partner } = useGetPartnerProfile();
  const partnerId = partner?.data?.profile?.publicId;

  const { logoUrl, brandName } = company || {};
  const {
    about,
    isin,
    interest,
    principle,
    rating,
    ratingAgency,
    ownership,
    trusteeName,
    tags,
    documents,
    yieldToPartner,
  } = meta || {};

  const { dpId, status } = cmrStatus?.data || {};

  const {
    settlementDate,
    returnAmount,
    amount,
    paymentOptions,
    minYield,
    maxYield,
    unitConsiderationAmount: perUnitAmount,
  } = investmentTerms?.data || {};

  const hasDocuments = !!documents && documents.length > 0;

  const { data: partnerAmount, isLoading: isPartnerYieldLoading } = usePartnerConsiderationAmount(
    isin,
    maxYield,
    units,
  );

  const handlePaymentClose = () => {
    setIsPaymentOpen(false);
    setUserPaymentLink(null);
  };

  const handleCRMModalClose = () => {
    setShowCMRModal(false);
  };

  const handleYieldAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const regex = /^[0-9]*\.?[0-9]{0,2}%?$/;
    if (regex.test(value) || value === '') {
      setYieldError(false);
      setCustomYield(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    if (isMaxAmountExceeded) {
      const investmentAmount = !isInvestmentTermsLoading && !!amount ? amount : initialAmount || 0;

      track({
        eventName: BONDS_EVENTS.Bonds_Request_Callback,
        eventProperties: {
          partner_id: partnerId,
          user_id: clientId,
          investment_amount: investmentAmount,
          pan_name: client?.pan,
          primary_phone: client?.phone,
          primary_email: client?.email,
          partner_name: `${partner?.data?.profile?.firstName} ${partner?.data?.profile?.lastName}`,
          created_at: new Date().toString(),
        },
      });
      setIsCallbackOpen(true);
      return;
    }

    if (status === 'VERIFICATION_IN_PROGRESS' || status === 'VERIFIED' || status === 'APPROVED') {
      const partnerCommissionAmount =
        !isPartnerYieldLoading && !!partnerAmount?.data && !!amount && amount - partnerAmount?.data;

      track({ eventName: BONDS_EVENTS.Bonds_Generate_Payment_Link });
      setUserPaymentLinkLoading(true);
      generatePaymentLink({
        unitConsiderationAmount: perUnitAmount,
        qty: units,
        dealId,
        dealName: name,
        maturityDate,
        amount,
        returnAmount,
        settlementDate,
        clientPublicId: clientId,
        partnerCommissionAmount,
        yieldOfInvestment: customYield,
        partnerYield: maxYield,
      })
        .then((res) => {
          setUserPaymentLink(res.data);
          setIsPaymentOpen(true);
        })
        .catch((err) => {
          toast.error(err?.response?.data.message || 'Unable to generate link, try again later', {
            duration: 2500,
            position: 'top-center',
          });
        })
        .finally(() => {
          setButtonDisabled(false);
          setUserPaymentLinkLoading(false);
        });
    } else {
      setShowCMRModal(true);
    }
  };

  useEffect(() => {
    if (!isDealLoading && !!qty) {
      setUnits(qty);
    }
  }, [isDealLoading]);

  useEffect(() => {
    if (amount > 200000) {
      setIsMaxAmountExceeded(true);
    } else {
      setIsMaxAmountExceeded(false);
    }
  }, [amount, paymentOptions]);

  const KYCButtonComponent = ({ isMaxAmountExceeded, status, acceptedTerms, clientId }) => {
    if (isMaxAmountExceeded) {
      return (
        <KYCButton
          text="Request Callback"
          className="!mt-0 h-10 !py-0 shadow"
          isLoading={false}
          disabled={!client}
        />
      );
    }

    if (status === 'PENDING' || status === 'REJECTED' || status === 'DELETED') {
      return (
        <KYCButton
          text="Unlock Bonds"
          isLoading={false}
          disabled={!acceptedTerms}
          className="!mt-0 h-10 !py-0 shadow"
        />
      );
    }

    return (
      <KYCButton
        text="Generate Payment Link"
        className="!mt-0 h-10 !py-0 shadow"
        isLoading={userPaymentLinkLoading}
        disabled={!acceptedTerms || !clientId || buttonDisabled || yieldError}
      />
    );
  };

  const handleInputChange = (e: any) => {
    const value = e.target.value;

    if (value === '') {
      return;
    }

    track({ eventName: BONDS_EVENTS.Bonds_Enter_Units });

    const parsedValue = parseInt(value, 10);

    // Check if parsedValue is a number
    if (isNaN(parsedValue)) {
      return;
    }

    const minQty = investmentTerms?.data?.minInvestment?.qty;
    const maxQty = investmentTerms?.data?.maxInvestment?.qty;

    if (parsedValue < minQty) {
      toast.error(`Minimum qty is ${minQty}`);
    } else if (parsedValue > maxQty) {
      toast.error(`Maximum qty is ${maxQty}`);
    } else {
      setUnits(parsedValue);
    }
  };

  const downloadPayoutSchedule = () => {
    if (!cashFlow?.data || cashFlow?.data.length === 0) {
      toast.error('No data available to download', { duration: 5000, position: 'top-center' });
      return;
    }

    setDownloadButtonLoading(true);
    try {
      const worksheet = XLSX.utils.json_to_sheet(
        cashFlow?.data.map((payout) => {
          return {
            Date: payout?.cashFlowDate,
            Principal: payout?.principalAmount?.toFixed(2),
            Interest: payout?.interestAmount?.toFixed(2),
            Cashflow: payout?.cashFlowAmount?.toFixed(2),
          };
        }),
      );
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const workbookBinary = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      const blob = new Blob([workbookBinary], { type: 'application/octet-stream' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${name || brandName} payouts.xlsx`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      toast.success('Payouts saved to Downloads.', {
        duration: 5000,
        position: 'top-center',
      });
    } catch (err) {
      toast.error('An error occurred while generating the CSV file', {
        duration: 5000,
        position: 'top-center',
      });
      console.error('CSV download error:', err);
    } finally {
      setDownloadButtonLoading(false);
    }
  };

  return (
    <div className="my-6 max-w-7xl mx-auto">
      <div className="grid grid-cols-2 xl:grid-cols-3 gap-8 xl:gap-16">
        <div className="col-span-2">
          <Link
            to="/deals"
            className="bg-green-700/10 flex items-center justify-center mb-6 p-1.5 h-9 w-9 rounded-full text-green-700"
          >
            <ArrowBack fontSize="small" />
          </Link>
          {/* Heading */}
          <div>
            <div className="w-20 h-20 overflow-hidden rounded bg-zinc-200 mb-6">
              {logoUrl && (
                <Image
                  // @ts-ignore
                  src={logoUrl}
                  alt={brandName}
                  className="w-full h-full"
                />
              )}
            </div>
            <div className="flex gap-3 items-center mb-4">
              <h1 className="text-xl font-semibold text-slate-800">{name || brandName}</h1>
              {tags && (
                <div className="uppercase text-green-800 bg-green-800/15 px-2.5 py-1.5 rounded text-[8px] font-semibold">
                  {tags}
                </div>
              )}
              {/* <div className="uppercase text-green-800 text-[8px] font-semibold rounded px-2.5 py-1.5 bg-white border border-stone-200">
                                Senior Secured Bonds
                            </div> */}
            </div>
            <p className="text-stone-500">ISIN: {isin}</p>
            <p className="text-stone-500 max-w-2xl mb-4 mt-2">{about}</p>
            {/* Company details */}

            <div className="flex flex-col gap-6 mt-6 lg:mt-9">
              {/* Stats */}
              <div className="flex mt-auto w-full min-h-0 flex-col bg-black/[.02] rounded-lg border border-stone-300">
                <div className="grid grid-cols-2 xl:grid-cols-4 border-b border-b-stone-300">
                  <div className="p-4 md:p-6 border-r border-r-stone-300 max-xl:border-b max-xl:border-b-stone-300">
                    <h3 className="text-black/40 font-semibold decoration-dashed decoration-2 decoration-stone-300 underline pb-1 text-xs uppercase mb-1">
                      Yield
                    </h3>
                    <p className="text-2xl font-medium text-stone-700">
                      {twoDecimalNumber(yieldRate)}%
                    </p>
                  </div>
                  <div className="p-4 md:p-6 border-r border-r-stone-300 max-xl:border-b max-xl:border-b-stone-300">
                    <h3 className="text-black/40 font-semibold decoration-dashed decoration-2 decoration-stone-300 underline pb-1 text-xs uppercase mb-1">
                      Coupon Rate
                    </h3>
                    <p className="text-2xl font-medium text-stone-700">{couponRate}</p>
                  </div>
                  <div className="p-4 md:p-6 border-r border-r-stone-300">
                    <h3 className="text-black/40 font-semibold decoration-dashed decoration-2 decoration-stone-300 underline pb-1 text-xs uppercase mb-1">
                      Tenure
                    </h3>
                    <p className="text-2xl font-medium text-stone-700">
                      {tenure} {tenurePeriod}
                    </p>
                  </div>
                  <div className="p-4 md:p-6">
                    <h3 className="text-black/40 font-semibold decoration-dashed decoration-2 decoration-stone-300 underline pb-1 text-xs uppercase mb-1">
                      Rating
                    </h3>
                    <p className="text-2xl font-medium text-stone-700">{rating}</p>
                  </div>
                </div>

                <div className="grid grid-cols-2 xl:grid-cols-4 border-b border-b-stone-300">
                  <div className="p-4 md:p-6 border-r border-r-stone-300 max-xl:border-b max-xl:border-b-stone-300">
                    <h3 className="text-black/40 font-semibold decoration-dashed decoration-2 decoration-stone-300 underline pb-1 text-xs uppercase mb-1">
                      Trustee Name
                    </h3>
                    <p className="text-2xl font-medium text-stone-700">{trusteeName}</p>
                  </div>
                  <div className="p-4 md:p-6 border-r border-r-stone-300 max-xl:border-b max-xl:border-b-stone-300">
                    <h3 className="text-black/40 font-semibold decoration-dashed decoration-2 decoration-stone-300 underline pb-1 text-xs uppercase mb-1">
                      Ownership
                    </h3>
                    <p className="text-2xl font-medium text-stone-700">{ownership}</p>
                  </div>
                  <div className="p-4 md:p-6 border-r border-r-stone-300">
                    <h3 className="text-black/40 font-semibold decoration-dashed decoration-2 decoration-stone-300 underline pb-1 text-xs uppercase mb-1">
                      Maturity Date
                    </h3>
                    <p className="text-2xl font-medium text-stone-700">{maturityDate}</p>
                  </div>
                  <div className="p-4 md:p-6">
                    <h3 className="text-black/40 font-semibold decoration-dashed decoration-2 decoration-stone-300 underline pb-1 text-xs uppercase mb-1">
                      Rating Agency
                    </h3>
                    <p className="text-2xl font-medium text-stone-700">{ratingAgency}</p>
                  </div>
                </div>
              </div>

              {/* Interest / Principle */}
              <div className="flex mt-auto w-full min-h-0 flex-col bg-black/[.02] rounded-lg border border-stone-300">
                <div className="grid grid-cols-2 md:col-span-1">
                  <div className="p-4 md:p-6 border-r border-r-stone-300">
                    <h3 className="text-black/40 font-semibold decoration-dashed decoration-2 decoration-stone-300 underline pb-1 text-xs uppercase mb-4">
                      Interest
                    </h3>
                    <div className="h-8 mix-blend-darken max-w-[300px] text-2xl font-medium text-stone-700">
                      {interest}
                    </div>
                  </div>
                  <div className="p-4 md:p-6">
                    <h3 className="text-black/40 font-semibold decoration-dashed decoration-2 decoration-stone-300 underline pb-1 text-xs uppercase mb-4">
                      Principle
                    </h3>
                    <div className="h-8 mix-blend-darken max-w-[300px] text-2xl font-medium text-stone-700">
                      {principle}
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="flex justify-between items-center mb-3">
                  <h3 className="text-stone-700 text-lg font-medium underline underline-offset-4 cursor-pointer pb-1 decoration-dashed decoration-2 decoration-stone-300">
                    Payout Schedule
                  </h3>

                  <button
                    className="text-[#475467] flex justify-center items-center bg-white font-semibold text-[12px] border border-gray-300 h-9 w-44 rounded-lg shadow-sm px-[10px] py-[4px] normal-case"
                    onClick={downloadPayoutSchedule}
                    disabled={downloadButtonLoading}
                  >
                    <FileDownloadOutlinedIcon
                      sx={{ width: '14px', height: '14px', marginRight: '4px' }}
                    />{' '}
                    {downloadButtonLoading ? 'Downloading...' : ' Download Schedule'}
                  </button>
                </div>

                <div className="grid grid-cols-4 overflow-hidden bg-black/5 text-slate-700 border border-stone-300 rounded-md">
                  <div className="flex items-center justify-center p-3 font-semibold border-r border-r-stone-200">
                    Repayment Date
                  </div>
                  <div className="flex flex-col items-center justify-center p-3 font-semibold border-r border-r-stone-200">
                    Principle
                    <span className="text-xs font-medium">A</span>
                  </div>
                  <div className="flex flex-col items-center justify-center p-3 font-semibold border-r border-r-stone-200">
                    Interest <span className="text-xs font-medium">B</span>
                  </div>
                  <div className="flex flex-col items-center justify-center p-3 font-bold">
                    Total <span className="text-xs font-medium">A+B</span>
                  </div>

                  <div className="grid grid-cols-4 col-span-4 bg-primary">
                    <div className="flex items-center justify-center py-6 text-gray-700 font-medium border-b border-b-stone-200 border-r border-r-stone-200">
                      {settlementDate ? formatDate(settlementDate) : '-'}
                    </div>
                    <div className="flex items-center justify-center py-6 text-gray-500 font-medium border-b border-b-stone-200 border-r border-r-stone-200">
                      -
                    </div>
                    <div className="flex items-center justify-center py-6 text-gray-500 font-medium border-b border-b-stone-200 border-r border-r-stone-200">
                      -
                    </div>
                    <div className="flex items-center justify-center py-6 font-semibold border-b border-b-stone-200 bg-black/5">
                      {!isInvestmentTermsLoading && !!amount && getRupeeWithDecimal.format(amount)}
                      {!amount && !!initialAmount && getRupeeWithDecimal.format(initialAmount)}
                    </div>
                  </div>

                  {isBondCashflowLoading ? (
                    <></>
                  ) : (
                    cashFlow?.data?.map((cashFlowCell) => (
                      <div
                        key={`${cashFlowCell.cashFlowDate}-${cashFlowCell.cashFlowAmount}`}
                        className="grid grid-cols-4 col-span-4 bg-primary"
                      >
                        <div className="flex items-center justify-center py-6 text-gray-700 font-medium border-b border-b-stone-200  border-r border-r-stone-200">
                          {formatDate(cashFlowCell.cashFlowDate)}
                        </div>
                        <div className="flex items-center justify-center py-6 text-gray-500 font-medium border-b border-b-stone-200  border-r border-r-stone-200">
                          {getRupeeWithDecimal.format(cashFlowCell.principalAmount)}
                        </div>
                        <div className="flex items-center justify-center py-6 text-gray-500 font-medium border-b border-b-stone-200  border-r border-r-stone-200">
                          {getRupeeWithDecimal.format(cashFlowCell.interestAmount)}
                        </div>
                        <div className="flex items-center justify-center py-6 font-semibold border-b border-b-stone-200 bg-black/5">
                          {getRupeeWithDecimal.format(cashFlowCell.cashFlowAmount)}
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>

              <div>
                <h3 className="text-stone-700 text-lg font-medium mb-3">
                  Settlement Time & Cycles
                </h3>
                <div className="border border-stone-200 rounded-lg bg-white/80 py-5 px-4 md:px-6 max-w-screen-sm">
                  <div className="grid grid-cols-2 border-b py-1.5 border-b-stone-200">
                    <h4 className="text-xs text-stone-600">Time</h4>
                    <h4 className="text-xs text-stone-600">Settlement</h4>
                  </div>

                  <div className="grid grid-cols-2 py-2 border-b border-b-black/10 border-dashed">
                    <p className="text-stone-950/70 text-xs">12 AM - 2PM</p>
                    <p className="text-stone-950/70 text-xs">Same day settlement</p>
                  </div>

                  <div className="grid grid-cols-2 py-2 border-b border-b-black/10 border-dashed">
                    <p className="text-stone-950/70 text-xs">Post 2PM</p>
                    <p className="text-stone-950/70 text-xs">Next working day</p>
                  </div>

                  <div className="grid grid-cols-2 py-2">
                    <p className="text-stone-950/70 text-xs">Holidays & Weekends</p>
                    <p className="text-stone-950/70 text-xs">Next working day</p>
                  </div>
                </div>
              </div>

              {/* Documents */}
              {hasDocuments && (
                <div>
                  <h3 className="text-stone-700 text-lg font-medium mb-3">Documents</h3>

                  {/* Downloadable files */}
                  <div className="flex flex-wrap gap-6">
                    {documents &&
                      documents.map((document: DealDocumentType) => (
                        <a href={document.link} target="_blank" download>
                          <div
                            key={document.name}
                            className="border border-stone-200 md:w-96 rounded-lg py-5 px-4 flex justify-between items-center"
                          >
                            <div>
                              <h4 className="text-stone-700 font-medium text-sm">
                                {document.name}
                              </h4>
                              <p className="text-stone-500 text-sm">{document.text}</p>
                            </div>
                            <DownloadForOfflineOutlinedIcon className="text-stone-500" />
                          </div>
                        </a>
                      ))}
                  </div>
                </div>
              )}

              {/* FAQs */}
              <div>
                <h3 className="text-stone-700 text-lg font-medium mb-3">
                  Frequently Asked Questions
                </h3>
                <BondFaqs />
              </div>
            </div>
          </div>
        </div>

        <form className="lg:mt-12 max-lg:col-span-2" onSubmit={handleSubmit}>
          <div className="flex flex-col bg-black/[.02] rounded-lg border border-stone-300 overflow-hidden">
            <div>
              {/* Bonds Unit Input */}
              <div className="border-b relative border-b-stone-300 text-slate-600 h-24">
                <div className="absolute z-10 top-4 left-3 right-3 flex items-center justify-between tracking-wider uppercase text-xs font-semibold text-black/40">
                  <label className="">Units</label>
                  <p>₹{perUnitAmount} / unit</p>
                </div>
                {/* Increase / Decrease Count */}
                <div className="bg-white h-full flex px-3 items-center">
                  <div className="flex items-center gap-3 mt-6">
                    <button
                      type="button"
                      className={clsx(
                        'w-6 h-6 rounded-full flex items-center justify-center text-xs bg-green-700 text-white font-semibold',
                        {
                          'opacity-20': units <= qty,
                        },
                      )}
                      disabled={units <= qty}
                      onClick={() => {
                        setUnits((units) => units - 1);
                        track({
                          eventName: BONDS_EVENTS.Bonds_Enter_Units,
                        });
                      }}
                    >
                      -
                    </button>

                    <Typography
                      className="text-stone-400 text-2xl font-semibold"
                      sx={{
                        minWidth: `${
                          investmentTerms?.data?.maxInvestment?.qty?.toString()?.length * 5 + 30 ||
                          75
                        }px`,
                        width: `${
                          investmentTerms?.data?.maxInvestment?.qty?.toString()?.length * 5 + 30 ||
                          75
                        }px`,
                        maxWidth: `${
                          investmentTerms?.data?.maxInvestment?.qty.toString().length * 5 + 30 || 75
                        }px`,
                      }}
                    >
                      <Input
                        sx={{
                          fontSize: 'inherit',
                          fontWeight: 'inherit',
                          fontStyle: 'inherit',
                        }}
                        inputProps={{
                          style: {
                            textAlign: 'center',
                          },
                        }}
                        value={units}
                        onChange={(e) => handleInputChange(e)}
                      ></Input>
                    </Typography>

                    <button
                      type="button"
                      className={clsx(
                        'w-6 h-6 rounded-full flex items-center justify-center text-xs bg-green-700 text-white font-semibold',
                      )}
                      onClick={() => {
                        setUnits((units) => units + 1);

                        track({
                          eventName: BONDS_EVENTS.Bonds_Enter_Units,
                        });
                      }}
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>

              <div className="p-4 md:p-5 border-b border-b-stone-300 flex items-center gap-4 text-slate-600">
                <h3 className="text-sm min-w-36">Investment Amount</h3>
                <span className="text-lg font-medium line-clamp-1">
                  {!isInvestmentTermsLoading && !!amount && getRupeeWithDecimal.format(amount)}
                  {!amount && !!initialAmount && getRupeeWithDecimal.format(initialAmount)}
                </span>
              </div>

              <div className="p-4 md:p-5 border-b border-b-stone-300 flex items-center gap-4 text-slate-600">
                <h3 className="text-sm text-slate-600 min-w-36">Yield to Partner</h3>
                <span className="text-lg font-medium text-slate-700 line-clamp-1">
                  {maxYield ? `${twoDecimalNumber(maxYield)}` + '%' : '-'}
                </span>
              </div>

              <div className="p-4 md:p-5 border-b border-b-stone-300 flex items-center gap-4 text-slate-600">
                <h3 className="text-sm text-slate-600 min-w-36 flex flex-col">
                  Yield to Client on Tap
                  <span className="text-[10px]">(Visible on Tap Invest)</span>
                </h3>
                <span
                  className="text-lg font-medium text-slate
slate-700 line-clamp-1"
                >
                  {twoDecimalNumber(yieldRate)}%
                </span>
              </div>
              <div className="p-4 md:p-5 border-b border-b-stone-300 flex items-center gap-4 text-slate-600">
                <h3 className="text-sm min-w-36">Expected Returns</h3>
                <span className="text-lg font-medium line-clamp-1">
                  {!isInvestmentTermsLoading &&
                    !!returnAmount &&
                    (getRupeeWithDecimal.format(returnAmount) || '-')}
                  {!returnAmount &&
                    !!initialReturnAmount &&
                    (getRupeeWithDecimal.format(initialReturnAmount) || '-')}
                </span>
              </div>

              <div className="p-4 md:p-5 border-b border-b-stone-300 flex items-center gap-4 text-slate-600">
                <h3 className="text-sm min-w-36">Settlement Date</h3>
                <span className="text-lg font-medium line-clamp-1">
                  {!isInvestmentTermsLoading && (settlementDate ? formatDate(settlementDate) : '-')}
                </span>
              </div>
            </div>
          </div>

          <div className="flex flex-col bg-black/[.02] rounded-lg border border-stone-300 overflow-hidden mt-6">
            <div className="bg-white px-4 py-5 border-b-[1px] border-stone-300">
              <h3 className="text-gray-600 text-sm border-b border-dashed w-fit ">
                YIELD TO CLIENT (CUSTOM)
              </h3>
              <div className="inline-flex items-end">
                <input
                  type="text"
                  id="yield"
                  className="pt-4 font-semibold text-2xl text-black"
                  placeholder={`${yieldRate}`}
                  onChange={handleYieldAmountChange}
                  onBlur={(e) => {
                    const value = e.target.value;
                    if (maxYield && value > maxYield) {
                      toast.error(`Yield cannot be greater than ${maxYield}`, {
                        duration: 5000,
                        position: 'top-center',
                      });
                      setYieldError(true);
                    } else if (minYield && value < minYield) {
                      toast.error(`Yield cannot be less than ${minYield}`, {
                        duration: 5000,
                        position: 'top-center',
                      });
                      setYieldError(true);
                    } else {
                      toast.dismiss();
                      setYieldError(false);
                    }
                  }}
                  value={customYield}
                  style={{
                    width: `${
                      customYield?.toString().length > 0
                        ? customYield?.toString().includes('.')
                          ? customYield?.toString().length - 0.5
                          : customYield?.toString().length
                        : 3
                    }ch`,
                    outline: 'none',
                  }}
                />

                <span className=" text-gray-500">%</span>
              </div>
            </div>
            <div className="p-4 text-green-700 text-sm font-semibold">
              Your Commission{' '}
              <span className="text-green-700 text-lg font-semibold ml-8">
                {(!isPartnerYieldLoading &&
                  !!partnerAmount?.data &&
                  !!amount &&
                  partnerAmount?.data <= amount &&
                  getRupeeWithDecimal.format(amount - partnerAmount?.data)) ||
                  '-'}
              </span>
            </div>
          </div>

          <div className="mt-6">
            <SelectClient
              client={client}
              setClient={setClient}
              eventName={BONDS_EVENTS.Bonds_select_client}
              onChange={() => {
                setButtonDisabled(false);
              }}
            />
          </div>

          <div className="mt-4">
            <Checkbox
              id="acceptTerms"
              className="accent-green-600"
              label={
                <span>
                  By proceeding you agree to our{' '}
                  <a href="/terms" target="_blank" className="underline">
                    Terms & Conditions
                  </a>{' '}
                  <span>
                    and{' '}
                    <a
                      href="https://app.tapinvest.in/BondsRDA.pdf"
                      className="underline"
                      target="_blank"
                    >
                      Risks
                    </a>{' '}
                    involved
                  </span>
                </span>
              }
              labelClassName="text-stone-500"
              defaultChecked
              onChange={(e) => setAcceptedTerms(e.target.checked)}
            />
          </div>

          <div className="mt-4 flex justify-between items-center gap-2.5">
            <KYCButtonComponent
              isMaxAmountExceeded={isMaxAmountExceeded}
              status={status}
              acceptedTerms={acceptedTerms}
              clientId={clientId}
            />
          </div>

          {/* Contact RM */}
          <div className="border border-stone-300 rounded-md p-5 mt-6">
            <h3 className="text-slate-800 font-semibold text-sm mb-1.5">Contact RM</h3>
            <p className="text-stone-600 text-xs">
              We have a dedicated Relationship Manager assigned for all your queries and doubts.
            </p>

            <div className="bg-white flex items-center justify-between border border-black/10 rounded-lg shadow-sm mt-4">
              <div className="py-5 px-4">
                <h3 className="font-medium text-sm mb-0.5">Avnish Singh</h3>
                <p className="text-[10px] text-stone-600">
                  Available, from Monday to Friday (9:30AM to 6:30PM)
                </p>
              </div>
              <div className="self-end">
                <img
                  src={'https://cdn.leafround.com/promo/avnish-singh.png'}
                  width={108}
                  height={100}
                  alt="Avnish Singh"
                />
              </div>
            </div>
            <div className="flex justify-between items-center gap-2 mt-4">
              <a
                href="tel:+917278005041"
                target="_blank"
                className="text-slate-600 flex justify-center items-center bg-white font-semibold text-sm border border-black/10 h-10 w-44 rounded-xl shadow-sm"
              >
                Quick call <PhoneInTalk fontSize="small" className="ml-2" />
              </a>
              <a
                href="https://calendly.com/avnish-singh-leafround/connect-with-the-bonds-manager"
                target="_blank"
                className="text-slate-600 flex justify-center items-center bg-white font-semibold text-sm border border-black/10 h-10 w-44 rounded-xl shadow-sm"
              >
                Book a meet <CalendarToday fontSize="small" className="ml-2" />
              </a>
            </div>
          </div>
        </form>
      </div>
      {/* Payment Link Modal */}
      <Dialog open={isPaymentOpen} maxWidth="sm" fullWidth>
        <div className="flex bg-amber-100 p-2.5 items-center gap-2 ">
          <InfoIcon className="text-amber-700 h-4 w-4" />
          <h4 className="text-sm text-amber-700 font-medium">
            This link is valid only for 3 hours
          </h4>
        </div>
        <DialogTitle>
          <div className="px-5 flex justify-between">
            <div>
              <h3 className="text-lg font-semibold">Link Generated</h3>
              <p className="text-slate-600 text-sm mt-1.5">
                Copy link & share it with client for successful investment in the Bond
              </p>
            </div>
            <CloseOutlinedIcon
              className="cursor-pointer bg-slate-200 rounded-full p-1 w-7 h-7"
              onClick={() => handlePaymentClose()}
            />
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="py-5bg-white rounded-lg">
            <div className="p-5 pb-3 flex gap-3">
              <div className="flex flex-col justify-between gap-[6px] flex-1 pt-[14px] pr-4 pb-4 border-r border-gray-200">
                <h4 className="text-sm text-stone-400 ">PER UNIT PRICE</h4>
                <h3 className="text-sm font-medium">{getRupeeWithDecimal.format(perUnitAmount)}</h3>
              </div>
              <div className="flex flex-col justify-between gap-[6px] flex-1 pt-[14px] pr-4 pb-4 border-r border-gray-200">
                <h4 className="text-sm text-stone-400">TOTAL UNITS</h4>
                <h3 className="text-sm font-medium text-green-700">{units}</h3>
              </div>
              <div className="flex flex-col justify-between gap-[6px] flex-1 pt-[14px] pr-4 pb-4 pl-6">
                <h4 className="text-sm text-stone-400 ">TOTAL INVESTMENT AMT.</h4>
                <h3 className="text-sm font-medium"> {getRupeeWithDecimal.format(amount)}</h3>
              </div>
            </div>
            <div className="px-5 pt-6 pb-3">
              <div className="flex flex-col gap-[6px]">
                <h4 className="text-sm text-gray-600">Share link with your client</h4>
                <div className="flex items-center w-full">
                  <input
                    type="text"
                    value={`${userPaymentLink || 'loading link...'}`}
                    readOnly
                    className="flex-grow border border-gray-300 rounded-l-xl p-2"
                  />
                  <button
                    // onClick={copyToClipboard}
                    onClick={() => {
                      navigator.clipboard.writeText(userPaymentLink);
                      toast.success('Link copied to clipboard', {
                        duration: 2000,
                        position: 'top-center',
                      });
                    }}
                    className="bg-green-700 text-white text-sm font-semibold h-[2.65rem] px-4 rounded-r-xl "
                  >
                    Copy Url
                  </button>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      {/* Request Callback Modal */}
      <Dialog open={isCallbackOpen} maxWidth="sm" fullWidth>
        <div className="p-4">
          <h3 className="text-xl font-semibold mb-2">Thanks for reaching out to us!</h3>
          <p className="text-slate-600 text-sm mb-3">
            Our investments team will contact you shortly.
          </p>
          <div className="flex justify-end items-center mt-8">
            <button
              onClick={() => setIsCallbackOpen(false)}
              className="bg-green-600 text-white text-sm font-semibold py-3 w-36 rounded-xl"
            >
              Understood
            </button>
          </div>
        </div>
      </Dialog>

      {/* CMR Upload Modal */}
      <CMRUpload
        open={showCMRModal}
        onClose={handleCRMModalClose}
        refetchCmrStatus={refetchCmrStatus}
        clientId={clientId}
      />
      {/* Add new clients */}
      <ClientDrawer />
    </div>
  );
};

BondsDealDetail.displayName = 'BondsDealDetail';

export default BondsDealDetail;
