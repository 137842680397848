import React, { useState } from "react";
import { useLayoutStore } from "../../../components/organisms/Layout/store";
import clsx from "clsx";
import { ClientOnboardingPageType } from "../types";
import { CLIENT_ONBOARDING_PAGE_TYPE, CLIENT_ONBOARDING_STEPS } from "../constants";
import { useClientKycStatus } from "../useClient";
import FirstStep from "./FirstStep";
import IdentityVerification from "./IdentityVerification";
import PanVerification from "./PanVerification";
import AadhaarVerification from "./AadhaarVerification";
import BankVerification from "./BankVerification";
import FinalStep from "./FinalStep";
import { ArrowBack } from "@mui/icons-material";

interface ClientDrawerProps { };

// TODO: Back button to go back to the select screen
const ClientDrawer: React.FC<ClientDrawerProps> = () => {
    const [step, setStep] = useState<ClientOnboardingPageType>(CLIENT_ONBOARDING_PAGE_TYPE.FIRST_STEP);
    const {
        isClientDrawerOpen,
        setIsClientDrawerOpen,
        clientId
    } = useLayoutStore(({
        isClientDrawerOpen,
        setIsClientDrawerOpen,
        clientId,
    }) => ({
        isClientDrawerOpen,
        setIsClientDrawerOpen,
        clientId,
    }));

    const isExistingClient = !!clientId;


    const { data: kycStatus, isLoading } = useClientKycStatus(clientId)
    const { aadhaarVerified, bankDetailsVerified, panVerified } = kycStatus?.data || {};

    // steps
    const isFirstStep = step === CLIENT_ONBOARDING_PAGE_TYPE.FIRST_STEP;
    const isIdentityStep = step === CLIENT_ONBOARDING_PAGE_TYPE.IDENTITY_VERIFICATION;
    const isPanStep = step === CLIENT_ONBOARDING_PAGE_TYPE.PAN_VERIFICATION;
    const isAadhaarStep = step === CLIENT_ONBOARDING_PAGE_TYPE.AADHAAR_VERIFICATION;
    const isBankStep = step === CLIENT_ONBOARDING_PAGE_TYPE.BANK_VERIFICATION;
    const isFinalStep = step === CLIENT_ONBOARDING_PAGE_TYPE.FINAL_STEP;

    // steps verified
    const isIdentityVerified = isExistingClient || (isPanStep || isAadhaarStep || isBankStep);
    const isPanVerified = isExistingClient ? panVerified : (isAadhaarStep || isBankStep);
    const isAadhaarVerified = isExistingClient ? aadhaarVerified : isBankStep;
    const isBankVerified = bankDetailsVerified;

    const handleStep = (step: ClientOnboardingPageType) => {
        setStep(step);
    }


    const stepCount = Object.keys(CLIENT_ONBOARDING_STEPS).findIndex((currentStep) => currentStep === step) + 1;

    return (
        <>
            <div id="client-drawer" className={clsx("transition-all flex flex-col duration-300 transform fixed top-0 end-0 bottom-0 z-[80] w-full md:w-[600px] bg-primary overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300", {
                "translate-x-0": isClientDrawerOpen,
                "translate-x-full": !isClientDrawerOpen
            })}>
                {/* Header */}
                {!isFinalStep && <div className="border-b border-b-stone-300 px-4 py-4 md:px-6 md:py-8">
                    {isFirstStep ?
                        <div>
                            <h3 className="text-lg md:text-2xl font-semibold mb-1.5">{isExistingClient ? "Continue KYC Verification" : "Add New Client"}</h3>
                        </div>
                        :
                        <>
                            <div className="flex justify-between items-center mb-6">
                                <button onClick={() => setStep(CLIENT_ONBOARDING_PAGE_TYPE.FIRST_STEP)} className="bg-green-700/10 flex items-center justify-center p-1.5 h-9 w-9 rounded-full text-green-700">
                                    <ArrowBack fontSize="small" />
                                </button>
                                <div>
                                    <div className="flex items-center justify-between mb-0.5">
                                        <h4 className="text-[11px] text-green-700 font-semibold">Steps {stepCount} of 4</h4>
                                        <h5 className="text-[11px] text-stone-500 font-semibold">Up Next: {CLIENT_ONBOARDING_STEPS[step].nextStepTitle}</h5>
                                    </div>
                                    <div className="flex items-center gap-1 w-56">
                                        <div className={clsx("h-1.5 w-full rounded-l-full", {
                                            "bg-green-700": isIdentityVerified,
                                            "bg-black/5": !isIdentityVerified
                                        })}></div>
                                        <div className={clsx("h-1.5 w-full", {
                                            "bg-green-700": isPanVerified,
                                            "bg-black/5": !isPanVerified
                                        })}></div>
                                        <div className={clsx("h-1.5 w-full", {
                                            "bg-green-700": isAadhaarVerified,
                                            "bg-black/5": !isAadhaarVerified
                                        })}></div>
                                        <div className={clsx("h-1.5 w-full rounded-r-full", {
                                            "bg-green-700": isBankVerified,
                                            "bg-black/5": !isBankVerified
                                        })}></div>
                                    </div>

                                </div>
                            </div>
                            <h3 className="text-2xl font-semibold mb-1.5">{CLIENT_ONBOARDING_STEPS[step].stepTitle}</h3>
                            <p className="text-stone-600 mt-1.5">{CLIENT_ONBOARDING_STEPS[step].formDescription}</p>
                        </>
                    }
                </div>}
                {/* Content */}
                <div className={clsx("px-4 py-4 md:px-6 md:py-6", {
                    "m-auto": isFinalStep
                })}>
                    {isFirstStep && <FirstStep
                        isIdentityVerified={isIdentityVerified}
                        isPanVerified={isPanVerified}
                        isAadhaarVerified={isAadhaarVerified}
                        isBankVerified={isBankVerified}
                        handleStep={handleStep}
                    />}
                    {isIdentityStep && <IdentityVerification handleStep={handleStep} />}
                    {isPanStep && <PanVerification handleStep={handleStep} />}
                    {isAadhaarStep && <AadhaarVerification handleStep={handleStep} />}
                    {isBankStep && <BankVerification handleStep={handleStep} />}
                    {isFinalStep && <FinalStep handleStep={handleStep} />}
                </div>
                {/* Footer */}
                {!isFinalStep && <div className="sticky mt-auto w-full bg-primary/90 bottom-0 px-4 py-3 md:px-6 md:py-5 border-t border-t-stone-300">
                    <button onClick={() => {
                        setStep(CLIENT_ONBOARDING_PAGE_TYPE.FIRST_STEP)
                        setIsClientDrawerOpen(null)
                    }} className="text-slate-600 underline text-sm font-semibold flex mx-auto justify-between items-center">I will do it later</button>
                </div>}
            </div>
            {isClientDrawerOpen &&
                <div
                    onClick={() => {
                        if (isFinalStep || isFirstStep) {
                            setStep(CLIENT_ONBOARDING_PAGE_TYPE.FIRST_STEP)
                            setIsClientDrawerOpen(null);
                        }
                    }}
                    className="flex fixed top-0 right-0 bottom-0 left-0 bg-black/50 z-[70]"
                />
            }
        </>
    );
};

ClientDrawer.displayName = "ClientDrawer";

export default ClientDrawer;