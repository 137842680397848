import React, { useState } from "react";
import { ClientOnboardingActions } from "../types";
import * as yup from "yup";
import "yup-phone-lite";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { CLIENTS_EVENTS, CLIENT_ONBOARDING_PAGE_TYPE } from "../constants";
import { kycError } from "../../../utils/toast";
import { NetworkManager } from "../../../services";
import Input from "../../../components/Fields/Input";
import { Field } from "../../../components/Fields/Field";
import KYCButton from "../../Onboarding/KYCButton";
import { useLayoutStore } from "../../../components/organisms/Layout/store";
import toast from "react-hot-toast";
import { useAnalytics } from "../../../hooks/useAnalytics";

interface IdentityVerificationProps extends ClientOnboardingActions { }

const IdentityVerification: React.FC<IdentityVerificationProps> = ({
  handleStep,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { setClientId, setClientDetails } = useLayoutStore(
    ({ setClientId, setClientDetails }) => ({ setClientId, setClientDetails })
  );

  const { track } = useAnalytics();

  const schema = yup.object().shape({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    email: yup
      .string()
      .required("Email is required")
      .email("Email is not valid"),
    phone: yup
      .string()
      .phone("IN", "Please enter a valid phone number")
      .required("Phone Number is required")
      .nullable(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      phone: null,
      email: null,
      firstName: null,
      lastName: null,
    },
  });

  const confirmIdentity = (
    email: string,
    phone: string,
    firstName: string,
    lastName: string
  ) => {
    return NetworkManager.Call({
      method: "post",
      path: NetworkManager.PATH.createClient,
      data: {
        firstName,
        lastName,
        phone: phone,
        isd: "+91",
        email: email,
      },
    });
  };

  const onSubmit = async (data: Record<string, any>) => {
    const { email, phone, firstName, lastName } = data;

    setIsLoading(true);

    try {
      const identityRes = await confirmIdentity(
        email,
        phone,
        firstName,
        lastName
      );
      const clientId = identityRes?.data.clientPublicId;
      // Set Client ID
      setClientId(clientId);
      setClientDetails({
        name: `${firstName} ${lastName}`,
        email,
      });
      handleStep(CLIENT_ONBOARDING_PAGE_TYPE.PAN_VERIFICATION);
      track({ eventName: CLIENTS_EVENTS.Identity_Verification_Success });
    } catch (error) {
      if (error?.response?.status?.toString().startsWith("5")) {
        toast.error("Something went wrong! Please try again", {
          duration: 2500,
          position: "top-center",
        });
      } else if (error?.response?.status.toString().startsWith("4")) {
        toast.error(error?.response?.data?.message, {
          duration: 2500,
          position: "top-center",
        });
      } else {
        kycError("Something went wrong! Please try again");
      }
    }

    setIsLoading(false);
  };

  const handleEntryEvent = (event) => {
    track({ eventName: event });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col max-w-96">
      <Field label="First Name" error={errors.firstName?.message}>
        <Input
          onClick={() => handleEntryEvent(CLIENTS_EVENTS.FName_Entry)}
          type="text"
          placeholder="Enter your fist name"
          {...register("firstName", { required: true, maxLength: 80 })}
        />
      </Field>
      <Field
        className="mt-6"
        label="Last Name"
        error={errors.lastName?.message}
      >
        <Input
          onClick={() => handleEntryEvent(CLIENTS_EVENTS.LName_Entry)}
          type="text"
          placeholder="Enter your last name"
          {...register("lastName", { required: true, maxLength: 80 })}
        />
      </Field>
      <Field
        className="mt-6"
        label="Email Address"
        error={errors.email?.message}
      >
        <Input
          onClick={() => handleEntryEvent(CLIENTS_EVENTS.Email_Entry)}
          type="email"
          placeholder="Enter your email address"
          {...register("email", { required: true, maxLength: 80 })}
        />
      </Field>
      <Field
        className="mt-6"
        label="Phone Number"
        error={errors.phone?.message}
      >
        <Input
          onClick={() => handleEntryEvent(CLIENTS_EVENTS.Phone_Entry)}
          type="text"
          placeholder="9321xxxxxx"
          maxLength={10}
          {...register("phone")}
          numericOnly={true}
          prefixNode={
            <span className="text-slate-600 font-semibold text-sm">+91</span>
          }
        />
      </Field>
      <KYCButton isLoading={isLoading} text="Confirm and Proceed" />
    </form>
  );
};

IdentityVerification.displayName = "IdentityVerification";

export default IdentityVerification;
