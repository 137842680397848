import { useState } from "react";
import { NetworkManager } from "../services";

export const useDownloadCsv = ({ path, filename}) => {
    const [csvLoading, setCsvLoading] = useState(false);


    const downloadCsv = async () => {
        setCsvLoading(true);
        const csvRes = await NetworkManager.Call({
            method: "get",
            path,
            responseType: "blob"
        })

        // Create a link element
        const link = document.createElement('a');

        // Create a URL for the Blob and set it as the href attribute
        const url = URL.createObjectURL(new Blob([csvRes]));
        link.href = url;

        // Set the download attribute with the desired file name
        link.download = `${new Date().toISOString()}_${filename}`;

        // Programmatically click the link to trigger the download
        link.click();
        // Clean up by revoking the object URL
        URL.revokeObjectURL(url);

        setCsvLoading(false);
    }

    return {
        csvLoading,
        downloadCsv
    }
}