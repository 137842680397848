import clsx from 'clsx';
import React, { ReactNode, forwardRef } from 'react';

interface CheckboxProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
    className?: string;
    labelClassName?: string;
    label?: string | ReactNode;
    id: string;
}

const Checkbox: React.ForwardRefRenderFunction<HTMLInputElement, CheckboxProps> = ({ id, label, className, labelClassName, ...rest }, ref) => {

    return (
        <div className="flex items-center gap-x-4">
            <input
                ref={ref}
                id={id}
                type="checkbox"
                className={clsx("w-4 h-4 rounded checked:focus:ring-primary checked:text-primary bg-white shadow-sm border-gray-300", className)}
                {...rest}
            />
            {label &&
                <label htmlFor={id} className={clsx('text-sm font-medium', labelClassName)}>{label}</label>
            }
        </div>
    );
};

export default forwardRef(Checkbox);