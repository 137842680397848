import React, { useState } from "react";
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { KeyboardArrowDown } from "@mui/icons-material";
import { BONDS_FAQS } from "./constants";

interface BondFaqsProps { };

const BondFaqs: React.FC<BondFaqsProps> = () => {
    const [expanded, setExpanded] = useState<number | false>(false);

    const handleChange = (panel: number, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <div className="flex flex-col gap-3 mt-3">
            {BONDS_FAQS.map((faq, index) => (
                <MuiAccordion
                    key={faq.id}
                    className="!bg-primary border border-stone-200"
                    expanded={expanded === index}
                    onChange={(e, newExpanded) => handleChange(index, newExpanded)}
                    disableGutters
                    sx={{ '&:before': { height: '0px' } }}
                    elevation={0}
                >
                    <MuiAccordionSummary
                        aria-controls={`panel${index}d-content`}
                        id={`panel${index}d-header`}
                        expandIcon={<KeyboardArrowDown className="text-stone-400" />}
                    >
                        <h3 className="text-stone-700 font-medium text-sm">{faq.question}</h3>
                    </MuiAccordionSummary>
                    <MuiAccordionDetails>
                        <div className="text-stone-600 text-sm">
                            {faq.answer}
                        </div>
                    </MuiAccordionDetails>
                </MuiAccordion>
            ))

            }
        </div>
    );
};

BondFaqs.displayName = "BondFaqs";

export default BondFaqs;