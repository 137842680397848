import React from "react";
import Footer from "../../components/Footer";

interface TermsProps { };

const Terms: React.FC<TermsProps> = () => {
    return (
        <>
            <div className="py-24 max-w-screen-2xl mx-auto px-4 md:px-12">
                <h1 className="font-medium text-4xl lg:text-6xl mb-32 text-center">Terms & Conditions</h1>

                <div className="text-xl md:text-2xl">
                    <p className="mb-6">
                        The Terms and Conditions set our herein (the <b>“Terms”</b>) constitutes a legal agreement between the <b>User</b> (hereinafter referred to as <b>“User”</b> or <b>“You”</b>) and <b>Purple Petal Invest Private Limited</b>, a company registered under the Companies Act, 2013, bearing CIN U72900MH2021PTC364347 (hereinafter referred to as the <b>“Company”</b> or <b>“PPIPL”</b> or <b>“Us”</b> or <b>“We”</b> or <b>“Our”</b>, which expression shall, unless repugnant to the context or meaning thereof, be deemed to mean and include its Affiliates, successors and permitted assigns)and having its registered office at Shop No. 14-15-16-17, Achraj Tower No.-2, Chhaoni Nagpur, Nagpur- 440013, Maharashtra, India.
                    </p>

                    <p className="mb-6">
                        PPIPL owns and operates the <b>Platform</b> (as hereinafter defined) and is a marketplace engaged in the business of providing technology solutions for fixed income financial products and investment opportunities (the <b>"Business"</b>).
                    </p>

                    <p className="mb-6">
                        For the purpose of this Agreement “User”, “You”, “Your” or Participant refers to any natural or legal person (registered or otherwise) who has access to the Website, content and uses the services offered by TAPINVEST™ on or through the Website. By accessing, downloading, and using our Platform, the User agrees to be bound by these Terms and Conditions (hereinafter referred to as the “Terms”)  and explicitly consents to being bound by our Privacy Policy (as defined hereinafter ), and such other agreements that may be adopted or that has been entered into between PPIPL and the User for such Transaction. In case of a breach, your right to access will be terminated without prior notice.
                    </p>

                    <ol className="list-decimal ml-6 flex flex-col gap-6 md:gap-12">
                        <li>
                            GENERAL

                            <ol className="ml-6 flex flex-col gap-6 md:gap-12">
                                <li className="flex gap-2">
                                    <span>a.</span> In this agreement, unless the context clearly indicates a contrary intention, and in addition to the terms defined in the body of the Agreement, the following words or expressions shall have the meaning assigned herein:
                                </li>

                                <li className="flex gap-2">
                                    <span>b.</span> “Applicable Law” means any applicable law, rule, regulation, ordinance, order, treaty, judgment, notification, decree, bye-law, governmental approval, directive, guideline, requirement or other governmental restriction, or any similar form of decision of, or determination by, or any interpretation, policy or administration, having the force of law and shall include any of the foregoing, injunction, permit or decision of any central, state or local, municipal government, authority, agency, court having jurisdiction over the matter in question, whether in effect as of the date of this Agreement or thereafter, in any jurisdiction;
                                </li>

                                <li className="flex gap-2">
                                    <span>c.</span> “Personal Data” means any data about an individual who is identifiable by or in relation to such data.
                                </li>

                                <li className="flex gap-2">
                                    <span>d.</span> “Platform” means and includes the Website and/or the software and mobile application of TAPINVEST™ accessed through or downloaded from the Android or iOS app store on a compatible device.
                                </li>

                                <li className="flex gap-2">
                                    <span>e.</span> “Privacy Policy” means the privacy policy of the Platform which inter alia governs the use of User information, collection and the storage of Personal Information and sensitive personal  information. Please refer to the Privacy Policy for further information.
                                </li>

                                <li className="flex gap-2">
                                    <span>f.</span> “Services” means any and all technology services provided by TAPINVEST™ on its Platform for dealing in fixed income financial products and includes buying, selling, facilitation of leases, collections, payments, payouts and any ancillary services.
                                </li>

                                <li className="flex gap-2">
                                    <span>g.</span> “Terms” means the terms and conditions set out herein.
                                </li>

                                <li className="flex gap-2">
                                    <span>h.</span> “Transaction” means availing of any Services on or through the Platform.
                                </li>
                            </ol>
                        </li>

                        <li>
                            ACCESS TO CONTINUED USE OF SERVICES
                            <ol className="ml-6 flex flex-col gap-6 md:gap-12">
                                <li className="flex gap-2">
                                    <span>a.</span> By registering for, accessing and/or using the Platform in manner whatsoever, the User agrees to all the Terms contained herein, the Privacy Policy and all other operating rules, policies and procedures that may be published by PPIPL from time to time, each of which may be updated from time to time without notice to the User. An intimation regarding the updates or modification of the Terms and other relevant modified updates shall be uploaded on Announcements &gt; Legal, which will be available on our Website. Furthermore, the Services offered through the Platform may be subject to additional terms and conditions, basis any other agreement entered into between the User and PPIPL, or as may be specified by PPIPL from time to time. The User’s continued use of the PPIL’s Services is subject to those additional terms and conditions, which are incorporated into these Terms by this reference.
                                </li>

                                <li className="flex gap-2">
                                    <span>b. </span> These Terms apply to all Users of the Platform. In the case that the User does not agree to  any of these Terms, or does not comply with the provisions herein, the User is advised to cease using the Platform or its Services. Users are prohibited from violating or attempting to violate any security of the Platform. Tampering any portion of the Website or providing inaccurate information or misrepresentation is prohibited and is considered a breach of Terms and Privacy Policy.
                                </li>
                            </ol>
                        </li>

                        <li>
                            REGISTRATION AND TERMS OF USE
                            <ol className="ml-6 flex flex-col gap-6 md:gap-12">
                                <li className="flex gap-2">
                                    <span>a.</span> The User is required to register their profile on the Platform for which purpose the User shall be required to furnish details including but not limited to their Name Phone number and email address. The User hereby represents, warrants, and covenants that such information provided by the User shall be accurate, truthful, and complete and that the User shall keep their registration information accurate and up to date. The User shall not provide any false Personal Information or create any account for anyone other than him/herself. After acceptance to the  Terms,  and Privacy Policy and Risks Disclosure Policy. The User then submits KYC details for the purposes of registration without which, the User would not be able to use the services of the Platform and cannot claim the Terms to be invalid. Clicking the “I Accept” is an affirmative action of accepting to the Terms. After registration is completed, the User is granted a personal, non-exclusive, non-transferrable, and limited license or right to access the Platform. The User’s ability to continue using the Platform is subject to their continued maintenance of an account on the Platform and all the KYC information and other information provided being true, accurate and correct.
                                </li>

                                <li className="flex gap-2">
                                    <span>b. </span> Registration of User profile is a one-time process, a “User id” is generated and can be used along with a one-time Password (OTP) to login to the Platform. Users will be required to enter their personal information including their name, contact details, email address and valid phone number while registering an Account. As part of the registration, Users may be required to undertake a verification process to verify the information provided. In the case the User has been previously registered, the User shall login / sign into the profile using the same credentials as provided during the registration process.
                                </li>

                                <li className="flex gap-2">
                                    <span>c. </span> The User represents and warrants that the User is an Indian resident or an entity registered in India and in the case that the User is an individual, the User is of legal age i.e. 18 (eighteen years of age) or older to form a binding contract, or that if the User is registering on behalf of an entity, that the User is authorized to enter and bind the entity  to these Terms by registering on the Platform. In case of a NRI User, the User undertakes to follow the applicable laws.
                                </li>

                                <li className="flex gap-2">
                                    <span>d. </span> The User further undertakes to promptly update its details on the Platform in the event of any change or modification of such details.
                                </li>

                                <li className="flex gap-2">
                                    <span>e. </span> PPIPL expressly excludes any liability for any unauthorized access to a User’s profile.
                                </li>

                                <li className="flex gap-2">
                                    <span>f. </span> The User hereby agrees to be liable and accountable for all activities that take place through its profile on the Platform or through the use of the Services.
                                </li>

                                <li className="flex gap-2">
                                    <span>g. </span> The User may be required to upload certain information and documents to ascertain their identity and eligibility to use the features of the Platform or to avail themselves of the Services. After Registration the User must provide PAN Number, Aadhar Number, Bank Details (Name, Account Number, IFSC) for KYC procedure. Such identification documents (the “KYC Documents”) shall be valid, true, complete, and up to date. The User represents and warrants that all information provided by the User valid, complete, true, and correct on the date of acceptance of the Terms and the User shall update the documents if there is any change, such that the documents continue to be valid, complete, true, and correct throughout the duration of the User’s use of the Platform.
                                </li>

                                <li className="flex gap-2">
                                    <span>h. </span> The User hereby acknowledges that in the event of providing an incorrect or misleading information, the act shall constitute a material breach of these Terms, and the User’s access the Platform or Services may be restricted in such event or annulled.
                                </li>

                                <li className="flex gap-2">
                                    <span>i.</span> The User consents and allows PPIPL to share information to third parties for verification and validity of KYC documents and for completion of payments in business transactions.
                                </li>

                                <li className="flex gap-2">
                                    <span>j.</span> You agree to provide your explicit consent to fetch your credit data from Experian CIC on a monthly basis.
                                </li>

                                <li className="flex gap-2">
                                    <span>k.</span> PPIPL may require you or request you to provide additional information or undertake additional registration in relation to Your access to certain features or services on the Website.  Your access to such features will depend on your acceptance of those additional conditions.
                                </li>

                                <li className="flex gap-2">
                                    <span>l.</span> Access and use of the Platform including but not limited to products, images, text, design, structure, illustrations, blogs, or other content visual graphics available on the Platform are governed by the Terms and any other rules, procedures policies etc. which govern any portion of the Platform. Unless Registered, User has limited access to the Website.
                                </li>

                                <li className="flex gap-2">
                                    <span>m.</span> The User hereby irrevocably authorizes PPIPL as its lawful attorney-in-fact to take any action for the purpose of recovery of money on behalf of Investors. For this purpose, to appear in all or any courts and offices to represent the Investor in all proceedings and make statement on oath or otherwise for and on behalf of the Investor.
                                </li>

                                <li className="flex gap-2">
                                    <span>n.</span> The User agrees that investment orders once placed will not be refunded or cancelled by PPIPL under any circumstances.
                                </li>
                            </ol>
                        </li>

                        <li>
                            DISCLAIMERS AND WARRANTIES
                            <ol className="ml-6 flex flex-col gap-6 md:gap-12">
                                <li className="flex gap-2">
                                    <span>a.</span> The User shall be solely responsible for ensuring compliance with Applicable Laws and shall be solely liable for any liability that may arise due to a breach of its obligations in this regard.
                                </li>

                                <li className="flex gap-2">
                                    <span>b.</span> The User shall use the Platform and Services at its sole risk. User hereby accepts full responsibility for any consequences that may arise from User’s use of the Services, and expressly agree and acknowledge that PPIPL shall have absolutely no liability with respect to the same. The User undertakes to maintain confidentiality and notify PPIPL of any unauthorized access or any breach of security. The User undertakes not to sell or transfer his account to a third party.
                                </li>

                                <li className="flex gap-2">
                                    <span>c.</span> To the extent permissible under Applicable Law, PPIPL expressly disclaims all warranties arising out of the Services, including warranties of quality, accuracy, title, compatibility, applicability, usability, appropriateness, and any warranty that may arise out of course of performance, course of dealing, or usage of trade.
                                </li>

                                <li className="flex gap-2">
                                    <span>d.</span> Notwithstanding anything to the contrary contained herein, neither PPIPL nor any of its affiliates shall have any liability to the User or any third party for any indirect, incidental, special or consequential damages or any loss of revenue or profits arising under, directly or indirectly, or relating, in any manner whatsoever, to these Terms or the Services. To the maximum extent permitted by Applicable Laws, the User agree to waive, release, discharge, and hold harmless  PPIPL, its directors, employees, agents, consultants from any and all claims, losses, damages, liabilities, expenses and causes of action arising out of the Services or accessing of, or using, the Platform.
                                </li>

                                <li className="flex gap-2">
                                    <span>e.</span> The User understands that the products listed on the Platform are speculative in nature and involve a high degree of risk. Thus, the Platform is suitable for sophisticated investors who are well versed with the risks. PPIPL does advice, endorse or guarantee any product. It disclaims any liability caused by such loss. Information is subject to verification by the Users, PPIPL disclaims all liabilities or losses for any decisions made by the investors.  Please read all the offer related documents carefully
                                </li>

                                <li>
                                    <div className="flex gap-2">
                                        <span>f.</span> PPIPL shall not be liable or responsible for:
                                    </div>
                                    <ol className="ml-6 flex flex-col gap-6 md:gap-12">
                                        <li className="flex gap-2">
                                            <span>i.</span> Instances where a transaction is unable to be completed or does not materialize;
                                        </li>

                                        <li className="flex gap-2">
                                            <span>ii.</span> Any failure to perform, or delay in performance of, any of PPIPL’s obligations under these Terms which is beyond PPIPL’s reasonable control, including force majeure events;
                                        </li>

                                        <li className="flex gap-2">
                                            <span>iii.</span> Any failure to perform, or delay in performance of, any of PPIPL’s obligations under these Terms; and
                                        </li>

                                        <li className="flex gap-2">
                                            <span>iv.</span> Any loss or damage caused by a virus’s attack, or other material that may infect User device, data, or other proprietary material due to User use of the Platform.
                                        </li>
                                    </ol>
                                </li>

                                <li className="flex gap-2">
                                    <span>g.</span> Without prejudice to the foregoing, none of the affiliates, directors, employees, agents, or representatives of PPIPL shall be personally liable for any action in connection with the Platform or Services.
                                </li>
                            </ol>
                        </li>

                        <li>
                            INDEMNITY
                            <p>
                                The User shall indemnify, defend and hold harmless, the PPIPL, its parent companies (if any), subsidiaries (if any), affiliates, and their officers, associates’ successors, assigns, licensors, employees, directors, agents, and representatives, from and against any claim, action, demand, lawsuits, judicial proceeding, losses, liabilities, damages and costs (including, without limitation, from all damages, liabilities, settlements, costs and attorneys’ fees) due to or arising out of User’s use or misuse of, or access to, the Platform, or otherwise, violation of these Terms or any infringement by the User or any third party who may use User’s account with PPIPL, of these Terms or the rights of any other person. PPIPL reserves the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by You, in which event You will assist and cooperate with PPIPL in asserting any available defenses.
                            </p>
                        </li>

                        <li>
                            INTELLECTUAL PROPERTY
                            <ol className="ml-6 flex flex-col gap-6 md:gap-12">
                                <li className="flex gap-2">
                                    <span>a. </span> All intellectual property rights arising from the Platform and its Services are solely owned by PPIPL.
                                </li>

                                <li className="flex gap-2">
                                    <span>b. </span> Subject to these Terms, the Platform permits the User a non-exclusive, non-transferable, revocable, and limited license or right to use the Platform and Services in accordance with these  Terms and any other written instructions issued by the Platform from time to time.
                                </li>

                                <li className="flex gap-2">
                                    <span>c. </span> The contents of this Platform are copyright-protected, and may not be used, sold, licensed, copied or reproduced in whole or in part in any manner or form or in or on any media to any person without the prior written consent of PPIPL.
                                </li>

                                <li className="flex gap-2">
                                    <span>d. </span> Except as stated in these Terms, nothing in these Terms should be construed as conferring any right in or license to PPIPL’s or any third party’s intellectual rights.
                                </li>

                                <li>
                                    <div className="flex gap-2">
                                        <span>e. </span> The User undertakes that they shall not:
                                    </div>
                                    <ol className="ml-6 flex flex-col gap-6 md:gap-12">
                                        <li className="flex gap-2">
                                            <span>i.</span> copy, transmit, display, perform, distribute (for compensation or otherwise), license, alter, store, or otherwise use the Platform or any of its components.
                                        </li>

                                        <li className="flex gap-2">
                                            <span>ii.</span> delete or modify any content of the Platform, including but not limited to, legal notices, disclaimers, or proprietary notices such as copyright or trademark symbols, logos, that You do not own or have express permission to modify.
                                        </li>

                                        <li className="flex gap-2">
                                            <span>iii.</span> make false or malicious statements against the Platform, allied services or the Company.
                                        </li>

                                        <li className="flex gap-2">
                                            <span>iv.</span> use the Platform in any unlawful manner, for any unlawful purpose, or in any manner inconsistent with these Terms of Use, or act fraudulently or maliciously, for example, by hacking into or inserting malicious code, including viruses, or harmful data, into the Platform or any operating system.
                                        </li>

                                        <li className="flex gap-2">
                                            <span>v.</span> engage in any form of antisocial, disrupting, or destructive acts, including ‘flaming’, ‘Spamming’, ‘flooding’, ‘trolling’, ‘phishing’ and ‘griefing’ as those terms are commonly understood and used on the internet.
                                        </li>

                                        <li className="flex gap-2">
                                            <span>vi.</span> use the Platform or any allied services in a way that could damage, disable, overburden, impair or compromise the Platform, our systems or security or interfere with other Users.
                                        </li>

                                        <li className="flex gap-2">
                                            <span>vii.</span> carry out reverse engineering in respect of the hardware or software underlying the Platform or any part thereof; and
                                        </li>

                                        <li className="flex gap-2">
                                            <span>viii.</span> comply with any applicable law and regulation relating to downloading, using or otherwise exporting the technology used or supported by the Platform or allied services, which may include but is not limited to not using the Platform in a country where the use of the Platform or any allied service is unlawful.
                                        </li>
                                    </ol>
                                </li>

                                <li className="flex gap-2">
                                    <span>f. </span> All content provided by PPIPL is made available as is and without any representations or warranties whatsoever, and PPIPL hereby disclaims any implied warranties, including but not limited to warranties of fitness for use, accuracy, merchantability, and non-infringement.
                                </li>

                                <li className="flex gap-2">
                                    <span>g. </span> The Services may include services, documents, and information owned by, licensed to, or otherwise made available by a third party (the “Third Party Services”) or contain links to Third Party Services. The User understand that Third Party Services are the responsibility of the third party that created or provided it and acknowledge that use of such Third-Party Services is solely at their own risk.
                                </li>

                                <li className="flex gap-2">
                                    <span>h. </span> PPIPL makes no representation and hereby expressly excludes all warranties and liabilities arising out of or pertaining to such Third-Party Services, including their accuracy or completeness. Further, all intellectual property rights in and to Third Party Services are the property of the respective third parties.
                                </li>

                                <li className="flex gap-2">
                                    <span>i. </span> PPIPL enables payments via Payment Service Providers (PSPs) Partners and the User should take care not to share their personal UPI (Unified Payments Interface) pin or OTP (One Time Password) with any third party intentionally or unintentionally. PPIPL never solicits information such as UPI pin or OTP over a call or otherwise.
                                </li>

                                <li className="flex gap-2">
                                    <span>j. </span> PPIPL shall not be liable for any fraud due to the sharing of such details by the User. The providers providing Third Party Services / PSP partners shall not be liable for any fraud due to sharing of such details by the User.
                                </li>

                                <li className="flex gap-2">
                                    <span>k. </span> All Third-Party Content on the Platform, whether publicly posted or privately transmitted, is the sole responsibility of the person who originated such Content. PPIPL cannot guarantee the authenticity of any Third-Party Content or data which Users may provide about themselves. The User acknowledges that all Third-Party Content accessed using the Platform is at User’s own risk and User will be solely responsible and liable for any damage or loss to the User or any other party resulting therefrom, and PPIPL shall have no liability towards You or any other party for any damage or loss resulting from such Third-Party Content.
                                </li>

                                <li className="flex gap-2">
                                    <span>l. </span> For purposes of these Terms of Use, the term "Third-Party Content" includes, without limitation, any location information, "floats", logos, images, videos, audio clips, comments, information, data, text, photographs, software, scripts, graphics, and interactive features generated, provided, or otherwise made accessible by PPIPL on or through the Platform.
                                </li>
                            </ol>
                        </li>

                        <li>
                            TERM AND TERMINATION
                            <ol className="ml-6 flex flex-col gap-6 md:gap-12">
                                <li>
                                    <div className="flex gap-2">
                                        <span>a.</span> These Terms shall remain in effect unless terminated in accordance with the terms hereunder:
                                    </div>
                                    <ol className="ml-6 flex flex-col gap-6 md:gap-12">
                                        <li className="flex gap-2">
                                            <span>i.</span> PPIPL may terminate a User’s access to or use of the Platform and/or the Services, or any portion thereof, immediately and at any point, at its sole discretion, if the User violates or breaches any of its obligations, responsibilities, or covenants under these Terms or the Agreements.
                                        </li>
                                        <li className="flex gap-2">
                                            <span>ii.</span> If a User wishes to terminate account, User may reach out to support@tapinvest.in,.
                                        </li>
                                    </ol>

                                </li>
                                <li className="flex gap-2">
                                    <span>b.</span> Notwithstanding anything to the contrary contained in the Terms, upon termination of a User’s access to or use of the Services, all amounts or outstanding payment due by the User in relation to User’s use of or access to the Services, if any, shall become immediately payable.
                                </li>
                            </ol>
                        </li>

                        <li>
                            COOKIES

                            <p className="mt-3">
                                The Platform employs the use of cookies, and by accessing the Platform, the User agrees to     the use of cookies along with the Platform's Privacy Policy.
                            </p>
                        </li>

                        <li>
                            FEES/CHARGES
                            <p className="mt-3">
                                PPIPL reserves the right to charge a fee for use of the Platform and/or the Services and non-payment may result in denial of Services.
                            </p>
                        </li>

                        <li>
                            MODIFICATION
                            <p className="mt-3">
                                PPIPL reserves the right at its sole discretion at any time to add, modify or discontinue, temporarily or permanently, the Services (or any part thereof) and Terms or allied services (including without limitation, the availability of any feature, database, or content) with or without cause. PPIPL shall not be liable for any such addition, modification, suspension or discontinuation of the Platform and /or the Services.
                            </p>
                        </li>

                        <li>
                            SEVERABILITY
                            <p className="mt-3">
                                If any provision of these Terms is determined by any court or other competent authority to be unlawful or unenforceable, the other provisions of these Terms will continue in effect. If any unlawful or unenforceable provision would be lawful or enforceable if part of it were deleted, that part will be deemed to be deleted, and the rest of the provision will continue in effect (unless that would contradict the clear intention of the clause, in which case the entirety of the relevant provision will be deemed to be deleted).
                            </p>
                        </li>

                        <li>
                            NOTICES
                            <p className="mt-3">
                                All notices for PPIPL under these Terms shall be sent to legal@tapinvest.in. All Notices will be in the English language. Unless otherwise specified in these Term, all notices under these Terms will be in writing and will be deemed to have been duly given when received, if personally delivered or sent by certified or registered mail, return receipt requested; when receipt is electronically confirmed, if transmitted by facsimile or e-mail; or the day after it is sent, if sent for next day   delivery by recognized overnight delivery service.
                            </p>
                        </li>

                        <li>
                            ASSIGNMENT
                            <p className="mt-3">
                                These Terms are personal to the User, and are not assignable, transferable, or sublicensable by  the User. PPIPL may assign, transfer or delegate any of its rights and obligations hereunder without User consent. It is hereby clarified that no agency, partnership, joint venture, or employment relationship is created as a result of these Terms and neither party has any authority of any kind to bind the other in any respect.
                            </p>
                        </li>

                        <li>
                            GOVERNING LAW
                            <p className="mt-3">
                                The Terms shall be governed by and construed in accordance with the laws of India. All claims, differences or disputes arising from these Terms shall be subject to the exclusive jurisdiction of the courts in Bengaluru, Karnataka, India.
                            </p>
                        </li>

                        <li>
                            GREIVANCE REDRESSAL MECHANISM

                            <p className="mt-3">
                                Any discrepancies or grievances regarding the Platform and its Services, or a breach of the Terms shall be taken up with the designated Grievance Officer, and the Grievance Redressal Process shall be in accordance with Applicable Law. Except where required by Applicable Law, the Platform cannot ensure a response to questions or comments regarding topics unrelated to the terms of this Terms of Use or the Platform’s Privacy Policy.

                            </p>
                        </li>

                        <li>
                            REFERENCE
                            <p className="mt-3">
                                Any questions or queries regarding the Services may be forwarded to legal@tapinvest.in. For the purpose of taking the question or query, the User shall be required to provide information including name, email address, and contact number for the purpose of sufficient identification and authentication. The information provided shall be subject to the terms of the Privacy Policy.
                            </p>
                        </li>

                        <li>
                            CHANGE IN TERMS
                            <p className="mt-3">
                                These Terms may be amended by the Platform from time to time. The Platform may also assign or transfer this agreement, and the associated rights and obligations accrued by the User under these Terms, and the User hereby agree to cooperate with the Platform for any such transfer or assignment. The User is advised to periodically review the Terms, for any such revisions or amendments. The continued use of the Platform and Services by the User shall be deemed to constitute the acceptance of any such revisions or amendments to these Terms.
                            </p>
                        </li>
                    </ol>
                </div>
            </div>
            <Footer />
        </>

    );
};

Terms.displayName = "Terms";

export default Terms;