import React, { useEffect } from "react";
import { ONBOARDING_EVENTS, ONBOARDING_PAGE_TYPE } from "./constants";
import PhoneAuth from "./PhoneAuth";
import EmailAuth from "./EmailAuth";
import PanVerification from "./PanVerification";
import AadhaarVerification from "./AadhaarVerification";
import BankVerification from "./BankVerification";
import { useOnboardingStore } from "./store";
import { useNavigate } from "react-router-dom";
import TapLogo from "./TapLogo";
import AgreementModal from "./AgreementModal";
import { useAnalytics } from "../../hooks/useAnalytics";

interface OnboardingProps { };

/** 
 * TODO:
 * Store Token
 * Agreement Modal
 * Resend OTP
 */


const Onboarding: React.FC<OnboardingProps> = () => {
    const navigate = useNavigate();
    const { nextPage, setIsContractModalOpen } = useOnboardingStore(({ nextPage, setIsContractModalOpen }) => ({ nextPage, setIsContractModalOpen }));
    const { track } = useAnalytics();

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (!!token && !!nextPage && nextPage == ONBOARDING_PAGE_TYPE.LOGGED_IN) {
            navigate("/deals");
        }
        if (!!token && !!nextPage && nextPage == ONBOARDING_PAGE_TYPE.CONTRACT_SIGNING) {
            setIsContractModalOpen(true);
            track({ eventName: ONBOARDING_EVENTS.KYC_TnC_Initiated })
        }
    }, [nextPage])


    return (
        <div>
            <div className="max-w-sm flex flex-col gap-y-8 mx-auto mt-6 md:mt-20 px-4 box-content">
                <TapLogo />
                {nextPage == ONBOARDING_PAGE_TYPE.PHONE_VERIFICATION &&
                    <PhoneAuth />
                }
                {nextPage == ONBOARDING_PAGE_TYPE.EMAIL_VERIFICATION &&
                    <EmailAuth />
                }
                {nextPage == ONBOARDING_PAGE_TYPE.PAN_VERIFICATION &&
                    <PanVerification />
                }
                {nextPage == ONBOARDING_PAGE_TYPE.AADHAAR_VERIFICATION &&
                    <AadhaarVerification />
                }
                {nextPage == ONBOARDING_PAGE_TYPE.BANK_VERIFICATION &&
                    <BankVerification />
                }
                <AgreementModal />
            </div>
        </div>
    );
};

Onboarding.displayName = "Onboarding";

export default Onboarding;