import React, { useEffect, useState } from 'react';
import { Checkbox, CircularProgress } from '@mui/material';
import Input from '../../../components/Fields/Input';
import clsx from 'clsx';
import { useMutation } from 'react-query';
import { AddFamilyAccount, getAllFamilyAccount } from '../useFamilyAccount';
import { styled } from '@mui/material/styles';
import { CheckCircleIcon } from '../../../assets/mui';
import toast from 'react-hot-toast';

interface PrimaryClientStepProps {
  dataObject: any; // The dataObject containing clients and other details
  setDataObject: (dataObject: any) => void; // Function to update the dataObject
  onClose: () => void; // Callback to close the drawer
  setFamilyLabel: any;
}

const PrimaryClientStep: React.FC<PrimaryClientStepProps> = ({
  dataObject,
  setDataObject,
  onClose,
  setFamilyLabel,
}) => {
  const [isLoading, setIsLoading] = useState(false); // Local loading state
  const [hasPrimaryMember, setHasPrimaryMember] = useState(false);
  const clientsWithIndex = dataObject.userDetails.map((client, index) => ({
    ...client,
    index: client.index !== undefined ? client.index : index,
  }));

  const [clients, setClients] = useState<any[]>(clientsWithIndex);

  const mutation = useMutation({
    mutationFn: AddFamilyAccount,
    onMutate: () => {
      setIsLoading(true); // Set loading to true before the mutation starts
    },
    onSuccess: (data) => {
      toast.success('Clients saved successfully.');
      onClose();
      setDataObject({
        familyName: '',
        familyPublicId: null,
        userDetails: [],
      });
      setFamilyLabel('');
      setHasPrimaryMember(false);
    },
    onError: (error: any) => {
      console.error(error);
      toast.error(error?.response?.data?.message);
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });
  
  const sanitizePhoneNumber = (phone) => {
    // Remove any non-numeric characters except +
    let sanitizedPhone = phone.replace(/[^0-9+]/g, '');

    // If the sanitized phone starts with +91, ensure it has exactly 13 characters
    if (sanitizedPhone.startsWith('+91') && sanitizedPhone.length !== 13) {
      sanitizedPhone = sanitizedPhone.slice(0, 3) + sanitizedPhone.slice(3, 13); // Trim to +91 followed by 10 digits
    }

    // If the sanitized phone doesn't start with +91, ensure it has exactly 10 digits
    if (!sanitizedPhone.startsWith('+91') && sanitizedPhone.length > 10) {
      sanitizedPhone = sanitizedPhone.slice(0, 10); // Trim to exactly 10 digits
    }

    return sanitizedPhone;
  };

  const validatePhoneNumber = (phone) => {
    const sanitizedPhone = sanitizePhoneNumber(phone);
    const phoneRegex = /^(?:\+91)?[0-9]{10}$/; // Allows either exactly 10 digits or +91 followed by 10 digits

    if (!phoneRegex.test(sanitizedPhone)) {
      toast.error(
        'Invalid phone number. Please enter a valid 10-digit number or a number with +91 prefix.',
      );
      return false;
    }
    return true;
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Standard email regex
    if (!emailRegex.test(email)) {
      toast.error('Invalid email address. Please enter a valid email.');
      return false;
    }
    return true;
  };

  const handlePhoneChange = (index, value) => {
    // Allow only digits and + in the input
    if (/^[0-9+]*$/.test(value)) {
      setClients((prevClients) =>
        prevClients.map((client) =>
          client.index === index ? { ...client, phone: sanitizePhoneNumber(value) } : client,
        ),
      );
    } else {
      toast.error('Please enter a valid phone number with only digits or a +91 prefix.');
    }
  };

  const handleEmailChange = (index, value) => {
    setClients((prevClients) =>
      prevClients.map((client) => (client.index === index ? { ...client, email: value } : client)),
    );
  };

  const handlePrimaryClientToggle = (index: number) => {
    setClients((prevClients) =>
      prevClients.map((client) =>
        client.index === index
          ? {
              ...client,
              isd: client.level === 'PRIMARY_MEMBER' && '+91',
              level: client.level === 'PRIMARY_MEMBER' ? 'SECONDARY_MEMBER' : 'PRIMARY_MEMBER',
            }
          : client,
      ),
    );
  };

  useEffect(() => {
    const getPrimaryClientsList = async () => {
      try {
        const response = await getAllFamilyAccount({
          familyAccountPublicId: dataObject.familyPublicId,
        });
        setHasPrimaryMember(response?.data[0]?.primaryMemberPublicIds.length > 0);
      } catch (error) {
        console.error('Error fetching primary clients list:', error);
      }
    };

    getPrimaryClientsList();
  }, [dataObject, hasPrimaryMember]);

  const onSaveAndContinue = () => {
    if (!hasPrimaryMember) {
      if (clients.some((client) => client.level === 'PRIMARY_MEMBER')) {
      } else {
        toast.error('At least one client must be set as a primary member.');
        return; // Do not proceed if no primary member is set
      }
    }
    const invalidPhone = clients.some(
      (client) => client.level === 'PRIMARY_MEMBER' && !validatePhoneNumber(client.phone || ''),
    );
    const invalidEmail = clients.some(
      (client) => client.level === 'PRIMARY_MEMBER' && !validateEmail(client.email || ''),
    );

    if (invalidPhone || invalidEmail) {
      return; // Do not proceed if there are validation errors
    }

    const updatedDataObject = {
      ...dataObject,
      userDetails: clients,
    };

    mutation.mutate(updatedDataObject);
  };

  const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
    color: '#4caf50', // Custom unchecked color
    '&.Mui-checked': {
      color: '#4caf50', // Custom checked color
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.5rem', // Adjust icon size
    },
  }));

  return (
    <div className="flex flex-col justify-between min-h-24 md:min-h-28 pb-4">
      <div>
        <h3 className="text-lg font-semibold mb-2">Set Primary Clients</h3>
        <p className="text-sm text-gray-600 mb-4">Choose the clients you want to set as primary</p>
        {clients.map((client, i) => {
          const isSelected = client.level === 'PRIMARY_MEMBER';
          return (
            <div
              key={client.index}
              className={clsx('border rounded-lg p-4 mb-2 flex items-start justify-between', {
                'border-green-600': isSelected,
                'border-gray-300': !isSelected,
              })}
            >
              <div className="flex-grow">
                <div className="text-base font-semibold">Name</div>
                <p className="text-sm text-gray-600">{client.firstName}</p>
                {isSelected && (
                  <div className="mt-2">
                    <div className="flex gap-4 mb-2">
                      <Input
                        placeholder="Phone Number"
                        value={client.phone || ''}
                        onChange={(e) => handlePhoneChange(client.index, e.target.value)}
                        className="flex-grow text-gray-600"
                      />
                      <Input
                        placeholder="Email ID"
                        value={client.email || ''}
                        onChange={(e) => handleEmailChange(client.index, e.target.value)}
                        className="flex-grow text-gray-600"
                      />
                    </div>
                  </div>
                )}
              </div>
              <CustomCheckbox
                checked={isSelected}
                onChange={() => handlePrimaryClientToggle(i)}
                icon={<CheckCircleIcon style={{ color: '#e0e0e0' }} />} // Custom unchecked icon color
                checkedIcon={<CheckCircleIcon style={{ color: '#4caf50' }} />} // Custom checked icon color
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </div>
          );
        })}
      </div>

      <div className="mt-4 bg-gray-50 p-4 rounded-lg text-sm">
        <h4 className="font-semibold mb-2">What is Primary Account?</h4>
        <p className="text-gray-600">
          Primary account holders can log in with phone or email, see & manage all accounts, and
          they will have the ability to make investments for everyone within that family.
        </p>
      </div>

      <button
        type="button"
        className={clsx(
          'mt-6 flex items-center justify-center gap-2 text-sm text-white rounded-xl font-bold h-10 w-full',
          {
            'bg-green-700 hover:bg-green-800': !isLoading,
            'bg-green-700/60 cursor-not-allowed': isLoading,
          },
        )}
        onClick={onSaveAndContinue}
        disabled={isLoading}
      >
        {isLoading ? <CircularProgress style={{ color: 'white' }} size={20} /> : 'Save'}
      </button>
    </div>
  );
};

export default PrimaryClientStep;
